<svg
  [ngClass]="{ static: static }"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="-2 -2 142 142"
  xml:space="preserve"
>
  <defs>
    <linearGradient id="a" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop stop-color="var(--primary)" offset="0%" />
      <stop stop-color="var(--secondary)" offset="100%" />
    </linearGradient>
  </defs>

  <style>
    #outer_circle {
      transform-origin: center;
      animation: spinner_StKS 6s 1 linear;
      animation-direction: reverse;
    }
    #inner_circle {
      transform-origin: center;
      animation: spinner_StKS 6s 1 linear;
    }
    .static #inner_circle,
    .static #outer_circle {
      animation: none;
    }

    @keyframes spinner_StKS {
      100% {
        transform: rotate(360deg);
      }
    }
  </style>
  <rect width="160" height="160" fill="url(#a)" mask="url(#icon)"></rect>
  <mask id="icon">
    <g id="clock" fill="#fff">
      <path
        class="st0"
        d="M70.4,56.8L89,38.1c0.6-0.6,1.5-0.6,2.1,0l11.3,11.4c0.6,0.6,0.6,1.5,0,2.1L83.8,70.4l18.6,18.8
		c0.5,0.5,0.6,1.4,0.1,2l-0.1,0.1l-11.3,11.4c-0.6,0.6-1.5,0.6-2.1,0L70.4,83.9l-18.6,18.8c-0.6,0.6-1.4,0.6-2,0.1l-0.1-0.1
		L38.3,91.2c-0.6-0.6-0.6-1.5,0-2.1l18.6-18.8L38.3,51.6c-0.5-0.5-0.6-1.4-0.1-2l0.1-0.1l11.3-11.4c0.6-0.6,1.5-0.6,2.1,0L70.4,56.8
		z M99.3,50.5L90,41.2L71.4,60c-0.6,0.6-1.5,0.6-2.1,0L50.7,41.2l-9.2,9.3l18.6,18.8c0.5,0.5,0.6,1.4,0.1,2l-0.1,0.1L41.5,90.2
		l9.2,9.3l18.6-18.8c0.6-0.6,1.4-0.6,2-0.1l0.1,0.1L90,99.5l9.2-9.3L80.6,71.4c-0.5-0.5-0.6-1.4-0.1-2l0.1-0.1L99.3,50.5z"
      />
    </g>
    <g id="inner_circle" fill="#fff">
      <path
        class="st0"
        d="M70.6,15.4c22.1,0,41.9,13,51,32.9c0.3,0.8,0,1.6-0.7,2c-0.8,0.3-1.6,0-2-0.7c-8.6-18.8-27.4-31.2-48.3-31.2
		c-9,0-17.7,2.3-25.4,6.5c-0.7,0.4-1.6,0.1-2-0.6c-0.4-0.7-0.1-1.6,0.6-2C51.9,17.8,61.1,15.4,70.6,15.4z"
      />
      <path
        class="st0"
        d="M17.5,72c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5c0,31.3,25.1,56.6,56.2,56.6
		c25.8,0,48.1-17.7,54.4-42.5c0.2-0.8-0.3-1.6-1.1-1.8c-0.8-0.2-1.6,0.3-1.8,1.1c-6,23.5-27.1,40.3-51.5,40.3
		C41.3,125.6,17.5,101.6,17.5,72z"
      />
    </g>
    <g id="outer_circle" fill="#fff">
      <path
        class="st0"
        d="M70.4,2c12.2,0,23.9,3.2,34.2,9.1c0.7,0.4,1,1.3,0.6,2c-0.4,0.7-1.3,1-2,0.6C93.2,8,82,5,70.4,5
		C33.7,5,4,35,4,71.9c0,0.8-0.7,1.5-1.5,1.5S1,72.7,1,71.9C1,33.3,32.1,2,70.4,2z M10.4,104.1c0.7-0.4,1.6-0.1,2,0.6
		c7.4,13.3,19.3,23.8,33.4,29.5c0.8,0.3,1.1,1.2,0.8,1.9c-0.3,0.8-1.2,1.1-2,0.8C30,131,17.6,120.1,9.9,106.1
		C9.5,105.4,9.7,104.5,10.4,104.1z M139.8,71.9c0-0.8-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5C136.8,99.5,120,124,95,134
		c-0.8,0.3-1.1,1.2-0.8,2c0.3,0.8,1.2,1.1,2,0.8C122.3,126.3,139.8,100.7,139.8,71.9z"
      />
    </g>
  </mask>
</svg>

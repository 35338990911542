<aside class="site-sidebar">
  <div class="sidebar-header">
    <div class="site-logo">
      <a routerLink="/home">
        <img src="/assets/images/logo-lockup.svg" alt="Design System" />
        <span class="visually-hidden">Return to Home Page</span>
      </a>
    </div>
    <app-site-search location="sidebar"></app-site-search>
  </div>

  <div class="site-navigation" id="sidenav" #sidenav>
    <nav class="nav">
      <div class="loose-links">
        <div class="list-group">
          <a class="list-group-item" routerLinkActive="active" routerLink="/pages/get-started">Get Started</a>
        </div>
      </div>

      <div class="section-divider"></div>

      <div #webAccordion="ngbAccordion" ngbAccordion [destroyOnHide]="false">
        <div ngbAccordionItem="web">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Web</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <p class="section-header">Basics</p>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/animation">Animation </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/colors">Colors</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/component-library"
                    >Component Library
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/error-validation"
                    >Error Validation</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/iconography">Iconography</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/grid">Layout </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/analytics">Product Analytics</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/sample-application"
                    >Sample Application
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/techstack">Tech Stack</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/typography">Typography</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/urls">URLs</a>
                </div>

                <p class="section-header">Themes</p>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/themes/backgrounds"
                    >Backgrounds</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/themes/colorsets">Color Sets</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/themes/compactui">Compact</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/themes/darkmode">Dark Mode</a>
                </div>

                <p class="section-header">Patterns</p>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/patterns/account-access"
                    >Account Access</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/patterns/account-access-mfa"
                    >Account Access - MFA</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/patterns/action-list-panel"
                    >Action List & Panel
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/patterns/annotations"
                    >Annotations
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/patterns/create-a-case"
                    >Create a Case
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/patterns/device-access"
                    >Device Access</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/patterns/impersonation"
                    >Impersonation</a
                  >

                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/patterns/onboarding"
                    >Onboarding</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/patterns/on-product-support"
                    >On-Product Support</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/patterns/timeout">Timeout</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/patterns/tracking">Tracking </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/patterns/user-management"
                    >User Management</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/patterns/widgets">Widgets</a>
                </div>

                <p class="section-header">Components</p>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/accessibility-statement"
                    >Accessibility Statement</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/accessibility-widget"
                    >Accessibility Widget
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/accordions">Accordions</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/account-switcher"
                    >Account Switchers</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/address-blocks"
                    >Address Blocks</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/avatars">Avatars </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/badges">Badges</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/buttons">Buttons</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/cards">Cards</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/carousel">Carousel </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/checkboxes">Checkboxes</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/color-picker">Color Pickers</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/datepickers"
                    >Date and Time Pickers</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dragdrop">Drag and Drop</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dropdowns">Dropdowns </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/empty-states">Empty States</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/errors">Error Pages </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/file-upload">File Upload</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/footers">Footers</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/forms">Forms</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/hamburger">Hamburger Menu</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/headers">Headers</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/image-lightbox"
                    >Image Lightbox
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/vscroller"
                    >Infinite Scrolling Box</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/inputfields">Input Fields</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/languageselector"
                    >Language Selector</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/links">Links</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/lists">Lists</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/loading">Loading Screens</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/maps">Maps</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/miller-column"
                    >Miller Columns</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/modals">Modals</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/notifications">Notifications</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/breadcrumbs"
                    >On-Page Navigation</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/page-title">Page Title </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/payment-blocks"
                    >Payment Blocks</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/popovers">Popovers</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/pricing-plans">Pricing Plans</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/progress"
                    >Progress Indicators</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/promohero"
                    >Promotional Heroes</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/radios">Radio Buttons </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/ratings">Ratings </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/skeletons">Skeletons</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/sliders">Sliders </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/splitviews">Split Views</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/steppers">Steppers</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/switches">Switches</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/tables">Tables</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/tabs">Tabs</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/theme-switcher"
                    >Theme Switcher
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/tooltips">Tooltips</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/tree">Tree Navigation </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/welcome-screens"
                    >Welcome Screens</a
                  >
                </div>

                <p class="section-header">Data Visualization</p>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/guidelines"
                    >Design Principles</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/colors">Chart Colors</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/accessibility"
                    >Accessibility</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/dashboards"
                    >Dashboards</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/terminology"
                    >Terminology &amp; Style</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/chart-selector"
                    >Chart Selector</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/area">Area</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/bar-grouped"
                    >Bar - Grouped
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/bar-single-horizontal"
                    >Bar - Stack Horizontal
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/bar-stacked"
                    >Bar - Stack Vertical
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/bar">Bar - Vertical</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/bubble">Bubble Chart</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/bubblemap"
                    >Bubble Map</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/choroplethmap"
                    >Choropleth Map</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/donut">Donut</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/dotmap">Dot Map</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/gauge">Gauge</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/heatmap">Heat Map </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/line">Line</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/metricblock"
                    >Metric Block</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/pie">Pie</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/scatter"
                    >Scatter Plot</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/web/dataviz/sparkline">Sparkline</a>
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div ngbAccordionItem="mobile">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Mobile</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <p class="section-header">Basics</p>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/colors">Colors</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/development">Development</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/iconography">Iconography</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/grid">Layout</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/typography">Typography</a>
                </div>

                <p class="section-header">Components</p>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/account-access"
                    >Account Access</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/alerts">Alerts</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/biometric-authentication"
                    >Biometric Authentication</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/buttons">Buttons</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/camera">Camera</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/checkboxes">Checkboxes</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/date-and-time"
                    >Date & Time</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/dropdowns">Dropdowns</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/errors">Error Messages</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/filtering">Filtering</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/help">Help</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/input-fields">Inputs</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/keyboards">Keyboards</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/links">Links</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/lists">Lists</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/navigation">Navigation</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/onboarding">Onboarding</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/pagination">Pagination</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/loading"
                    >Progress Indicators</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/search">Search</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/sheets">Sheets</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/sliders">Sliders</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/switches">Switches</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/mobile/tabs">Tabs</a>
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div ngbAccordionItem="desktop">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Desktop</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <p class="section-header">Basics</p>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/desktop/colors">Colors</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/desktop/grid">Layout</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/desktop/iconography">Iconography</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/desktop/typography">Typography</a>
                </div>
                <p class="section-header">Components</p>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/desktop/buttons"
                    >Buttons and Links</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/desktop/containers">Containers</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/desktop/forms">Forms</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/desktop/headers-footers"
                    >Headers and Footers</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/desktop/navigation">Navigation</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/desktop/support"
                    >On-Product Support</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/desktop/ribbons">Ribbons</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/desktop/user-feedback"
                    >User Feedback</a
                  >
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div ngbAccordionItem="hardware">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Hardware</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <p class="section-header">Basics</p>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/accessibility"
                    >Accessibility</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/colors">Colors</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/finish">Finishes</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/iconography"
                    >Iconography</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/logo">Logos</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/material">Materials</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/typography">Typography</a>
                </div>
                <p class="section-header">Components</p>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/accessories"
                    >Design Consistency</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/buttons">Buttons</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/covers">Covers</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/draft-angles"
                    >Draft Angles</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/knobs-handles"
                    >Knobs and Handles</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/labels">Labels</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/leds">LEDs</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/ports">Ports</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/radial-corners"
                    >Radial Corners</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/hardware/signature-elements"
                    >Signature Elements</a
                  >
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div ngbAccordionItem="wearables">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Wearables</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <p class="section-header">Basics</p>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/wearables/colors">Colors</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/wearables/iconography"
                    >Iconography</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/wearables/sample-application"
                    >Sample Application</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/wearables/typography">Typography</a>
                </div>
                <p class="section-header">Components</p>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/wearables/buttons">Buttons</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/wearables/navigation">Navigation</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/wearables/progress"
                    >Progress Indicators</a
                  >
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div ngbAccordionItem="conversational-ui">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Conversational-UI</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/conversational-ui/chatbots"
                    >Chatbots</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/conversational-ui/voice-ui"
                    >Voice UI</a
                  >
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div ngbAccordionItem="printed-materials">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Printed-Materials</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/printed-materials/illustrations"
                    >Illustration Guideline</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/printed-materials/indicias"
                    >Indicias</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/printed-materials/packaging"
                    >Packaging</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/printed-materials/pdf-reports"
                    >PDF Reports</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/printed-materials/shipping-labels"
                    >Shipping Labels</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/printed-materials/user-instructions"
                    >User Instructions</a
                  >
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div ngbAccordionItem="illustrations">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Illustrations</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/illustrations/illustrations"
                    >Packages &amp; Envelopes
                  </a>

                  <a
                    class="list-group-item"
                    routerLinkActive="active"
                    routerLink="/illustrations/carrier-logo-illustrations"
                    >Carrier Logos
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/illustrations/gec-illustrations"
                    >GEC Facilities
                  </a>
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div ngbAccordionItem="content">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Content</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/content/style">Editorial Style</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/content/client-emails"
                    >Emails (Client)</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/content/internal-emails"
                    >Emails (Internal)</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/content/on-product-support"
                    >On-Product Support</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/content/point-of-view"
                    >Point of View</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/content/terminology">Terminology</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/content/user-messages"
                    >User Messages</a
                  >
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div ngbAccordionItem="globalization">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Globalization</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/globalization/process"
                    >Process and Guidelines</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/globalization/country-parameters"
                    >Country Parameters
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/globalization/key-concepts"
                    >Key Concepts</a
                  >

                  <a class="list-group-item" routerLinkActive="active" routerLink="/globalization/translation"
                    >Content Translation</a
                  >

                  <a class="list-group-item" routerLinkActive="active" routerLink="/globalization/development"
                    >Coding for Translation</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/globalization/json"
                    >JSON Guidelines</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/globalization/android"
                    >Android Guidelines</a
                  >
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div ngbAccordionItem="accessibility">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Accessibility</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="list-group">
                  <a class="list-group-item" routerLinkActive="active" routerLink="/accessibility/overview">Overview</a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/accessibility/accessible-content"
                    >Accessible Content
                  </a>
                  <a
                    class="list-group-item"
                    routerLinkActive="active"
                    routerLink="/accessibility/ADA-keypad-best-practices"
                    >ADA Keypads</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/accessibility/checklist">
                    Checklists
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/accessibility/color-contrast-ratios"
                    >Color Contrast Ratios
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/accessibility/compliance-score"
                    >Compliance Score
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/accessibility/tips"
                    >Development Tips</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/accessibility/dos-and-donts"
                    >Do's and Dont's</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/accessibility/mobile">Mobile</a>
                  <a
                    class="list-group-item"
                    routerLinkActive="active"
                    routerLink="/accessibility/principles-of-accessible-design"
                    >POUR Principles</a
                  >
                  <a
                    class="list-group-item"
                    routerLinkActive="active"
                    routerLink="/accessibility/quality-assurance-of-accessibility"
                    >QA Testing</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/accessibility/additional-resources"
                    >Resources</a
                  >
                  <a class="list-group-item" routerLinkActive="active" routerLink="/accessibility/terminology"
                    >Terminology
                  </a>
                  <a class="list-group-item" routerLinkActive="active" routerLink="/accessibility/vpats">VPAT</a>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="section-divider"></div>

      <div class="loose-links">
        <div class="list-group">
          <a class="list-group-item" routerLinkActive="active" routerLink="/brand/brand-voice">Brand Voice</a>
          <a class="list-group-item" routerLinkActive="active" routerLink="/design/design-principles"
            >Design Principles</a
          >
          <a class="list-group-item" routerLinkActive="active" routerLink="/pages/cx-principles">CX Principles </a>
          <a class="list-group-item" routerLinkActive="active" routerLink="/pages/inclusive-design"
            >Inclusive Design
          </a>
        </div>
      </div>

      <div class="section-divider"></div>

      <div class="loose-links">
        <div class="list-group">
          <a class="list-group-item" routerLinkActive="active" routerLink="/downloads">Downloads</a>
          <!-- <a class="list-group-item" routerLinkActive="active" routerLink="/showcase">Product Showcase</a> -->
          <a class="list-group-item" routerLinkActive="active" routerLink="/videos">Videos</a>
        </div>
      </div>

      <div class="section-divider"></div>

      <div class="loose-links mb-6">
        <div class="list-group">
          <a class="list-group-item" routerLinkActive="active" routerLink="/pages/release-notes">Release Notes</a>
          <a class="list-group-item" routerLinkActive="active" routerLink="/pages/team">The Team</a>
          <a class="list-group-item" routerLinkActive="active" routerLink="/pages/contribute">Contribute</a>
        </div>
      </div>
    </nav>
  </div>
</aside>

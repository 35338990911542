import { Component, Input } from '@angular/core';

@Component({
  selector: 'pbds-progress-spinner',
  template: `
    <span role="alert" aria-live="assertive">
      <span
        class="sbl-circ"
        [style.width.px]="size"
        [style.height.px]="size"
        [style.border-width.px]="size / 8"
        aria-hidden="true"
      ></span>
      <span class="visually-hidden">Loading</span>
    </span>
  `,
  styleUrls: ['./progress-spinner.component.scss']
})
export class PbdsProgressSpinnerComponent {
  @Input()
  size: number;
}

<svg
  [ngClass]="{ static: static }"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="-2 -2 142 142"
  xml:space="preserve"
>
  <defs>
    <linearGradient id="a" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop stop-color="var(--primary)" offset="0%" />
      <stop stop-color="var(--secondary)" offset="100%" />
    </linearGradient>
  </defs>

  <style>
    #outer_circle {
      transform-origin: center;
      animation: spinner_StKS 6s 1 linear;
      animation-direction: reverse;
    }
    #inner_circle {
      transform-origin: center;
      animation: spinner_StKS 6s 1 linear;
    }

    .static #inner_circle,
    .static #outer_circle {
      animation: none;
    }

    @keyframes spinner_StKS {
      100% {
        transform: rotate(360deg);
      }
    }
  </style>
  <rect width="160" height="160" fill="url(#a)" mask="url(#icon)"></rect>
  <mask id="icon">
    <g id="clock" fill="#fff">
      <path
        d="M86,67V53.4l0-0.3c-0.2-8.5-7.1-15.5-15.7-15.5c-8.6,0-15.7,7.1-15.7,15.8V67c-5.8,4.6-9.4,11.8-9.4,19.8
		c0,14,11.2,25.3,25.1,25.3c13.9,0,25.1-11.3,25.1-25.3C95.5,78.7,91.8,71.6,86,67z M57.7,53.4l0-0.3c0.2-6.9,5.8-12.5,12.7-12.5
		c7,0,12.7,5.8,12.7,12.8v11.5c-3.7-2.2-8-3.5-12.7-3.5c-4.6,0-8.9,1.3-12.7,3.5V53.4z M70.4,109c-12.2,0-22.1-10-22.1-22.3
		c0-12.3,9.9-22.3,22.1-22.3c12.2,0,22.1,10,22.1,22.3C92.5,99,82.6,109,70.4,109z"
      />
      <path
        d="M79,82c0-4.8-3.8-8.6-8.6-8.6s-8.6,3.9-8.6,8.6c0,4.3,3.1,7.8,7.1,8.5v5.8l0,0.1c0.1,0.8,0.7,1.4,1.5,1.4
		c0.8,0,1.5-0.7,1.5-1.5v-5.8C75.9,89.7,79,86.2,79,82z M70.4,87.6C70.4,87.6,70.4,87.6,70.4,87.6C70.4,87.6,70.4,87.6,70.4,87.6
		c-3.1,0-5.6-2.5-5.6-5.6c0-3.1,2.5-5.6,5.6-5.6S76,78.8,76,82C76,85.1,73.5,87.6,70.4,87.6z"
      />
    </g>
    <g id="inner_circle" fill="#fff">
      <path
        d="M70.6,15.4c22.1,0,41.9,13,51,32.9c0.3,0.8,0,1.6-0.7,2c-0.8,0.3-1.6,0-2-0.7c-8.6-18.8-27.4-31.2-48.3-31.2
		c-9,0-17.7,2.3-25.4,6.5c-0.7,0.4-1.6,0.1-2-0.6c-0.4-0.7-0.1-1.6,0.6-2C51.9,17.8,61.1,15.4,70.6,15.4z M17.5,72
		c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5c0,31.3,25.1,56.6,56.2,56.6c25.8,0,48.1-17.7,54.4-42.5c0.2-0.8-0.3-1.6-1.1-1.8
		c-0.8-0.2-1.6,0.3-1.8,1.1c-6,23.5-27.1,40.3-51.5,40.3C41.3,125.6,17.5,101.6,17.5,72z"
      />
    </g>
    <g id="outer_circle" fill="#fff">
      <g>
        <path
          d="M70.4,2c12.2,0,23.9,3.2,34.2,9.1c0.7,0.4,1,1.3,0.6,2c-0.4,0.7-1.3,1-2,0.6C93.2,8,82,5,70.4,5C33.7,5,4,35,4,71.9
			c0,0.8-0.7,1.5-1.5,1.5S1,72.7,1,71.9C1,33.3,32.1,2,70.4,2z"
        />
        <path
          d="M10.4,104.1c0.7-0.4,1.6-0.1,2,0.6c7.4,13.3,19.3,23.8,33.4,29.5c0.8,0.3,1.1,1.2,0.8,1.9c-0.3,0.8-1.2,1.1-2,0.8
			C30,131,17.6,120.1,9.9,106.1C9.5,105.4,9.7,104.5,10.4,104.1z"
        />
        <path
          d="M139.8,71.9c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5C136.8,99.5,120,124,95,134c-0.8,0.3-1.1,1.2-0.8,2
			c0.3,0.8,1.2,1.1,2,0.8C122.3,126.3,139.8,100.7,139.8,71.9z"
        />
      </g>
    </g>
  </mask>
</svg>

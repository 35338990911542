import { Component, Input } from '@angular/core';

@Component({
  selector: 'pbds-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class PbdsEmptyStateComponent {
  @Input() head: string;
  @Input() caption?: string;
  @Input() icon = 'none';
}

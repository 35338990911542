<svg
  [ngClass]="{ static: static }"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 156 150"
  style="enable-background: new 0 0 156 150"
  xml:space="preserve"
>
  <defs>
    <linearGradient id="a" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop stop-color="var(--primary)" offset="0%" />
      <stop stop-color="var(--secondary)" offset="100%" />
    </linearGradient>
  </defs>

  <style>
    #outer_circle {
      transform-origin: center;
      animation: spinner_StKS 6s 1 linear;
      animation-direction: reverse;
    }
    #inner_circle {
      transform-origin: center;
      animation: spinner_StKS 6s 1 linear;
    }

    .static #inner_circle,
    .static #outer_circle {
      animation: none;
    }

    @keyframes spinner_StKS {
      100% {
        transform: rotate(360deg);
      }
    }
  </style>
  <rect width="160" height="160" fill="url(#a)" mask="url(#icon)"></rect>
  <mask id="icon">
    <g id="clock">
      <g id="bigicon" fill="#fff">
        <g>
          <path
            class="st0"
            d="M67.4,84.4V39.2c0-0.8,0.7-1.5,1.5-1.5h18.2c0.8,0,1.5,0.7,1.5,1.5v45.2c0,0.8-0.7,1.5-1.5,1.5H68.9
				C68.1,85.9,67.4,85.2,67.4,84.4z M85.6,40.7H70.4v42.2h15.2V40.7z"
          />
          <path
            class="st0"
            d="M67.5,101.2c0-5.9,4.7-10.6,10.5-10.6s10.5,4.8,10.5,10.6c0,5.9-4.7,10.6-10.5,10.6S67.5,107.1,67.5,101.2z
				 M85.5,101.2c0-4.2-3.4-7.6-7.5-7.6s-7.5,3.4-7.5,7.6s3.4,7.6,7.5,7.6S85.5,105.4,85.5,101.2z"
          />
        </g>
      </g>
    </g>
    <g id="inner_circle" fill="#fff">
      <path
        d="M132.3,55c-0.6,0-1.1-0.3-1.4-0.9c-9-21.3-29.8-35-52.9-35c-10.6,0-21,2.9-30,8.4c-0.7,0.4-1.6,0.2-2.1-0.5
		c-0.4-0.7-0.2-1.6,0.5-2.1C55.9,19.1,66.8,16,78,16c24.3,0,46.2,14.5,55.7,36.9c0.3,0.8,0,1.6-0.8,2C132.7,54.9,132.5,55,132.3,55z
		 M78,137c-33.4,0-60.5-27.1-60.5-60.5c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5c0,31.7,25.8,57.5,57.5,57.5
		c25.7,0,48.5-17.3,55.4-42.1c0.2-0.8,1-1.3,1.8-1c0.8,0.2,1.3,1,1,1.8C129.1,118.8,105.1,137,78,137z"
      />
    </g>
    <g id="outer_circle" fill="#fff">
      <g id="Layer_1_00000111887046347563152000000006085499972617247130_">
        <g>
          <path
            d="M55.8,145.6c-0.2,0-0.3,0-0.5-0.1c-17.2-5.7-31.7-17.5-40.6-33.4c-0.4-0.7-0.2-1.6,0.6-2c0.7-0.4,1.6-0.2,2,0.6
				c8.6,15.2,22.4,26.6,38.9,32c0.8,0.3,1.2,1.1,1,1.9C57,145.2,56.4,145.6,55.8,145.6z M100.1,145.6c-0.6,0-1.2-0.4-1.4-1
				c-0.3-0.8,0.2-1.6,1-1.9c28.7-9.4,47.9-36,47.9-66.2c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5c0,31.5-20.1,59.2-50,69
				C100.4,145.6,100.3,145.6,100.1,145.6z M7,82.4c-0.8,0-1.4-0.6-1.5-1.4c-0.1-1.5-0.1-3-0.1-4.5C5.4,36.5,38,3.9,78,3.9
				c12.8,0,25.4,3.4,36.4,9.8c0.7,0.4,1,1.3,0.5,2.1c-0.4,0.7-1.3,1-2.1,0.5c-10.6-6.1-22.6-9.4-34.9-9.4
				C39.6,6.9,8.4,38.1,8.4,76.5c0,1.4,0,2.9,0.1,4.3C8.6,81.7,8,82.4,7,82.4C7.1,82.4,7.1,82.4,7,82.4z"
          />
        </g>
      </g>
    </g>
  </mask>
</svg>

import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  HostBinding,
  Input,
  Output,
  ElementRef,
  SimpleChanges,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

import { ViewportScroller } from '@angular/common';

import { PbdsDatavizHeatmap } from './dataviz.interfaces';
import { PbdsDatavizService } from './dataviz.service';

import { select as d3_select } from 'd3-selection';
import {
  scaleThreshold as d3_scaleThreshold,
  scaleQuantile as d3_scaleQuantile,
  scaleQuantize as d3_scaleQuantize,
  scaleBand as d3_scaleBand
} from 'd3-scale';
import { min as d3_min, max as d3_max } from 'd3-array';
import { axisBottom as d3_axisBottom, axisLeft as d3_axisLeft } from 'd3-axis';
import { isoParse as d3_isoParse } from 'd3-time-format';

@Component({
  selector: 'pbds-dataviz-heatmap',
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PbdsDatavizHeatmapComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class.pbds-chart')
  chartClass = true;

  @HostBinding('class.pbds-chart-heatmap')
  heatmapClass = true;

  @Input()
  data: Array<PbdsDatavizHeatmap>;

  @Input()
  width = 306;

  @Input()
  height = 400;

  @Input()
  marginTop = 0; // hardcoded on purpose, do not document until feedback

  @Input()
  marginRight = 0; // hardcoded on purpose, do not document until feedback

  @Input()
  marginBottom = 30; // hardcoded on purpose, do not document until feedback

  @Input()
  marginLeft = 55;

  @Input()
  scale: 'threshold' | 'quantile' | 'quantize' = 'quantile';

  @Input()
  domain: Array<number>;

  @Input()
  xAxisFormatType: 'number' | 'time' = null;

  @Input()
  xAxisFormatString = '';

  @Input()
  yAxisFormatType: 'number' | 'time' = null;

  @Input()
  yAxisFormatString = '';

  @Input()
  hideLegend = false;

  @Input()
  legendWidth = 105 + 28; // hardcoded legend width + left margin, do not document until feedback

  @Input()
  legendPosition: 'right' | 'bottom' = 'right';

  @Input()
  legendLabelFormatType: 'number' = null;

  @Input()
  legendLabelFormatString = '';

  @Input()
  tooltipXLabelFormatType: 'number' | 'time' = null;

  @Input()
  tooltipXLabelFormatString = '';

  @Input()
  tooltipYLabelFormatType: 'number' | 'time' = null;

  @Input()
  tooltipYLabelFormatString = '';

  @Input()
  tooltipValueFormatType: 'number' = null;

  @Input()
  tooltipValueFormatString = '';

  @Input()
  theme: 'classic' | 'ocean' | 'sunset' | 'twilight' = 'classic';

  @Output()
  hovered: EventEmitter<object> = new EventEmitter<object>();

  @Output()
  clicked: EventEmitter<object> = new EventEmitter<object>();

  private chart;
  private svg;
  private margin;
  private colorRange;
  private colorDomain;
  private xAxisScale;
  private xAxisCall;
  private xAxis;
  private xAxisFormat;
  private xAxisTickSize: number;
  private xAxisTickSizeOuter: number;
  private hideXAxisDomain: boolean;
  private hideXAxisZero: boolean;
  private hideXAxisTicks: boolean;
  private hideXAxis: boolean;
  private yAxis;
  private yAxisFormat;
  private yAxisTickSize;
  private yAxisTickSizeOuter;
  private yAxisScale;
  private yAxisCall;
  private hideYAxis: boolean;
  private hideYAxisDomain: boolean;
  private hideYAxisZero: boolean;
  private hideYAxisTicks: boolean;
  private legendLabelFormat;
  private tooltip;
  private tooltipYLabelFormat;
  private tooltipXLabelFormat;
  private hideTooltip;
  private tooltipValueFormat;

  constructor(private _dataviz: PbdsDatavizService, private _element: ElementRef, private _scroll: ViewportScroller) {}

  ngOnInit() {
    this.margin = {
      top: +this.marginTop,
      right: +this.marginRight,
      bottom: +this.marginBottom,
      left: +this.marginLeft
    };

    // create formatters
    this.yAxisFormat = this._dataviz.d3Format(this.yAxisFormatType, this.yAxisFormatString);
    this.xAxisFormat = this._dataviz.d3Format(this.xAxisFormatType, this.xAxisFormatString);
    this.legendLabelFormat = this._dataviz.d3Format(this.legendLabelFormatType, this.legendLabelFormatString);
    this.tooltipYLabelFormat = this._dataviz.d3Format(this.tooltipYLabelFormatType, this.tooltipYLabelFormatString);
    this.tooltipXLabelFormat = this._dataviz.d3Format(this.tooltipXLabelFormatType, this.tooltipXLabelFormatString);
    this.tooltipValueFormat = this._dataviz.d3Format(this.tooltipValueFormatType, this.tooltipValueFormatString);

    // defaults for all chart types
    this.hideXAxis = false;
    this.hideXAxisZero = false;
    this.hideXAxisDomain = true;
    this.hideYAxisDomain = true;
    this.hideTooltip = false;
    this.hideXAxisTicks = true;
    this.hideYAxisTicks = true;
    this.xAxisTickSize = 8;
    this.xAxisTickSizeOuter = 0;
    this.yAxisTickSize = 8;
    this.yAxisTickSizeOuter = 0;

    // create the chart
    this.chart = d3_select(this._element.nativeElement).attr('aria-hidden', 'true');

    // create chart svg
    this.svg = this.chart
      .append('svg')
      .attr('width', +this.width)
      .attr('height', +this.height + this.margin.top + this.margin.bottom)
      .attr('class', 'img-fluid')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr(
        'viewBox',
        `-${this.margin.left} -${this.margin.top} ${+this.width} ${+this.height + this.margin.top + this.margin.bottom}`
      );

    // color range
    const colors = this._dataviz.getColors(true, this.theme).slice().reverse();

    const colorDomain: any = [
      +d3_min(this.data, (d: PbdsDatavizHeatmap) => d.value),
      +d3_max(this.data, (d: PbdsDatavizHeatmap) => d.value)
    ];
    const colorValues = this.data.map((d) => d.value);

    switch (this.scale) {
      case 'threshold':
        this.colorRange = d3_scaleThreshold().domain(this.domain).range(colors);

        this.colorDomain = this.colorRange.domain();
        break;

      case 'quantile':
        this.colorRange = d3_scaleQuantile().domain(colorValues).range(colors);

        this.colorDomain = this.colorRange.quantiles();
        break;

      case 'quantize':
        this.colorRange = d3_scaleQuantize().domain(colorDomain).range(colors);

        this.colorDomain = this.colorRange.thresholds();
        break;
    }

    // console.log(colors, colorDomain, colorValues, this.scale, this.colorRange, this.colorDomain);

    // define axis labels
    const xAxisLabels: any = [...new Set(this.data.map((d) => d.xLabel))];
    const yAxisLabels: any = [...new Set(this.data.map((d) => d.yLabel))].reverse();

    // X axis
    this.xAxisScale = d3_scaleBand()
      .domain(xAxisLabels)
      .rangeRound([0, this.width - this.margin.left])
      .paddingInner(0.1);

    this.xAxisCall = d3_axisBottom(this.xAxisScale)
      .tickSize(this.xAxisTickSize)
      .tickSizeOuter(this.xAxisTickSizeOuter)
      .tickFormat(this.xAxisFormatter);

    this.xAxis = this.svg
      .append('g')
      .attr('class', 'axis axis-x')
      .attr('transform', `translate(0, ${this.height})`)
      .classed('axis-hidden', this.hideXAxis)
      .classed('axis-zero-hidden', this.hideXAxisZero)
      .classed('axis-domain-hidden', this.hideXAxisDomain)
      .classed('axis-ticks-hidden', this.hideXAxisTicks)
      .call(this.xAxisCall);

    // Y axis
    this.yAxisScale = d3_scaleBand().domain(yAxisLabels).rangeRound([this.height, 0]).paddingInner(0.1);

    this.yAxisCall = d3_axisLeft(this.yAxisScale)
      .tickSize(this.yAxisTickSize)
      .tickSizeOuter(this.yAxisTickSizeOuter)
      .tickFormat(this.yAxisFormatter);

    this.yAxis = this.svg
      .append('g')
      .attr('class', 'axis axis-y')
      .classed('axis-hidden', this.hideYAxis)
      .classed('axis-zero-hidden', this.hideYAxisZero)
      .classed('axis-domain-hidden', this.hideYAxisDomain)
      .classed('axis-ticks-hidden', this.hideYAxisTicks)
      .call(this.yAxisCall);

    // TOOLTIP
    if (!this.hideTooltip) {
      this.tooltip = d3_select('body')
        .append('div')
        .attr('class', 'pbds-tooltip south')
        .style('opacity', 0)
        .attr('aria-hidden', 'true'); // hide tooltip for accessibility
    }

    // add legend classes
    if (!this.hideLegend) {
      this.chart.classed('pbds-chart-legend-bottom', this.legendPosition === 'bottom' ? true : false);
      this.chart.append('ul').attr('class', `legend legend-${this.legendPosition}`);
    }

    this.updateChart();
  }

  ngOnDestroy() {
    if (this.tooltip) this.tooltip.remove();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      this.updateChart();
    }
  }

  updateChart = () => {
    this.svg
      .selectAll('rect')
      .data(this.data)
      .join(
        (enter) =>
          enter
            .append('rect')
            .attr('class', 'block')
            .classed('empty', (d) => d.value === undefined || d.value === null)
            .attr('x', (d) => this.xAxisScale(d.xLabel))
            .attr('y', (d) => this.yAxisScale(d.yLabel))
            .attr('width', this.xAxisScale.bandwidth())
            .attr('height', this.yAxisScale.bandwidth())
            .style('fill', (d) => this.colorRange(d.value)),
        (update) =>
          update.call((update) => {
            update
              .classed('empty', (d) => d.value === undefined || d.value === null)
              .attr('pointer-events', 'none')
              .transition()
              .duration(1000)
              .attr('x', (d) => this.xAxisScale(d.xLabel))
              .attr('y', (d) => this.yAxisScale(d.yLabel))
              .attr('width', this.xAxisScale.bandwidth())
              .attr('height', this.yAxisScale.bandwidth())
              .style('fill', (d) => this.colorRange(d.value))
              .transition()
              .selection()
              .attr('pointer-events', 'auto');

            return update;
          }),
        (exit) => exit.transition().selection().attr('pointer-events', 'none').remove()
      )
      .on('mouseover', (event, data) => this.blockMouseOver(event, data))
      .on('mouseout', (event, data) => this.blockMouseOut())
      .on('click', (event, data) => this.blockMouseClick(event, data));

    if (!this.hideLegend) {
      this.chart
        .select('.legend')
        .selectAll('.legend-item')
        .data(this.colorDomain)
        .join(
          (enter) => {
            const li = enter
              .append('li')
              .attr('class', 'legend-item')
              .attr('data-index', (d, i) => {
                return i;
              });

            li.append('span')
              .attr('class', 'legend-key')
              .style('background-color', (d) => this.colorRange(d));

            li.append('span')
              .attr('class', 'legend-label')
              .html((d) => {
                let label: string = d;

                switch (this.legendLabelFormatType) {
                  case 'number':
                    label = this.legendLabelFormat(d);
                    break;
                }

                return `&ge; ${label}`;
              });

            return li;
          },
          (update) =>
            update
              .select('.legend-label')
              .attr('data-index', (d, i) => {
                return i;
              })
              .html((d) => {
                // console.log('HTML D: ', d);
                let label = d;

                switch (this.legendLabelFormatType) {
                  case 'number':
                    label = this.legendLabelFormat(d);
                    break;
                }

                return `&ge; ${label}`;
              }),
          (exit) => exit.remove()
        )
        .on('mouseover', (event, data) => this.legendMouseOver(event, data))
        .on('mouseout', () => this.legendMouseOut())
        .on('click', (event, data) => this.legendMouseClick(event, data));
    }
  };

  blockMouseOver = (event, data) => {
    // console.log(data.value, event, data, index, nodes);

    if (data.value !== null) {
      this.tooltipShow(event, data);
    }

    this.hovered.emit({ event, data });
  };

  blockMouseOut = () => {
    this.tooltipHide();
  };

  blockMouseClick = (event, data) => {
    this.clicked.emit({ event, data });
  };

  legendMouseOver = (event, data) => {
    const legendItems = this.chart.selectAll('.legend-item');
    const hovered = d3_select(event.currentTarget);
    const hoveredIndex = +hovered.attr('data-index');

    legendItems.classed('inactive', true);
    hovered.classed('inactive', false);

    const nodes = legendItems.nodes();

    this.chart
      .selectAll('.block')
      .filter((d, i) => {
        if (hoveredIndex + 1 === nodes.length) {
          return d.value < data;
        } else {
          const nextNodeData = +d3_select(nodes[+hoveredIndex + 1]).data();

          return d.value < data || d.value >= nextNodeData;
        }
      })
      .classed('inactive', true);

    this.hovered.emit({ event, data: data });
  };

  legendMouseOut = () => {
    this.chart.selectAll('.legend-item').classed('inactive', false);

    this.chart.selectAll('.block').classed('inactive', false);
  };

  legendMouseClick = (event, data) => {
    this.clicked.emit({ event, data: data });
  };

  private tooltipShow = (event, data) => {
    // console.log('TOOLTIP: ', data, index, node);

    const dimensions = event.currentTarget.getBoundingClientRect();
    const scroll = this._scroll.getScrollPosition();
    let yLabel;
    let xLabel;

    switch (this.tooltipYLabelFormatType) {
      case 'number':
        yLabel = this.tooltipYLabelFormat(data.yLabel);
        break;

      case 'time':
        const parsedTime = d3_isoParse(data.yLabel);
        yLabel = this.tooltipYLabelFormat(parsedTime);
        break;

      default:
        yLabel = `${data.yLabel}${this.tooltipYLabelFormatString}`;
    }

    switch (this.tooltipXLabelFormatType) {
      case 'number':
        xLabel = this.tooltipXLabelFormat(data.xLabel);
        break;

      case 'time':
        const parsedTime = d3_isoParse(data.xLabel);
        xLabel = this.tooltipXLabelFormat(parsedTime);
        break;

      default:
        xLabel = `${data.xLabel}${this.tooltipXLabelFormatString}`;
    }

    const value =
      this.tooltipValueFormat === null
        ? `<div class="tooltip-value">${data.value}</div>`
        : `<div class="tooltip-value">${this.tooltipValueFormat(data.value)}</div>`;

    this.tooltip.html(
      `
        ${yLabel} : ${xLabel}<br>
        ${value}
      `
    );

    const tooltipOffsetWidth = +this.tooltip.node().offsetWidth / 2;
    const tooltipOffsetHeight = +this.tooltip.node().offsetHeight + 8;

    this.tooltip.style('top', `${+scroll[1] + +dimensions.top - tooltipOffsetHeight}px`); //
    this.tooltip.style('left', `${+scroll[0] + +dimensions.left - tooltipOffsetWidth + +dimensions.width / 2}px`);
    this.tooltip.style('opacity', 1);

    this.tooltip.style('opacity', 1);
  };

  private tooltipHide = () => {
    this.tooltip.style('opacity', 0);
  };

  private xAxisFormatter = (item) => {
    switch (this.xAxisFormatType) {
      case 'number':
        return this.xAxisFormat(item);

      case 'time':
        const parseDate = d3_isoParse(item);
        return this.xAxisFormat(parseDate);

      default:
        return item;
    }
  };

  private yAxisFormatter = (item) => {
    switch (this.yAxisFormatType) {
      case 'number':
        return this.yAxisFormat(item);

      case 'time':
        const parseDate = d3_isoParse(item);
        return this.yAxisFormat(parseDate);

      default:
        return item;
    }
  };
}

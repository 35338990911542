import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AccordionService } from '@app/shared/services/accordion.service';
import { AlgoliaSearchService } from 'app/shared/services/algolia-search.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
@Component({
  selector: 'app-site-search',
  templateUrl: './site-search.component.html',
  styleUrls: ['./site-search.component.scss'],
})
export class SiteSearchComponent implements OnInit, AfterViewInit {
  @ViewChild('algoliaSearch') algoliaSearch: ElementRef;

  @Input() location: string;

  searching = false;
  searchFailed = false;
  hideSearchingWhenUnsubscribed = new Observable(() => () => (this.searching = false));
  activePanel;
  category;
  selectedCategory = 'all';
  isHome: boolean;

  constructor(
    private algoliaSearchService: AlgoliaSearchService,
    private router: Router,
    private accordionService: AccordionService,
    private fb: FormBuilder,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    // if (this.location === 'home') {
    //   this.selectedCategory = 'web';
    // } else {
    //   this.selectedCategory = 'all';
    // }
    this.searchCatForm.patchValue({ searchCat: this.selectedCategory });
  }

  searchCatForm = this.fb.group({
    searchCat: [this.selectedCategory],
  });
  ngAfterViewInit() {
    this.algoliaSearch.nativeElement.focus();
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((term) => term.length > 2),
      tap(() => {
        this.searching = true;
      }),
      switchMap((term) => {
        return this.algoliaSearchService.search(term, this.searchCatForm.value.searchCat);
      }),
      tap(() => {
        this.searching = false;
      }),
    );

  resultFormatter = (result) => {
    // eslint-disable-next-line @typescript-eslint/camelcase
    result.post_title = result.post_title.replace('<tt>', '').replace('</tt>', ': ');
    return `${result.post_title}`;
  };

  selectItem = ($event) => {
    $event.preventDefault();

    let slug = $event.item.permalink;
    this.category = $event.item.taxonomies.category[0];

    let path = `/${this.category}`;

    if (this.category === 'get started') {
      path = '';
    } else if ($event.item.post_type === 'page') {
      path = '';
    }

    slug = slug.replace(/\/{1}$/gi, ''); // remove last slash

    const url = `${path}/${slug}`;

    // if (category !== this.activePanel) {
    //   this.webAccordion.collapseAll();
    //   this.webAccordion.toggle(category);
    // }

    this.activePanel = this.category;
    // console.log('this.activePanel', this.activePanel);
    this.accordionService.sendCategory(this.category);

    // this.scrolltoActiveLink();

    this.router.navigateByUrl(url);
  };
}

<ng-template #content let-offcanvas>
  <div class="offcanvas-header">
    <button class="btn" (click)="offcanvas.dismiss('Cross click')" type="button" aria-label="Close Sidebar">
      <i class="pbi-icon-mini pbi-close"></i>
    </button>
  </div>
  <div class="offcanvas-body">
    <app-sidenav></app-sidenav>
  </div>
</ng-template>

<header class="home-header bg-brand-header fixed-top">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <nav class="navbar navbar-expand-sm navbar-light d-flex justify-content-evenly">
          <div class="home-header d-flex">
            <button class="btn text-white me-2" (click)="open(content)" type="button" aria-hidden="true">
              <i class="pbi-icon-mini pbi-menu"></i>
            </button>

            <a class="navbar-brand" routerLink="/home"> Design System </a>
          </div>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item" ngbDropdown>
                <button
                  class="version nav-link dropdown-toggle-caret d-flex align-items-center"
                  aria-expanded="false"
                  ngbDropdownToggle
                  *ngFor="let version of versionlist | slice: 0 : 1"
                >
                  <div class="me-3 d-flex flex-column align-items-start">
                    <div class="">
                      <span class="badge rounded-pill text-bg-success badge-new me-2">LATEST</span>
                      <strong>v{{ version.designsystem }}</strong> (Angular {{ version.angular }} / Bootstrap
                      {{ version.bootstrap }})
                    </div>

                    <div *ngIf="version.migration" class="text-small">
                      <a href="{{ version.migration.link }}" target="_blank">Migration Guide</a>
                    </div>
                  </div>
                </button>
                <div ngbDropdownMenu>
                  <div class="px-4 py-2" *ngFor="let version of versionlist">
                    <div>
                      <strong>{{ version.designsystem }}</strong>
                    </div>

                    <div class="text-nowrap">Angular {{ version.angular }} / Bootstrap {{ version.bootstrap }}</div>

                    <a
                      *ngIf="version.documentation"
                      class="d-block"
                      href="{{ version.documentation.link }}"
                      target="_blank"
                      >Website</a
                    >

                    <ng-container *ngIf="version.releaseNotes">
                      <ng-container *ngIf="version.releaseNotes.internal">
                        <a class="d-block" [routerLink]="version.releaseNotes.link">Release Notes</a>
                      </ng-container>

                      <ng-container *ngIf="!version.releaseNotes.internal">
                        <a class="d-block" href="{{ version.releaseNotes.link }}" target="_blank">Release Notes</a>
                      </ng-container>
                    </ng-container>

                    <a *ngIf="version.migration" class="d-block" href="{{ version.migration.link }}" target="_blank"
                      >Migration Guide</a
                    >
                  </div>

                  <div class="dropdown-divider"></div>

                  <a routerLink="/downloads" ngbDropdownItem>Previous Releases</a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>

<div class="hero-main">
  <div class="container text-white">
    <div class="row justify-content-center">
      <div class="col-auto d-flex flex-column align-items-center">
        <h1 class="text-white mb-0">Welcome to Design System</h1>
        <p style="margin-left: auto; margin-right: 1.5rem">Release 16</p>
        <app-site-search location="home"></app-site-search>
        <p class="mt-2">
          <a class="link-light text-large text-decoration-underline" routerLink="/pages/release-notes">Release Notes</a>
          <span class="d-inline-block mx-4">|</span>
          <a class="link-light text-large text-decoration-underline" href="https://update.angular.io/" target="blank"
            >Angular 16 Guide</a
          >
          <span class="d-inline-block mx-4">|</span>
          <a
            class="link-light text-large text-decoration-underline"
            href="https://getbootstrap.com/docs/5.2/migration/"
            target="blank"
            >Bootstrap 5.2</a
          >
        </p>
      </div>
    </div>
  </div>
  <svg
    class="hero-waves"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28 "
    preserveAspectRatio="none"
  >
    <defs>
      <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
    </defs>
    <g class="wave1">
      <use xlink:href="#wave-path" x="50" y="3" fill="currentColor" opacity=".2"></use>
    </g>
    <g class="wave2">
      <use xlink:href="#wave-path" x="50" y="0" fill="currentColor" opacity=".3"></use>
    </g>
    <g class="wave3">
      <use xlink:href="#wave-path" x="50" y="9" fill="currentColor"></use>
    </g>
  </svg>
</div>

<div class="container content">
  <div class="row justify-content-evenly g-0 my-7">
    <div class="col border-end px-7">
      <h3 class="text-white mt-2">Starter App</h3>
      <p class="">
        Get the Starter app, install dependencies with <kbd class="text-bg-light">npm run setup</kbd>, and start
        developing. It's got everything you need for a basic website structure – just customize to your liking.
      </p>
      <a class="mat-ripple btn btn-lg btn-outline-light" href="https://github.com/PBGUX/ng-starter-app" target="_blank"
        >Get the Starter App</a
      >
    </div>
    <div class="col px-7">
      <h3 class="text-white mt-2">Install the Design System</h3>

      <p class="">
        Starting an Angular application from scratch? Install the Design System with npm and follow the readme
        instructions to get started.
      </p>
      <ng-template #tipContent>{{ tooltipMessage }}</ng-template>
      <div class="input-group">
        <input
          type="text"
          class="form-control form-control-lg border-light text-white bg-transparent"
          value="npm install pb-design-system --save"
        />
        <br />
        <button
          ngbTooltip="Click to Copy"
          class="btn btn-lg btn-outline-light"
          type="button"
          (click)="copyMessage('npm install pb-design-system --save')"
          (hidden)="tooltipHidden()"
          [ngbTooltip]="tipContent"
        >
          <i class="pbi-icon-mini pbi-clipboard"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <section class="slides">
    <ng-container *ngIf="slideData$ | async as slideData">
      <app-carousel-section [slideData]="slideData.whatsNew" sectionTitle="What's New"></app-carousel-section>
      <app-carousel-section [slideData]="slideData.developers" sectionTitle="For Developers"></app-carousel-section>
      <app-carousel-section [slideData]="slideData.designers" sectionTitle="For Designers"></app-carousel-section>
    </ng-container>
  </section>
</div>

<app-footer #footer></app-footer>

import {
  formatDate,
  FormatWidth,
  FormStyle,
  getLocaleDateFormat,
  getLocaleDayNames,
  getLocaleFirstDayOfWeek,
  getLocaleMonthNames,
  TranslationWidth
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Injectable,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDatepickerI18n, NgbDateStruct, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {
  PbdsDaterangeChange,
  PbdsDaterangeFilter,
  PbdsDaterangeFilterChange,
  PbdsDaterangePlacement,
  PbdsDaterangePreset,
  PbdsDaterangePresetValue
} from './daterange-popover.interfaces';
import { PbdsDaterangeService } from './daterange-popover.service';

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(public daterangeService: PbdsDaterangeService) {
    super();
  }

  getWeekdayLabel(weekday: number): string {
    // for ng-bootstrap, sunday number of 7 converted to 0
    weekday = weekday === 7 ? 0 : weekday;

    // console.log(
    //   'weekday: ',
    //   this.daterangeService.getCurrentLocale(),
    //   weekday,
    //   getLocaleDayNames(this.daterangeService.getCurrentLocale(), FormStyle.Standalone, TranslationWidth.Abbreviated)[weekday]
    // );

    return getLocaleDayNames(
      this.daterangeService.getCurrentLocale(),
      FormStyle.Standalone,
      TranslationWidth.Abbreviated
    )[weekday];
  }

  getMonthShortName(month: number): string {
    return getLocaleMonthNames(this.daterangeService.getCurrentLocale(), FormStyle.Standalone, TranslationWidth.Wide)[
      month - 1
    ];
  }

  getMonthFullName(month: number): string {
    return getLocaleMonthNames(this.daterangeService.getCurrentLocale(), FormStyle.Standalone, TranslationWidth.Wide)[
      month - 1
    ];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'pbds-daterange-popover',
  templateUrl: './daterange-popover.component.html',
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PbdsDaterangePopoverComponent),
      multi: true
    }
  ],
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PbdsDaterangePopoverComponent implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild('datepickerPopup', { static: false }) private datepickerPopup: NgbPopover;

  @Input()
  presets: Array<PbdsDaterangePreset> = [
    {
      label: 'All Dates',
      value: null
    },
    {
      label: 'Last 7 Days',
      value: 7
    },
    {
      label: 'Last 30 Days',
      value: 30
    },
    {
      label: 'Previous Month',
      value: 'PREVIOUS_MONTH'
    },
    {
      label: 'Year to Date',
      value: 'YEAR_TO_DATE'
    }
  ];

  @Input()
  presetSelected: PbdsDaterangePresetValue = null;

  @Input()
  filters: Array<PbdsDaterangeFilter>;

  @Input()
  filterSelected = 0;

  @Input()
  showCustomPreset = true;

  @Input()
  applyText = 'Apply';

  @Input()
  cancelText = 'Cancel';

  @Input()
  container: null | 'body' = 'body';

  @Input()
  customRangeText = 'Custom Range';

  @Input()
  displayMonths = 2;

  @Input()
  displayInput = true;

  @Input()
  minDate: NgbDate = this.calendar.getPrev(this.calendar.getToday(), 'y');

  @Input()
  maxDate: NgbDate = this.calendar.getToday();

  @Input()
  placement: PbdsDaterangePlacement = 'bottom-right auto';

  @Input()
  fromDate: NgbDate | null = null;

  @Input()
  toDate: NgbDate | null = null;

  @Input()
  inputFormat = '{fromDate} to {toDate}';

  @Input()
  ariaLabel = 'Open date picker';

  @Input()
  ariaLabelSelected = 'Open date picker, selected range is {selectedRange}';

  @Output()
  dateChange = new EventEmitter<PbdsDaterangeChange>();

  @Output()
  filterChange = new EventEmitter<PbdsDaterangeFilterChange>();

  @Output()
  cancel = new EventEmitter<any>();

  firstDayOfWeek = getLocaleFirstDayOfWeek(this.daterangeService.getCurrentLocale());

  hoveredDate: NgbDate;

  dateRange = '';
  isDatepickerVisible = false;
  selectedFilter;
  startDate: NgbDate;
  formattedDate;
  emitValue: PbdsDaterangeChange;
  canEmit: boolean = true;
  private onTouched: any = () => {};
  private onChange = (obj: any) => {};

  constructor(private calendar: NgbCalendar, private daterangeService: PbdsDaterangeService) {
    this.writeValue(this.emitValue);
  }

  ngOnInit() {
    // china should start on a Monday, Angular locale returns incorrect 0
    this.firstDayOfWeek =
      this.daterangeService.getCurrentLocale() === 'zh-cn' ? this.firstDayOfWeek + 1 : this.firstDayOfWeek;

    if (this.presetSelected === 'CUSTOM') {
      this.showDatepicker();
    }

    if (this.presets) {
      if (!this.filters && this.presetSelected) {
        this.presetClick(
          this.presets.find((p, index) => {
            return p.value === this.presetSelected;
          })
        );
      } else if (this.presetSelected) {
        this.presetSelect({ value: this.presetSelected });

        this.onApply(false);
      }
    }
    this.onApply(false);
  }
  openPbdsDateRangePopup() {
    this.datepickerPopup.open();
  }

  // programmatically writing the value
  writeValue(value: any) {
    if (value) {
      // console.log('WRITE VALUE: ', value);

      const filterIndex = this.filters.findIndex((filter) => {
        return filter.field === value.filter;
      });

      this.fromDate = value.fromDate;
      this.toDate = value.toDate;
      this.formattedDate = value.formattedDate;
      this.presetSelected = value.value;
      this.selectedFilter = this.filters[filterIndex];
      this.isDatepickerVisible = this.presetSelected === 'CUSTOM' ? true : false;

      this.onApply();
    }
  }

  // method to be triggered on UI change
  registerOnChange(onChange: any) {
    // console.log('ONCHANGE: ', this.emitValue);
    this.onChange = onChange;
  }

  // method to be triggered on component touch
  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('CHANGES: ', changes);

    if (changes.filters && this.filters) {
      this.selectedFilter = this.filters[this.filterSelected];
    }

    if (changes.presets && changes.presets.isFirstChange()) {
      if (!this.filters && this.presetSelected) {
        this.presetClick(
          this.presets.find((p, index) => {
            return p.value === this.presetSelected;
          })
        );
      } else if (this.presetSelected) {
        this.presetSelect({ value: this.presetSelected });
        this.onApply();
      }
    }

    // if (changes.toText && changes.toText.firstChange === false) {
    //   this.setInputLabel();
    // }

    this.setInputLabel();
  }

  onApply(shouldEmit = true) {
    // if only a CUSTOM start date is selected, set the end date to the start date (i.e select a single day)
    if (!this.toDate) {
      this.toDate = this.fromDate;
    }

    this.setInputLabel();

    this.emitValue = {
      fromDate: this.fromDate,
      toDate: this.toDate,
      formattedDate: this.formattedDate,
      filter: this.filters && this.filters.length > 0 ? this.selectedFilter.field : null,
      value: this.presetSelected
    };

    this.startDate = this.fromDate;

    if (shouldEmit) {
      this.dateChange.emit(this.emitValue);

      this.datepickerPopup?.close();

      this.ariaLabel = this.ariaLabelFormat();
    }

    setTimeout(() => this.onChange(this.emitValue), 0);
  }

  onCancel() {
    this.datepickerPopup.close();

    this.cancel.emit();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    // this.presetSelected = null;
  }

  presetSelect = ($event: Partial<PbdsDaterangePreset>) => {
    if ($event.value === 'CUSTOM') {
      this.presetSelected = 'CUSTOM';
      return false;
    }
    this.setDateProperties($event.value);
    this.isDatepickerVisible = false;
  };

  presetClick(preset: PbdsDaterangePreset) {
    if (preset) {
      if (preset.value === 'CUSTOM') {
        return false;
      }
      this.setDateProperties(preset.value);
      this.isDatepickerVisible = false;
      this.onApply();
    }
  }

  private getFormattedDate(date: NgbDate) {
    if (date) {
      const locale = this.daterangeService.getCurrentLocale();
      const dateFormat = getLocaleDateFormat(locale, FormatWidth.Short);
      const formattedDate = formatDate(`${date.month}/${date.day}/${date.year}`, dateFormat, locale);
      return formattedDate;
    }
  }

  isHovered = (date: NgbDate) =>
    this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);

  isInside = (date: NgbDate) => date.after(this.fromDate) && date.before(this.toDate);

  isRange = (date: NgbDate) =>
    date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);

  showDatepicker() {
    this.isDatepickerVisible = true;
    this.presetSelect({ value: 'CUSTOM' });
  }

  onFilterChange($event: Event, filter: PbdsDaterangeFilter, index: number) {
    this.selectedFilter = this.filters[index];

    this.filterChange.emit({
      event: $event,
      filter: filter,
      index: index
    });
  }

  setPreset(value: PbdsDaterangePresetValue) {
    this.presetSelected = value;
    this.presetSelect({ value: this.presetSelected });
    this.onApply();
  }

  setFilter(index: number) {
    if (this.filters !== undefined) {
      this.selectedFilter = this.filters[index];
    }
  }

  setDateRange(value) {
    this.fromDate = new NgbDate(value.fromDate.year, value.fromDate.month, value.fromDate.day);
    this.toDate = new NgbDate(value.toDate.year, value.toDate.month, value.toDate.day);
    this.isDatepickerVisible = value.value === 'CUSTOM';
    this.presetSelected = value.value;

    if (this.filters) {
      this.filterSelected = this.filters.findIndex((f) => f.field === value.filter);
      this.selectedFilter = this.filters[this.filterSelected];
    }
    this.onApply();
  }

  private setInputLabel() {
    if (this.presets) {
      const selected = this.presets.find((p) => p.value === this.presetSelected);

      if (selected) {
        if (this.fromDate === null || this.toDate === null) {
          this.dateRange = selected.label;
        } else if (this.presetSelected === null || (this.presetSelected !== null && this.presetSelected !== 'CUSTOM')) {
          this.dateRange = selected.label;
        } else {
          this.dateRange = this.dateFormat();
        }
      } else if (this.presetSelected === 'CUSTOM' && this.fromDate && this.toDate) {
        this.dateRange = this.dateFormat();
      }

      if (this.dateRange !== '') {
        this.formattedDate = this.isDatepickerVisible ? this.dateFormat() : this.dateRange;
        this.ariaLabel = this.ariaLabelFormat();
      }
    }
  }

  private dateFormat() {
    return this.inputFormat
      .replace('{fromDate}', this.getFormattedDate(this.fromDate))
      .replace('{toDate}', this.getFormattedDate(this.toDate));
  }

  private ariaLabelFormat() {
    return this.ariaLabelSelected.replace('{selectedRange}', this.formattedDate);
  }

  private getDaysInMonth(year: number, month: number) {
    return new Date(year, month, 0).getDate();
  }

  private getFromAndToDates(value: PbdsDaterangePresetValue): { from: NgbDateStruct; to: NgbDateStruct } {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const currentDay = now.getDate();
    switch (value) {
      case 'PREVIOUS_MONTH':
        const year = currentMonth > 0 ? currentYear : currentYear - 1;
        const month = currentMonth > 0 ? currentMonth : 12;
        const day = 1;
        const lastDay = this.getDaysInMonth(year, month);
        return {
          from: { year, month, day },
          to: { year, month, day: lastDay }
        };
      case 'YEAR_TO_DATE':
        return {
          from: { year: currentYear, month: 1, day: 1 },
          to: { year: currentYear, month: currentMonth + 1, day: currentDay }
        };
      default:
        return { from: null, to: null };
    }
  }

  private setDateProperties(value: PbdsDaterangePresetValue) {
    if (value === 'PREVIOUS_MONTH' || value === 'YEAR_TO_DATE') {
      const { from, to } = this.getFromAndToDates(value);
      this.fromDate = new NgbDate(from.year, from.month, from.day);
      this.toDate = new NgbDate(to.year, to.month, to.day);
      this.presetSelected = value;
      this.startDate = this.fromDate;
    } else if (typeof value === 'number') {
      const isFuture = Math.sign(value) === -1 ? true : false;

      if (isFuture) {
        this.fromDate = this.calendar.getToday();
        this.toDate = this.calendar.getNext(this.fromDate, 'd', Number(Math.abs(value)));
        this.presetSelected = value;
        this.startDate = this.toDate;
        // console.log('FUTURE: ', this.fromDate, this.toDate, this.presetSelected, this.startDate);
      } else {
        this.toDate = this.calendar.getToday();
        this.fromDate = this.calendar.getPrev(this.toDate, 'd', Number(value));
        this.presetSelected = value;
        this.startDate = this.fromDate;
      }
    } else {
      this.fromDate = null;
      this.toDate = null;
      this.presetSelected = null;
      this.startDate = null;
    }
  }
}

import { Component, OnInit, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  @HostBinding('class.site-footer')
  // @HostBinding('class.fixed-bottom')
  private startYear = 2015;
  public date: string;
  public footerContainerType: string;
  public footerLogin: false;

  constructor(private _route: ActivatedRoute) {}

  ngOnInit() {
    this.footerContainerType = this._route.snapshot.firstChild.data.containerType || 'container-fluid';
    this.footerLogin = this._route.snapshot.firstChild.data.footerLogin;

    const currentYear: number = new Date().getFullYear();
    this.date = this.startYear === currentYear ? `${currentYear}` : `${this.startYear}-${currentYear}`;
  }
}

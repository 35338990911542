import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'pbds-dataviz-metric-indicator',
  template: `
    <span>{{ value }}</span>
  `,
  styles: []
})
export class PbdsDatavizMetricIndicatorComponent {
  @Input()
  value = 0;

  @Input()
  class = '';

  @Input()
  indicator: 'flat' | 'increase' | 'decrease' = 'flat';

  @Input()
  inverse = false;

  @HostBinding('class')
  get hostClasses() {
    return ['metric-block-indicator', this.indicator, this.inverse ? 'inverse' : '', this.class].join(' ');
  }
}

import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'pbds-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PbdsAvatarComponent {
  @Input() label: string;

  @Input() icon: string;

  @Input() image: string;

  @Input() size = 'normal';

  @Input() shape = 'square';

  @Input() style: any;

  @Input() styleClass: string;

  @Input() value: string;

  @Input() title: string;

  containerClass() {
    return {
      'pb-avatar': true,
      'pb-avatar-image': this.image != null,
      'pb-avatar-circle': this.shape === 'circle',
      'pb-avatar-sm': this.size === 'small',
      'pb-avatar-lg': this.size === 'large',
      'pb-avatar-xl': this.size === 'xlarge'
    };
  }
}

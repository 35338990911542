<svg
  [ngClass]="{ static: static }"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="-2 -2 142 142"
  xml:space="preserve"
>
  <defs>
    <linearGradient id="a" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop stop-color="var(--primary)" offset="0%" />
      <stop stop-color="var(--secondary)" offset="100%" />
    </linearGradient>
  </defs>

  <style>
    #outer_circle {
      transform-origin: center;
      animation: spinner_StKS 6s 1 linear;
      animation-direction: reverse;
    }
    #inner_circle {
      transform-origin: center;
      animation: spinner_StKS 6s 1 linear;
    }

    .static #inner_circle,
    .static #outer_circle {
      animation: none;
    }

    @keyframes spinner_StKS {
      100% {
        transform: rotate(360deg);
      }
    }
  </style>
  <rect width="160" height="160" fill="url(#a)" mask="url(#icon)"></rect>
  <mask id="icon">
    <g id="clock" fill="#fff">
      <g>
        <g>
          <path
            class="st0"
            d="M46.8,97.1c-1.5,1-3.5,0.7-4.3-0.5c-0.6-0.9-0.6-2.2,0.1-3.3l0.1-0.1c0.3-0.7,0.1-1.5-0.6-1.9
				c-0.7-0.4-1.6-0.2-2.1,0.5c-1.2,2.1-1.2,4.7,0.1,6.5c1.8,2.6,5.6,3.2,8.4,1.3c0.7-0.4,0.9-1.4,0.5-2.1
				C48.4,96.8,47.5,96.6,46.8,97.1z"
          />
          <path
            class="st0"
            d="M83.9,81.7c-0.6-0.5-1.5-0.4-2,0.1c-0.6,0.6-0.6,1.5,0,2.1l9.8,9.8l0.1,0.1c0.6,0.5,1.5,0.4,2-0.1
				c0.6-0.6,0.6-1.5,0-2.1L84,81.8L83.9,81.7z"
          />

          <path class="st0" d="M94,50.9C94,50.9,94,50.9,94,50.9L94,50.9L94,50.9z" />

          <path
            d="M91,69c0.1,0,0.2,0,0.2,0c2.7-0.2,5.5-1,8.3-2.6c1.4-0.8,2.7-1.9,4.1-3.4c2-2.2,3.5-4.8,4.3-7.9c0.8-3.1,0.6-6.1-0.1-9
				c-0.5-1.9-1.2-3.3-1.9-4.6c-0.5-0.8-1.7-1-2.4-0.3L94,50.9c0,0,0,0,0,0l0,0l0,0c-1.4,1.1-3.3,0.9-4.3-0.2l-0.1-0.1
				c-0.6-0.8-0.6-2.1-0.1-3.2l0-0.1l9.8-10c0.7-0.7,0.5-1.8-0.3-2.3c-0.5-0.3-1-0.5-1.6-0.8c-1-0.4-2-0.8-3.1-1.1
				c-3-0.8-6.2-0.8-9.5,0.1c-5.3,1.6-8.7,5-10.8,8.9c-1.3,2.4-1.9,4.8-2.2,7.2l0,0.1c0,0.5,0,1.1-0.1,1.7l0,0.5
				c-0.1,0.9-0.1,1.3-0.2,1.8l-0.1,0.2c-0.2,0.7-0.5,1.5-1.1,2.3l-0.1,0.2L67,59.3l-0.3,0.3l-7.8-7.8l0-4.9l0-0.1
				c0-0.3-0.2-0.7-0.4-0.9L48,35.4l-0.1-0.1c-0.6-0.5-1.5-0.4-2,0.1L35.5,45.8l-0.1,0.1c-0.5,0.6-0.4,1.5,0.1,2l10.4,10.4l0.1,0.1
				c0.3,0.2,0.6,0.3,0.9,0.3l4.9,0l7.6,7.6L43.6,81.8c-3.5,3.4-5.5,5.3-7.1,6.9c-3.9,4.1-3.9,10.5-0.2,14.4
				c3.9,4.1,10.5,4.2,14.7,0.3l1.8-1.7L69.2,86l16.7,16.7l0.2,0.2c4.7,4.4,12.1,4.4,16.7-0.2c4.7-4.7,4.7-12.2,0-16.9L86.8,69.8
				c0.4-0.2,0.7-0.3,1.1-0.4C89,69,90,69,91,69z M53.6,56.3l-0.1-0.1c-0.3-0.2-0.6-0.3-0.9-0.3l-4.9,0l-8.9-8.9l8.3-8.3l8.9,8.9
				l0,4.9l0,0.1c0,0.3,0.2,0.7,0.4,0.9l8.2,8.2c-0.8,0.8-1.8,1.7-2.9,2.8L53.6,56.3z M100.7,88l0.2,0.2c3.3,3.5,3.2,9-0.2,12.4
				c-3.5,3.5-9.2,3.5-12.7,0L71.4,84l1-1c1.4-1.3,2.3-2.2,2.7-2.6l4.2-4.2l2.9-2.9c0.7-0.7,1.2-1.2,1.7-1.6c0.1-0.1,0.2-0.2,0.4-0.3
				L100.7,88z M83.7,68.1c-0.2,0.1-0.4,0.3-0.6,0.4c-0.4,0.3-0.8,0.6-1.2,1l-0.4,0.3c-0.3,0.3-0.6,0.6-1,0.9l-0.7,0.7L76,75.3
				l-3.4,3.4C71.2,80,67.5,83.6,62,88.8l-12.6,12.1c-3,3.1-8,3.1-10.8,0.2c-2.6-2.8-2.6-7.3,0.1-10.2l2.2-2.2c0.8-0.8,1.8-1.8,3-2.9
				l24.9-24c1.5-1.5,2.7-2.6,3.6-3.6l0.1-0.1c1.1-1.4,1.7-2.7,2-4c0.1-0.5,0.2-1,0.2-1.8l0.1-0.8c0-0.5,0.1-1,0.1-1.4l0-0.4l0,0l0,0
				l0,0c0.2-2,0.7-4,1.8-6c1.8-3.3,4.6-6.2,9-7.5c2.7-0.8,5.3-0.7,7.9-0.1l0.5,0.1c0.5,0.1,0.9,0.3,1.3,0.4l0.4,0.2l-8.5,8.7
				c-0.1,0.1-0.1,0.2-0.2,0.3c-1.4,2.2-1.3,5.1,0.3,7c2,2.4,5.9,2.7,8.5,0.6l0.1-0.1l8.1-8.1l0,0.1c0.2,0.6,0.5,1.2,0.7,1.9
				c0.6,2.4,0.7,4.9,0.1,7.5c-0.6,2.6-1.9,4.7-3.6,6.5c-1.2,1.2-2.3,2.1-3.4,2.8L91.4,66l-0.3,0c0,0,0.1,0,0.1,0l-0.1,0l0,0
				c-1.3-0.1-2.6,0-4.1,0.4C85.8,66.8,84.8,67.3,83.7,68.1z"
          />
        </g>
      </g>
    </g>
    <g id="inner_circle" fill="#fff">
      <path
        class="st1"
        d="M121.6,48.3c-9.2-19.9-29.1-32.9-51.4-32.9c-9.6,0-18.8,2.4-27.1,6.9c-0.7,0.4-1,1.3-0.6,2
		c0.4,0.7,1.3,1,2,0.6c7.8-4.3,16.6-6.5,25.6-6.5c21.1,0,40,12.3,48.7,31.2c0.3,0.8,1.2,1.1,2,0.7C121.6,50,121.9,49.1,121.6,48.3z"
      />
      <path
        class="st1"
        d="M15.1,70.5c0.8,0,1.5,0.7,1.5,1.5c0,29.6,24,53.6,53.6,53.6c24.6,0,45.9-16.8,51.9-40.3c0.2-0.8,1-1.3,1.8-1.1
		c0.8,0.2,1.3,1,1.1,1.8c-6.3,24.9-28.8,42.5-54.8,42.5c-31.3,0-56.6-25.3-56.6-56.6C13.6,71.2,14.2,70.5,15.1,70.5z"
      />
    </g>
    <g id="outer_circle" fill="#fff">
      <path
        class="st1"
        d="M104.4,11.1C94,5.2,82.2,2,69.9,2C31.3,2,0,33.3,0,71.9c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5
		C3,35,33,5,69.9,5c11.7,0,23,3,33,8.7c0.7,0.4,1.6,0.2,2-0.6C105.4,12.4,105.1,11.5,104.4,11.1z"
      />
      <path
        class="st1"
        d="M11.5,104.6c-0.4-0.7-1.3-1-2-0.6c-0.7,0.4-1,1.3-0.6,2c7.8,13.9,20.3,24.9,35.2,30.8c0.8,0.3,1.6-0.1,1.9-0.8
		s-0.1-1.6-0.8-1.9C31,128.4,19.1,118,11.5,104.6z"
      />
      <path
        class="st1"
        d="M138.3,70.4c0.8,0,1.5,0.7,1.5,1.5c0,28.8-17.6,54.4-43.9,64.9c-0.8,0.3-1.6-0.1-2-0.8
		c-0.3-0.8,0.1-1.6,0.8-1.9c25.2-10.1,42.1-34.5,42.1-62.1C136.8,71.1,137.5,70.4,138.3,70.4z"
      />
    </g>
  </mask>
</svg>

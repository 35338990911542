import { Component, OnInit, Input, ElementRef, HostBinding, OnChanges, SimpleChanges } from '@angular/core';

import { select as d3_select } from 'd3-selection';
import { interpolate as d3_interpolate } from 'd3-interpolate';
import { arc as d3_arc } from 'd3-shape';
import { format as d3_format } from 'd3-format';

import { PbdsDatavizService } from './dataviz.service';
import { PbdsDatavizGauge } from './dataviz.interfaces';

@Component({
  selector: 'pbds-dataviz-gauge',
  template: `
    <div
      *ngIf="!hideLabel"
      class="gauge-details"
      [ngClass]="{ halfmoon: type === 'halfmoon', 'gauge-details-small': type === 'halfmoon' }"
      [ngStyle]="{ 'max-width.px': width - 3 * gaugeWidth, 'padding-top.px': detailsPaddingTop }"
    >
      <div class="gauge-number">{{ label }}</div>
      <div *ngIf="description" class="gauge-description text-center">{{ description }}</div>
    </div>
  `,
  styles: []
})
export class PbdsDatavizGaugeComponent implements OnInit, OnChanges {
  @HostBinding('class.pbds-chart')
  chartClass = true;

  @HostBinding('class.pbds-chart-gauge')
  gaugeClass = true;

  @Input()
  data: PbdsDatavizGauge;

  @Input()
  width = 300;

  @Input()
  type: 'halfmoon' | 'horseshoe' | 'circle' = 'halfmoon';

  @Input()
  color = '#E23DA8';

  @Input()
  hideLabel = false;

  @Input()
  labelFormatString = '';

  @Input()
  description;

  @Input()
  gaugeWidth = 20;

  public label;
  private chart;
  private svg;
  private gauge;
  private labelTween;
  private startAngle;
  private endAngle;
  private radius;
  private arc;
  private labelFormat;
  private oldValue;
  private height;
  private rounded;
  private detailsPaddingTop = 0;

  constructor(private _dataviz: PbdsDatavizService, private _element: ElementRef) {}

  ngOnInit() {
    this.height = this.width;
    this.radius = Math.max(this.width, this.height) / 2;
    this.labelFormat = d3_format(this.labelFormatString);
    this.label = this.labelFormat(this.data.value);

    switch (this.type) {
      case 'halfmoon':
        this.startAngle = -90;
        this.endAngle = 90;
        this.rounded = true;
        break;

      case 'horseshoe':
        this.startAngle = -140;
        this.endAngle = 140;
        this.rounded = true;
        break;

      case 'circle':
        this.startAngle = 0;
        this.endAngle = 360;
        this.rounded = false;
        break;
    }

    this.arc = d3_arc().cornerRadius(this.rounded ? this.gaugeWidth : 0);

    this.chart = d3_select(this._element.nativeElement).attr('aria-hidden', 'true');

    this.svg = this.chart
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('class', 'img-fluid')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', `-${this.width / 2} -${this.height / 2} ${this.width} ${this.height}`);

    this.drawChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      this.oldValue = changes.data.previousValue.value;
      this.updateChart();
    }
  }

  degreesToRadians = (degree) => {
    return (degree * Math.PI) / 180;
  };

  calculateMinMax = () => {
    const percentage = this.data.minvalue / (this.data.maxvalue - this.data.minvalue);

    return percentage * (this.data.value - this.data.minvalue) + (this.data.value - this.data.minvalue);
  };

  calculateCurve = (data) => {
    const start = this.degreesToRadians(this.startAngle);
    const end = start + (data * (this.degreesToRadians(this.endAngle) - start)) / this.data.maxvalue;

    return [
      {
        startAngle: start,
        endAngle: end
      }
    ];
  };

  drawChart = () => {
    this.gauge = this.svg.append('g').attr('class', 'gauge-group');

    // background arc
    this.gauge
      .append('path')
      .data(this.calculateCurve(this.data.maxvalue))
      .attr('class', 'gauge-background')
      .attr('d', (d) => {
        return this.arc({
          innerRadius: this.radius - this.gaugeWidth,
          outerRadius: this.radius,
          startAngle: d.startAngle,
          endAngle: d.endAngle
        });
      });

    // value arc
    this.gauge
      .append('path')
      .data(this.calculateCurve(this.calculateMinMax()))
      .attr('class', 'gauge-value')
      .attr('fill', this.color)
      .attr('d', (d) => {
        return this.arc({
          innerRadius: this.radius - this.gaugeWidth,
          outerRadius: this.radius,
          startAngle: d.startAngle,
          endAngle: d.endAngle
        });
      });

    switch (this.type) {
      case 'horseshoe':
        const svgClone: SVGSVGElement = this.svg.node().cloneNode(true);

        const fakeSvg = document.body.appendChild(svgClone);
        fakeSvg.setAttribute('style', 'position: absolute; left: -10000px;'); // position the cloned element offscreen

        const fakeGroup: any = fakeSvg.getElementsByClassName('gauge-group')[0];
        const fakeBox = fakeGroup.getBBox();

        this.svg
          .attr('height', fakeBox.height)
          .attr('viewBox', `-${this.width / 2} -${this.width / 2} ${this.width} ${fakeBox.height}`);

        this.detailsPaddingTop = this.gaugeWidth;

        fakeSvg.remove(); // remove the cloned element from the DOM
        break;
      case 'halfmoon':
        this.svg.attr('height', this.width / 2);
        this.svg.attr('viewBox', `-${this.width / 2} -${this.width / 2} ${this.width} ${this.width / 2}`);
        break;
    }
  };

  updateChart = () => {
    const group = this.svg.select('.gauge-group');

    group.select('.gauge-value').transition().duration(750).call(this.arcTween, this.calculateMinMax());

    this.labelTween = this.chart.select('.gauge-number');

    this.labelTween.transition().duration(750).call(this.textTween, this.data.value);
  };

  arcTween = (transition, value) => {
    const newAngle = this.calculateCurve(value);

    transition.attrTween('d', (d) => {
      const interpolate = d3_interpolate(d.endAngle, newAngle[0].endAngle);

      return (t) => {
        d.endAngle = interpolate(t);

        return this.arc({
          innerRadius: this.radius - this.gaugeWidth,
          outerRadius: this.radius,
          startAngle: d.startAngle,
          endAngle: d.endAngle
        });
      };
    });
  };

  textTween = (transition, value) => {
    value = d3_format('.4f')(value);
    value = value.replace(/,/g, '.');

    transition.tween('text', () => {
      const interpolate = d3_interpolate(d3_format('.4f')(+this.oldValue), value);

      return (t) => {
        this.labelTween.text((d) => {
          const updatedNumber = this.labelFormat(interpolate(t));
          this.label = updatedNumber;
          return updatedNumber;
        });
      };
    });
  };
}

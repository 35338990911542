import {
  Directive,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { select as d3_select } from 'd3-selection';
import { easeQuadInOut as d3_easeQuadInOut } from 'd3-ease';

import { PbdsDatavizBarGroupedComponent } from './dataviz-bar-grouped.component';

const ANNOTATION_MARGIN_TOP = 62;
const ANNOTATION_OFFSET = -22;
const ANNOTATION_COMMENT_OFFSET = -47;
const TRANSITION_DURATION = 1000;
const TRANSITION_DELAY = 500;

@Directive({
  selector: 'pbds-dataviz-bar-grouped[annotations]'
})
export class PbdsBarGroupedAnnotationsDirective implements OnInit, OnChanges {
  @Input('annotations') annotations;

  @Input('annotationsHilight') annotationsHilight = null;

  @Output() annotationClicked: EventEmitter<any> = new EventEmitter();

  private annotationsGroup;
  private hilightBox;

  constructor(@Inject(forwardRef(() => PbdsDatavizBarGroupedComponent)) private component) {
    component.marginTop = ANNOTATION_MARGIN_TOP;
  }

  ngOnInit(): void {
    this.annotationsGroup = this.component.svg.append('g').attr('class', 'annotations');

    this.hilightBox = this.annotationsGroup
      .append('rect')
      .classed('annotations-hilight', true)
      .attr('opacity', 0)
      .attr('width', this.component.xAxisScale.bandwidth())
      .attr('height', this.component.height)
      .attr('transform', `translate(${0}, ${0})`);

    this.update();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.annotationsHilight && !changes.annotationsHilight.firstChange) {
      if (changes.annotationsHilight.currentValue) {
        this.updateHilight();
      } else {
        this.hilightBox.transition().duration(200).attr('opacity', 0);
      }
    }

    if (changes.annotations && !changes.annotations.firstChange) {
      this.update();
    }
  }

  private update() {
    const isAnotations = this.annotations;
    const isIncidents = this.annotations?.incidents?.length > 0;
    const isComments = this.annotations?.comments?.length > 0;

    if (isAnotations && isIncidents) {
      const bandwidth = this.component.xAxisScale.bandwidth();

      this.annotationsGroup
        .selectAll('g.incident')
        .data(this.annotations.incidents)
        .join(
          (enter) => {
            const g = enter.append('g').attr('class', 'incident');

            g.attr('transform', (d, i) => {
              const x = this.component.xAxisScale(d.key) + bandwidth / 2;
              const y = ANNOTATION_OFFSET;

              return `translate(${x}, ${y})`;
            }).attr('index', (d, i) => i);

            g.append('circle')
              .attr('r', 0)
              .attr('cx', 0)
              .attr('cy', 0)
              .transition()
              .duration(TRANSITION_DURATION)
              .ease(d3_easeQuadInOut)
              .attr('r', 15);

            g.append('text')
              .attr('x', 0)
              .attr('y', 0)
              .attr('dx', 1)
              .attr('dy', 9)
              .attr('text-anchor', 'middle')
              .text((d) => {
                return d.icon || '';
              })
              .attr('style', 'font-size: 0')
              .transition()
              .duration(TRANSITION_DURATION)
              .ease(d3_easeQuadInOut)
              .attr('style', 'font-size: 17px');

            return g;
          },
          (update) => {
            update
              .transition()
              .duration(TRANSITION_DURATION)
              .ease(d3_easeQuadInOut)
              .attr('transform', (d) => {
                const x = this.component.xAxisScale(d.key) + bandwidth / 2;
                const y = ANNOTATION_OFFSET;

                return `translate(${x}, ${y})`;
              });

            return update;
          },
          (exit) => {
            exit.select('circle').transition().duration(TRANSITION_DURATION).attr('r', 0);

            exit.select('text').transition().duration(TRANSITION_DURATION).attr('style', 'font-size: 0');

            return exit.transition().delay(TRANSITION_DELAY).remove();
          }
        )
        .on('mouseover', (event, data) => {
          d3_select(event.currentTarget).classed('hovered', true);
        })
        .on('mouseout', (event, data) => {
          d3_select(event.currentTarget).classed('hovered', false);
        })
        .on('click', (event, data) => {
          // console.log('incident clicked', this.index.get(event.currentTarget.node));
          this.annotationClicked.emit({ event, data, index: +d3_select(event.currentTarget).attr('index') });
        });
    }

    if (isAnotations && isComments) {
      const bandwidth = this.component.xAxisScale.bandwidth();

      this.annotationsGroup
        .selectAll('g.comment')
        .data(this.annotations.comments)
        .join(
          (enter) => {
            const g = enter.append('g').attr('class', 'comment');

            g.attr('transform', (d) => {
              const x = this.component.xAxisScale(d.key) + bandwidth / 2;
              let y = ANNOTATION_OFFSET;
              const isIncidents = this.annotations?.incidents?.some((incident) => incident.key === d.key);

              if (isIncidents) {
                y = ANNOTATION_COMMENT_OFFSET;
              }

              return `translate(${x}, ${y})`;
            }).attr('index', (d, i) => i);

            g.append('circle')
              .attr('r', 0)
              .attr('cx', 0)
              .attr('cy', 0)
              .transition()
              .duration(TRANSITION_DURATION)
              .ease(d3_easeQuadInOut)
              .attr('r', 15);

            g.append('text')
              .attr('x', 0)
              .attr('y', 3)
              .attr('dx', 0)
              .attr('dy', 5)
              .attr('text-anchor', 'middle')
              .text('')
              .attr('style', 'font-size: 0')
              .transition()
              .duration(TRANSITION_DURATION)
              .ease(d3_easeQuadInOut)
              .attr('style', 'font-size: 17px');

            return g;
          },
          (update) => {
            update
              .transition()
              .duration(TRANSITION_DURATION)
              .ease(d3_easeQuadInOut)
              .attr('transform', (d) => {
                const x = this.component.xAxisScale(d.key) + bandwidth / 2;
                let y = ANNOTATION_OFFSET;
                const isIncidents = this.annotations?.incidents?.some((incident) => incident.key === d.key);

                if (isIncidents) {
                  y = ANNOTATION_COMMENT_OFFSET;
                }

                return `translate(${x}, ${y})`;
              });

            return update;
          },
          (exit) => {
            exit.select('circle').transition().duration(TRANSITION_DURATION).attr('r', 0);

            exit.select('text').transition().duration(TRANSITION_DURATION).attr('style', 'font-size: 0');

            return exit.transition().delay(TRANSITION_DELAY).remove();
          }
        )
        .on('mouseover', (event) => {
          d3_select(event.currentTarget).classed('hovered', true);
        })
        .on('mouseout', (event) => {
          d3_select(event.currentTarget).classed('hovered', false);
        })
        .on('click', (event, data) => {
          this.annotationClicked.emit({ event, data, index: +d3_select(event.currentTarget).attr('index') });
        });
    }

    // hilight
    if (this.annotationsHilight) {
      this.updateHilight();
    }

    this.component.svg.selectAll('.bar').classed('pbds-annotation-add', true);
  }

  private updateHilight() {
    const opacity = this.hilightBox.attr('opacity');

    const duration = opacity === 0 ? 0 : 300;

    this.hilightBox
      .transition()
      .duration(duration)
      .ease(d3_easeQuadInOut)
      .attr('transform', () => {
        const x = this.component.xAxisScale(this.annotationsHilight);
        const y = 0;

        return `translate(${x}, ${y})`;
      })
      .transition()
      .duration(200)
      .attr('opacity', 1);
  }
}

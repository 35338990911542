import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from 'app/shared/layout/layout.component';
import { HomeComponent } from './home/home.component';

const config: ExtraOptions = {
  // enableTracing: !environment.production, // Turn this on to for console.logging of route events
  enableTracing: false,
  scrollPositionRestoration: 'disabled', // set to disabled due to conflict with ScrollSpy, may be fixed in later angular version, property added in @angular v6.1
};

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'home',
        component: HomeComponent,
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'web',
        component: LayoutComponent,
        loadChildren: () => import('./web/web.module').then((m) => m.WebModule),
      },
      {
        path: 'mobile',
        component: LayoutComponent,
        loadChildren: () => import('./mobile/mobile.module').then((m) => m.MobileModule),
      },
      {
        path: 'desktop',
        component: LayoutComponent,
        loadChildren: () => import('./desktop/desktop.module').then((m) => m.DesktopModule),
      },
      {
        path: 'hardware',
        component: LayoutComponent,
        loadChildren: () => import('./hardware/hardware.module').then((m) => m.HardwareModule),
      },
      {
        path: 'wearables',
        component: LayoutComponent,
        loadChildren: () => import('./wearables/wearables.module').then((m) => m.WearablesModule),
      },
      {
        path: 'conversational-ui',
        component: LayoutComponent,
        loadChildren: () => import('./conversational/conversational.module').then((m) => m.ConversationalModule),
      },
      {
        path: 'printed-materials',
        component: LayoutComponent,
        loadChildren: () => import('./printed/printed.module').then((m) => m.PrintedModule),
      },
      {
        path: 'illustrations',
        component: LayoutComponent,
        loadChildren: () => import('./illustrations/illustrations.module').then((m) => m.IllustrationsModule),
      },
      {
        path: 'design',
        component: LayoutComponent,
        loadChildren: () => import('./design/design.module').then((m) => m.DesignModule),
      },
      {
        path: 'brand',
        component: LayoutComponent,
        loadChildren: () => import('./brand/brand.module').then((m) => m.BrandModule),
      },
      {
        path: 'content',
        component: LayoutComponent,
        loadChildren: () => import('./content/content.module').then((m) => m.ContentModule),
      },
      {
        path: 'videos',
        component: LayoutComponent,
        loadChildren: () => import('./videos/videos.module').then((m) => m.VideosModule),
      },
      {
        path: 'pages',
        component: LayoutComponent,
        loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
      },
      {
        path: 'downloads',
        component: LayoutComponent,
        loadChildren: () => import('./downloads/downloads.module').then((m) => m.DownloadsModule),
      },
      {
        path: 'globalization',
        component: LayoutComponent,
        loadChildren: () => import('./globalization/globalization.module').then((m) => m.GlobalizationModule),
      },
      {
        path: 'accessibility',
        component: LayoutComponent,
        loadChildren: () => import('./accessibility/accessibility.module').then((m) => m.AccessibilityModule),
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      // {
      //   path: '**',
      //   redirectTo: 'home'
      // }
    ],
  },
  // path is outside so that it loads full screen

  {
    path: 'demo',
    loadChildren: () => import('./demo/demo.module').then((m) => m.DemoModule),
  },
  {
    path: 'sampleapp',
    loadChildren: () => import('./sample-app/sample-app.module').then((m) => m.SampleAppModule),
  },
];

@NgModule({
  providers: [],
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import {
  Component,
  OnInit,
  Input,
  ElementRef,
  HostBinding,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { select as d3_select } from 'd3-selection';
import { line as d3_line, area as d3_area } from 'd3-shape';
import { scaleLinear as d3_scaleLinear } from 'd3-scale';
import { min as d3_min, max as d3_max } from 'd3-array';

import { PbdsDatavizSparkline } from './dataviz.interfaces';

@Component({
  selector: 'pbds-dataviz-sparkline',
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PbdsDatavizSparklineComponent implements OnInit, OnChanges {
  @HostBinding('class.pbds-chart')
  chartClass = true;

  @HostBinding('class.pbds-chart-sparkline')
  sparklineClass = true;

  @Input()
  data: PbdsDatavizSparkline[] = [];

  @Input()
  width = 160;

  @Input()
  height = 40;

  @Input()
  type: 'line' | 'line-high' | 'area' | 'area-high' | 'bar' = 'line';

  @Input()
  color = '#E23DA8';

  @Input()
  colorNegative: string | null = null; // undocumented, may add if needed

  @Input()
  strokeWidth = 2; // undocumented, width is automatically set by the type

  @Input()
  yAxisMinBuffer = 0;

  @Input()
  yAxisMaxBuffer = 0;

  private chart;
  private svg;
  private margin;

  constructor(private _element: ElementRef) {}

  ngOnInit() {
    this.margin = { top: 1, right: 0, bottom: 1, left: 0 };

    if (this.type === 'bar') {
      this.margin = { top: 0, right: 0, bottom: 0, left: 0 };
    }

    if (this.type === 'line-high' || this.type === 'area-high') {
      this.strokeWidth = 1;
    }

    if (this.colorNegative === null) {
      this.colorNegative = this.color;
    }

    this.chart = d3_select(this._element.nativeElement).attr('aria-hidden', 'true');

    this.svg = this.chart
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('class', 'img-fluid')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', `-${this.margin.left} -${this.margin.top} ${this.width} ${this.height}`);

    if (this.type === 'line' || this.type === 'line-high' || this.type === 'area' || this.type === 'area-high') {
      this.svg
        .append('path')
        .attr('class', 'sparkline')
        .attr('fill', 'none')
        .attr('stroke-width', this.strokeWidth)
        .attr('stroke', this.color);
    }

    if (this.type === 'area' || this.type === 'area-high') {
      this.svg.append('path').attr('class', 'sparkarea').attr('fill', this.color).attr('fill-opacity', 0.3);
    }

    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      this.updateChart();
    }
  }

  updateChart() {
    const data: any = this.data;

    const x = d3_scaleLinear()
      .domain([0, this.data.length])
      .range([0, this.width - this.margin.left - this.margin.right]);

    const y = d3_scaleLinear()
      .domain([+d3_min(data) - this.yAxisMinBuffer, +d3_max(data) + this.yAxisMaxBuffer])
      .range([this.height - this.margin.top - this.margin.bottom, 0]);

    const line = d3_line()
      .x((d, i) => x(i))
      .y((d: any) => y(d));

    const area = d3_area()
      .x((d, i) => x(i))
      .y0(this.height)
      .y1((d: any) => y(d));

    if (this.type === 'line' || this.type === 'line-high' || this.type === 'area' || this.type === 'area-high') {
      this.svg
        .selectAll('.sparkline')
        .transition()
        .duration(1000)
        .attr('d', () => line(data));
    }

    if (this.type === 'area' || this.type === 'area-high') {
      this.svg
        .selectAll('.sparkarea')
        .transition()
        .duration(1000)
        .attr('d', () => area(data));
    }

    if (this.type === 'bar') {
      const barWidth = (this.width - this.data.length) / this.data.length;

      // handles negative values, see example https://www.essycode.com/posts/create-sparkline-charts-d3/
      this.svg
        .selectAll('.sparkbar')
        .data(this.data)
        .join(
          (enter) =>
            enter
              .append('rect')
              .attr('class', 'sparkbar')
              .attr('x', (d, i) => x(i))
              .attr('y', this.height)
              .attr('width', barWidth)
              .attr('fill', (d) => (d > 0 ? this.color : this.colorNegative)) // still uses undocumented negative color values
              .attr('height', 0)
              .call((enter) => {
                enter
                  .transition()
                  .duration(1000)
                  .attr('y', (d) => (d > 0 ? y(d) : y(0)))
                  .attr('height', (d) => Math.abs(y(d) - y(0)));

                return enter;
              }),
          (update) =>
            update
              .transition()
              .duration(1000)
              .selection()
              .attr('x', (d, i) => x(i))
              .attr('y', (d) => (d > 0 ? y(d) : y(0)))
              .attr('width', barWidth)
              .attr('height', (d) => Math.abs(y(d) - y(0)))
              .attr('fill', (d) => (d > 0 ? this.color : this.colorNegative)),
          (exit) => exit.remove()
        );
    }
  }
}

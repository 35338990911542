import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLinkActive } from '@angular/router';

import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  providers: [RouterLinkActive],
})
export class SidenavComponent implements OnInit, AfterViewInit {
  @ViewChild('webAccordion') webAccordion: NgbAccordionDirective;

  activePanel;

  constructor(
    private router: Router,
    private elementref: ElementRef,
  ) {}

  ngOnInit() {
    this.activePanel = `${this.router.url.split('/')[1].replace('%20', ' ')}`;
  }

  ngAfterViewInit(): void {
    this.webAccordion.toggle(this.activePanel);

    setTimeout(() => {
      this.scrolltoActiveLink();
    }, 2000);
  }

  scrolltoActiveLink = () => {
    setTimeout(() => {
      const activeEl = this.elementref.nativeElement.querySelectorAll('a.list-group-item.active');

      activeEl[0]?.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OktaAuthenticateService {
  constructor(private _http: HttpClient) {}

  login() {
    return this._http.get('/api/login');
  }
}

<section>
  <h2>{{ sectionTitle }}</h2>
  <p-carousel
    [value]="slideData"
    [numVisible]="6"
    [numScroll]="1"
    [circular]="false"
    [showIndicators]="false"
    styleClass="homepage-carousel"
  >
    <ng-template pTemplate="previousicon">
      <i class="pbi-icon-mini pbi-minimal-left"></i>
    </ng-template>
    <ng-template pTemplate="item" let-slide>
      <div class="slide" (click)="goRoute(slide.route)">
        <div class="slide-thumbnail" [ngClass]="slide.class">
          <img src="/assets/images/homepage/slides/{{ slide.image }}.webp" class="img-fluid" alt="{{ slide.title }}" />
          <div class="slide-caption">
            <div>{{ slide.description }}</div>
          </div>
        </div>
        <div class="slide-title">{{ slide.title }}</div>
      </div>
    </ng-template>
    <ng-template pTemplate="nexticon">
      <i class="pbi-icon-mini pbi-minimal-right"></i>
    </ng-template>
  </p-carousel>
</section>

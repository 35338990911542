<svg
  [ngClass]="{ static: static }"
  xmlns="http://www.w3.org/2000/svg"
  xml:space="preserve"
  style="enable-background: new 0 0 155.4 149.1"
  viewBox="0 0 155.4 149.1"
>
  <defs>
    <linearGradient id="a" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop stop-color="var(--primary)" offset="0%" />
      <stop stop-color="var(--secondary)" offset="100%" />
    </linearGradient>
  </defs>

  <style>
    #outer_circle {
      transform-origin: center;
      animation: spinner_StKS 6s 1 linear;
      animation-direction: reverse;
    }
    #inner_circle {
      transform-origin: center;
      animation: spinner_StKS 6s 1 linear;
    }
    .static #inner_circle,
    .static #outer_circle {
      animation: none;
    }

    @keyframes spinner_StKS {
      100% {
        transform: rotate(360deg);
      }
    }
  </style>
  <rect width="160" height="160" fill="url(#a)" mask="url(#icon)"></rect>
  <mask id="icon">
    <g id="clock" fill="#fff">
      <path
        class="st0"
        d="M77.5 45.4c.8 0 1.4.6 1.5 1.4v3.7c0 .8-.7 1.5-1.5 1.5s-1.4-.6-1.5-1.4v-3.7c0-.8.7-1.5 1.5-1.5zM105.9 74.6c.8 0 1.5.7 1.5 1.5s-.6 1.4-1.4 1.5h-3.7c-.8 0-1.5-.7-1.5-1.5s.6-1.4 1.4-1.5h3.7zM77.5 99c.8 0 1.4.6 1.5 1.4v3.7c0 .8-.7 1.5-1.5 1.5s-1.4-.6-1.5-1.4v-3.7c0-.8.7-1.5 1.5-1.5zM52.6 74.6c.8 0 1.5.7 1.5 1.5s-.6 1.4-1.4 1.5H49c-.8 0-1.5-.7-1.5-1.5s.6-1.4 1.4-1.5h3.7z"
      />
      <path
        class="st0"
        d="M77.5 112.8c-20.4 0-37-16.6-37-37s16.6-37.1 37-37.1 37.1 16.6 37.1 37.1-16.7 37-37.1 37zm0-71.1c-18.8 0-34 15.3-34 34.1s15.3 34 34 34c18.8 0 34.1-15.3 34.1-34S96.3 41.7 77.5 41.7z"
      />
      <path
        d="M91.5 62c-.5-.6-1.3-.5-1.8 0l-8.3 9c-1.1-.8-2.4-1.4-3.9-1.4-1.1 0-2.1.3-2.9.7 0-.1-.1-.1-.1-.2L59.3 56l-.1-.1c-.6-.4-1.4-.4-1.9.1-.6.5-.6 1.4 0 1.9l15.1 14.3.1.1c-.8 1-1.2 2.3-1.2 3.7 0 3.5 2.8 6.3 6.2 6.3 3.4 0 6.2-2.8 6.2-6.3 0-1-.2-1.9-.7-2.8l8.4-9.2.1-.1c.5-.6.4-1.4 0-1.9zm-14 17.2c-1.8 0-3.2-1.5-3.2-3.3s1.5-3.3 3.2-3.3 3.2 1.5 3.2 3.3-1.4 3.3-3.2 3.3z"
        style="fill-rule: evenodd; clip-rule: evenodd"
      />
    </g>
    <g id="outer_circle" fill="#fff">
      <path
        d="M55.3 145c-.2 0-.3 0-.5-.1-17.2-5.7-31.7-17.5-40.6-33.4-.4-.7-.2-1.6.6-2 .7-.4 1.6-.2 2 .6 8.6 15.2 22.4 26.6 38.9 32 .8.3 1.2 1.1 1 1.9-.2.6-.8 1-1.4 1zM99.6 145c-.6 0-1.2-.4-1.4-1-.3-.8.2-1.6 1-1.9 28.7-9.4 47.9-36 47.9-66.2 0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5c0 31.5-20.1 59.2-50 69-.2.1-.3.1-.5.1zM6.5 81.8c-.8 0-1.4-.6-1.5-1.4-.1-1.5-.1-3-.1-4.5 0-40 32.6-72.6 72.6-72.6 12.8 0 25.4 3.4 36.4 9.8.7.4 1 1.3.5 2.1-.4.7-1.3 1-2.1.5-10.6-6.1-22.6-9.4-34.9-9.4-38.3 0-69.5 31.2-69.5 69.6 0 1.4 0 2.9.1 4.3.1.9-.5 1.6-1.5 1.6.1 0 .1 0 0 0z"
      />
    </g>
    <g id="inner_circle" fill="#fff">
      <path
        d="M131.8 54.4c-.6 0-1.1-.3-1.4-.9-9-21.3-29.8-35-52.9-35-10.6 0-21 2.9-30 8.4-.7.4-1.6.2-2.1-.5-.4-.7-.2-1.6.5-2.1 9.5-5.8 20.4-8.9 31.6-8.9 24.3 0 46.2 14.5 55.7 36.9.3.8 0 1.6-.8 2-.2 0-.4.1-.6.1zM77.5 136.4c-33.4 0-60.5-27.1-60.5-60.5 0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5c0 31.7 25.8 57.5 57.5 57.5 25.7 0 48.5-17.3 55.4-42.1.2-.8 1-1.3 1.8-1 .8.2 1.3 1 1 1.8-7.1 26.1-31.1 44.3-58.2 44.3z"
      />
    </g>
  </mask>
</svg>

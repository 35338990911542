import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlgoliaSearchService {
  constructor(private http: HttpClient) {}

  category: string;

  search(term: string, cat: string) {
    let search: HttpParams = new HttpParams();
    search = search.append('term', term);
    return this.http.get<Observable<any>>('/api/v1/search', { params: search }).pipe(
      map((results: any) => {
        if (cat === 'all') {
          return results;
        } else {
          return (results = results.filter((result) => {
            return result.post_type === cat;
          }));
        }
      })
    );
  }
}

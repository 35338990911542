<div class="input-group pbds-daterange-popover" *ngIf="displayInput; else daterangeButton">
  <input
    class="form-control"
    *ngIf="displayInput"
    [value]="dateRange"
    aria-label="Date"
    aria-readonly="true"
    readonly="readonly"
    tabindex="-1"
  />

  <ng-container *ngTemplateOutlet="daterangeButton"></ng-container>
</div>

<ng-template #daterangeButton>
  <button
    class="btn btn-secondary"
    id="daterange-button"
    #datepickerPopup="ngbPopover"
    [ngbPopover]="daterangeContent"
    [container]="container"
    [placement]="placement"
    [attr.aria-label]="ariaLabel"
    type="button"
    popoverClass="daterange-popover"
    autoClose="outside"
  >
    <i class="pbi-icon-mini pbi-calendar" aria-hidden="true"></i>
  </button>
</ng-template>

<ng-template #daterangeContent>
  <div class="d-block d-md-flex" cdkTrapFocus cdkTrapFocusAutoCapture>
    <div [hidden]="!isDatepickerVisible">
      <ngb-datepicker
        #datepicker="ngbDatepicker"
        [displayMonths]="displayMonths"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [firstDayOfWeek]="firstDayOfWeek"
        [showWeekdays]="true"
        [startDate]="startDate"
        [dayTemplate]="t"
        (dateSelect)="onDateSelection($event)"
        navigation="select"
        outsideDays="hidden"
      >
      </ngb-datepicker>
      <!--  -->

      <ng-template #t let-date let-focused="focused">
        <span
          class="custom-day"
          [class.focused]="focused"
          [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null"
        >
          {{ date.day }}
        </span>
      </ng-template>
    </div>

    <div class="d-flex flex-column justify-content-lg-between mt-md-0" [ngClass]="{ 'ms-md-4': isDatepickerVisible }">
      <!-- filters -->
      <div class="mb-3" *ngIf="filters && filters.length > 0" ngbDropdown>
        <button class="btn btn-sm btn-secondary" id="dateFilter" ngbDropdownToggle>
          {{ selectedFilter.label }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dateFilter">
          <button
            class="dropdown-item"
            *ngFor="let filter of filters; let index = index"
            (click)="onFilterChange($event, filter, index)"
            type="button"
          >
            {{ filter.label }}
          </button>
        </div>
      </div>

      <!-- presets radio buttons-->
      <div class="flex-grow-1" *ngIf="presets && filters">
        <mat-radio-group
          class="stacked-radio-group"
          [(ngModel)]="presetSelected"
          (change)="presetSelect($event)"
          aria-label="Select an option"
          style="gap: 4px"
          name="presets"
        >
          <mat-radio-button *ngFor="let preset of presets" [value]="preset.value">{{ preset.label }}</mat-radio-button>

          <mat-radio-button *ngIf="showCustomPreset" [value]="'CUSTOM'" (change)="showDatepicker()">{{
            customRangeText
          }}</mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- presets buttons-->
      <div class="d-flex flex-column" *ngIf="presets && !filters" style="gap: 4px">
        <button
          class="btn btn-secondary btn-sm text-nowrap"
          *ngFor="let preset of presets"
          (click)="presetClick(preset)"
          type="button"
        >
          {{ preset.label }}
        </button>

        <button
          class="btn btn-secondary btn-sm text-nowrap"
          *ngIf="showCustomPreset"
          (click)="showDatepicker()"
          type="button"
        >
          {{ customRangeText }}
        </button>
      </div>

      <!-- buttons -->
      <div class="d-flex justify-content-between mt-3" *ngIf="filters || isDatepickerVisible">
        <button class="btn btn-primary btn-sm me-1" (click)="onApply()" type="button">
          {{ applyText }}
        </button>
        <button class="btn btn-secondary btn-sm ms-1" (click)="onCancel()" type="button">
          {{ cancelText }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { Location, ViewportScroller } from '@angular/common';

import {
  bisect as d3_bisect,
  bisectRight as d3_bisectRight,
  extent as d3_extent,
  max as d3_max,
  min as d3_min,
  range as d3_range,
} from 'd3-array';
import { axisBottom as d3_axisBottom, axisLeft as d3_axisLeft } from 'd3-axis';
import { easeQuadInOut as d3_easeQuadInOut } from 'd3-ease';
import { format as d3_format } from 'd3-format';
import {
  scaleLinear as d3_scaleLinear,
  scaleOrdinal as d3_scaleOrdinal,
  scalePoint as d3_scalePoint,
  scaleTime as d3_scaleTime,
} from 'd3-scale';
import { pointer as d3_pointer, select as d3_select } from 'd3-selection';
import { area as d3_area, curveCatmullRom as d3_curveCatmullRom, line as d3_line } from 'd3-shape';
import { isoFormat as d3_isoFormat, isoParse as d3_isoParse, timeFormat as d3_timeFormat } from 'd3-time-format';

import { PbdsDatavizLine } from './dataviz.interfaces';
import { PbdsDatavizService } from './dataviz.service';

// assign an ID for each component instance
let nextId = 0;

@Component({
  selector: 'pbds-dataviz-line',
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PbdsDatavizLineComponent implements OnInit, OnDestroy, OnChanges {
  @HostBinding('class.pbds-chart')
  chartClass = true;

  @HostBinding('class.pbds-chart-line')
  lineClass = true;

  @Input()
  data: PbdsDatavizLine;

  @Input()
  width = 306;

  @Input()
  height = 400;

  @Input()
  type: 'medium' | 'high' | 'debug' = 'medium'; // debug to show all chart options

  @Input()
  area = false;

  @Input()
  xAxisType: 'date' | 'number' | 'string' = 'date';

  @Input()
  xAxisFormatString = '';

  @Input()
  xAxisTicks = 6;

  @Input()
  xAxisTitle: string | null = null;

  @Input()
  yAxisFormatString = '';

  @Input()
  yAxisTicks = 5;

  @Input()
  yAxisMinBuffer = 0.01;

  @Input()
  yAxisMaxBuffer = 0.01;

  @Input()
  hideXGrid = true;

  @Input()
  hideYGrid = true;

  @Input()
  hideLegend = false;

  @Input()
  legendWidth = 105 + 28; // hardcoded legend width + left margin, do not document until feedback

  @Input()
  legendPosition: 'right' | 'bottom' = 'right';

  @Input()
  legendLabelFormatType: 'number' | 'time' = null;

  @Input()
  legendLabelFormatString = '';

  @Input()
  tooltipHeadingFormatString = '';

  @Input()
  tooltipHeadingSuffix = '';

  @Input()
  tooltipLabelFormatType: 'number' | 'time' = null;

  @Input()
  tooltipLabelFormatString = '';

  @Input()
  tooltipValueFormatType: 'number' | 'time' = null;

  @Input()
  tooltipValueFormatString = '';

  @Input()
  marginTop = 10; // hardcoded on purpose, do not document until feedback

  @Input()
  marginRight = 20; // hardcoded on purpose, do not document until feedback

  @Input()
  marginBottom = 30; // hardcoded on purpose, do not document until feedback

  @Input()
  marginLeft = 55; // hardcoded on purpose, do not document until feedback

  @Input()
  theme;

  @Input()
  customColor: boolean = false;

  @Input()
  colorsArray = [];

  @Input() lineCurved = true;

  @Output()
  hovered: EventEmitter<object> = new EventEmitter<object>();

  @Output()
  clicked: EventEmitter<object> = new EventEmitter<object>();

  @Output()
  tooltipHovered: EventEmitter<object> = new EventEmitter<object>();

  @Output()
  tooltipClicked: EventEmitter<object> = new EventEmitter<object>();

  private chart;
  private svg;
  private mouserect;
  private tooltipLine;
  private margin;
  private clipPathId;
  private d3line;
  private d3area;
  private lineWidth;
  private linePoints;
  private colorRange;
  private xAxisScale;
  private xAxisCall;
  private xAxis;
  private xAxisFormat;
  private yAxisScale;
  private yAxisCall;
  private yAxis;
  private yAxisFormat;
  private xGrid;
  private xGridCall;
  private yGrid;
  private yGridCall;
  private xAxisTickSize: number;
  private xAxisTickSizeOuter: number;
  private xAxisTitleMargin: number;
  private yAxisTickSize: number;
  private yAxisTickSizeOuter: number;
  private hideXAxis: boolean;
  private hideYAxis: boolean;
  private hideXAxisDomain: boolean;
  private hideYAxisDomain: boolean;
  private hideXAxisZero: boolean;
  private hideYAxisZero: boolean;
  private hideXAxisTicks: boolean;
  private hideYAxisTicks: boolean;
  private legendLabelFormat;
  private tooltip;
  private hideTooltip: boolean;
  private tooltipHeadingFormat;
  private tooltipValueFormat;
  private tooltipLabelFormat;
  private mousedata;

  constructor(
    private _dataviz: PbdsDatavizService,
    private _element: ElementRef,
    private _scroll: ViewportScroller,
    private _location: Location,
  ) {}

  ngOnInit() {
    this.margin = {
      top: +this.marginTop,
      right: +this.marginRight,
      bottom: +this.marginBottom,
      left: +this.marginLeft,
    };

    this.clipPathId = nextId;

    // create formatters
    this.xAxisFormat =
      this.xAxisType === 'date' ? d3_timeFormat(this.xAxisFormatString) : d3_format(this.xAxisFormatString);
    this.yAxisFormat = d3_format(this.yAxisFormatString);
    this.legendLabelFormat = this._dataviz.d3Format(this.legendLabelFormatType, this.legendLabelFormatString);
    this.tooltipHeadingFormat =
      this.xAxisType === 'date'
        ? d3_timeFormat(this.tooltipHeadingFormatString)
        : d3_format(this.tooltipHeadingFormatString);
    this.tooltipLabelFormat = this._dataviz.d3Format(this.tooltipLabelFormatType, this.tooltipLabelFormatString);
    this.tooltipValueFormat = this._dataviz.d3Format(this.tooltipValueFormatType, this.tooltipValueFormatString);

    // defaults for all chart types
    this.lineWidth = 3;
    this.linePoints = true;
    this.hideXAxis = false;
    this.hideYAxis = false;
    this.hideXAxisZero = false;
    this.hideYAxisZero = false;
    this.hideXAxisDomain = false;
    this.hideYAxisDomain = false;
    this.hideTooltip = false;
    this.hideXAxisTicks = false;
    this.hideYAxisTicks = false;
    this.xAxisTickSize = 8;
    this.xAxisTickSizeOuter = 0;
    this.yAxisTickSize = 8;
    this.yAxisTickSizeOuter = 0;

    if (this.type !== 'debug') {
      // set type defaults
      switch (this.type) {
        case 'medium':
          this.hideXAxisTicks = true;
          this.hideYAxisTicks = true;
          break;

        case 'high':
          this.lineWidth = 2;
          this.lineCurved = false;
          this.linePoints = false;
          this.hideXAxisTicks = true;
          this.hideYAxisTicks = true;
          this.hideXGrid = false;
          this.hideYGrid = false;
          break;
      }
    }

    this.xAxisTitleMargin = this.xAxisTitle ? 30 : 0;

    // adjust margin if xAxis hidden
    if (this.hideXAxis) this.margin.bottom = 10; // need small margin for yAxis with 0 tick label

    if (!this.hideLegend && this.legendPosition === 'right') {
      this.width = +this.width - +this.legendWidth;
    }

    // define line
    this.d3line = d3_line()
      .x((d: any, i) => {
        if (this.xAxisType === 'date') {
          return this.xAxisScale(d3_isoParse(this.data.labels[i]));
        } else if (this.xAxisType === 'number') {
          return this.xAxisScale(this.data.labels[i]);
        } else {
          return this.xAxisScale(this.data.labels[i]);
        }
      })
      .y((d: any) => this.yAxisScale(d))
      .defined((d, i) => {
        // console.log(d);
        return d !== null; // only draw line if data is not null
      });

    // define line curve
    if (this.lineCurved) {
      this.d3line.curve(d3_curveCatmullRom.alpha(0.5));
    }

    // define area
    if (this.area) {
      this.d3area = d3_area()
        .x((d: any, i) => {
          if (this.xAxisType === 'date') {
            return this.xAxisScale(d3_isoParse(this.data.labels[i]));
          } else if (this.xAxisType === 'number') {
            return this.xAxisScale(this.data.labels[i]);
          } else {
            return this.xAxisScale(this.data.labels[i]);
          }
        })
        .y0(this.height)
        .y1((d: any, i) => this.yAxisScale(d))
        .defined((d, i) => {
          // console.log(d);
          return d !== null; // only draw line if data is not null
        });

      if (this.lineCurved) {
        this.d3area.curve(d3_curveCatmullRom.alpha(0.5));
      }
    }

    // create the chart
    this.chart = d3_select(this._element.nativeElement).attr('aria-hidden', 'true');

    // create chart svg
    this.svg = this.chart
      .append('svg')
      .attr('width', +this.width + this.margin.right)
      .attr('height', +this.height + this.margin.top + this.margin.bottom + this.xAxisTitleMargin)
      .attr('class', 'img-fluid')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr(
        'viewBox',
        `-${this.margin.left} -${this.margin.top} ${+this.width + this.margin.right} ${
          +this.height + this.margin.top + this.margin.bottom + this.xAxisTitleMargin
        }`,
      );

    // add rectangle to capture mouse
    this.mouserect = this.svg
      .append('rect')
      .attr('width', this.width - this.margin.left - this.margin.right)
      .attr('height', this.height)
      .attr('class', 'mouserect')
      .on('mousemove', (event, data) => this.mouserectMouseMove(event, data))
      .on('mouseout', (event, data) => this.mouserectMouseOut(event, data))
      .on('click', (event, data) => this.mouserectMouseClick(event));

    this.tooltipLine = this.svg.append('line').attr('y1', 0).attr('y2', this.height).attr('class', 'tooltip-line');

    // define color range

    const colors = this.customColor ? this.colorsArray : this._dataviz.getColors(false, this.theme);
    this.colorRange = d3_scaleOrdinal().range(colors);

    // add glow def
    this._dataviz.createGlowFilter(this.svg);

    // X AXIS
    if (this.xAxisType === 'date') {
      this.xAxisScale = d3_scaleTime()
        .domain(
          d3_extent(this.data.labels, (d: any) => {
            return d3_isoParse(d);
          }),
        )
        .range([0, this.width - this.margin.left - this.margin.right]);
    } else if (this.xAxisType === 'number') {
      this.xAxisScale = d3_scaleLinear()
        .domain(
          d3_extent(this.data.labels, (d: any) => {
            return d;
          }),
        )
        .range([0, this.width - this.margin.left - this.margin.right]);
    } else {
      this.xAxisScale = d3_scalePoint()
        .domain(this.data.labels)
        .range([0, this.width - this.margin.left - this.margin.right]);
    }

    if (this.xAxisType === 'string') {
      this.xAxisCall = d3_axisBottom(this.xAxisScale)
        // .ticks(+this.xAxisTicks)
        .tickSize(this.xAxisTickSize)
        .tickSizeOuter(this.xAxisTickSizeOuter)
        .tickFormat(this.xAxisFormatter)
        .tickValues(
          this.xAxisScale.domain().filter((d, i) => {
            // see https://github.com/d3/d3-scale/issues/182
            // d3 cannot determine number of strings to show on xaxis with scalePoint()
            return i % this.xAxisTicks === 0;
          }),
        );
    } else {
      this.xAxisCall = d3_axisBottom(this.xAxisScale)
        .ticks(+this.xAxisTicks)
        .tickSize(this.xAxisTickSize)
        .tickSizeOuter(this.xAxisTickSizeOuter)
        .tickFormat(this.xAxisFormatter);
    }

    this.xAxis = this.svg
      .append('g')
      .attr('class', 'axis axis-x')
      .attr('transform', `translate(0, ${this.height})`) //${-this.margin.right / 2}
      .classed('axis-hidden', this.hideXAxis)
      .classed('axis-zero-hidden', this.hideXAxisZero)
      .classed('axis-domain-hidden', this.hideXAxisDomain)
      .classed('axis-ticks-hidden', this.hideXAxisTicks)
      .call(this.xAxisCall);

    // X GRIDLINES
    if (!this.hideXGrid) {
      if (this.xAxisType === 'string') {
        this.xGridCall = d3_axisBottom(this.xAxisScale)
          .tickSize(-this.height)
          .tickValues(
            this.xAxisScale.domain().filter((d, i) => {
              // see https://github.com/d3/d3-scale/issues/182
              // d3 cannot determine number of strings to show on xaxis with scalePoint()
              return i % this.xAxisTicks === 0;
            }),
          );
      } else {
        this.xGridCall = d3_axisBottom(this.xAxisScale).tickSize(-this.height);
      }

      this.xGrid = this.svg
        .append('g')
        .attr('class', 'grid grid-x')
        .classed('grid-zero-hidden', this.hideXAxisZero)
        .attr('transform', `translate(0, ${this.height})`) //${-this.margin.right / 2}
        .call(this.xGridCall);
    }

    // X AXIS TITLE
    if (this.xAxisTitle) {
      this.svg
        .append('text')
        .attr('class', 'axis-title')
        .attr('text-anchor', 'middle')
        .attr(
          'transform',
          `translate(${this.svg.attr('width') / 2 - this.margin.left / 2 - this.margin.right / 2}, ${
            this.height + this.margin.top + (this.hideXAxis ? 20 : 40)
          })`,
        )
        // .attr('x', this.width / 2 - this.margin.left - this.margin.right)
        // .attr('y', this.height + this.margin.top + (this.hideXAxis ? 25 : 50))
        .text(this.xAxisTitle);
    }

    // Y AXIS
    this.yAxisScale = d3_scaleLinear()
      .domain([
        d3_min(this.data.series, (d: any, i) => {
          const minVal = +d3_min(d.values);
          return minVal - minVal * +this.yAxisMinBuffer;
        }),
        d3_max(this.data.series, (d: any, i) => {
          const maxVal = +d3_max(d.values);
          return maxVal + maxVal * this.yAxisMaxBuffer;
        }),
      ])
      .nice()
      .range([this.height, 0]);

    this.yAxisCall = d3_axisLeft(this.yAxisScale)
      .ticks(this.yAxisTicks)
      .tickSize(this.yAxisTickSize)
      .tickSizeOuter(this.yAxisTickSizeOuter)
      .tickFormat(this.yAxisFormatter);

    this.yAxis = this.svg
      .append('g')
      .attr('class', 'axis axis-y')
      .classed('axis-hidden', this.hideYAxis)
      .classed('axis-zero-hidden', this.hideYAxisZero)
      .classed('axis-domain-hidden', this.hideYAxisDomain)
      .classed('axis-ticks-hidden', this.hideYAxisTicks)
      .call(this.yAxisCall);

    // Y GRIDLINES
    if (!this.hideYGrid) {
      this.yGridCall = d3_axisLeft(this.yAxisScale)
        .ticks(this.yAxisTicks)
        .tickSize(-this.width + this.margin.left + this.margin.right);

      this.yGrid = this.svg
        .append('g')
        .attr('class', 'grid grid-y')
        .classed('grid-zero-hidden', this.hideYAxisZero)
        .attr('transform', `translate(0, 0)`)
        .call(this.yGridCall);
    }

    // TOOLTIP
    if (!this.hideTooltip) {
      this.tooltip = d3_select('body')
        .append('div')
        .attr('class', 'pbds-tooltip west')
        .style('opacity', 0)
        .attr('aria-hidden', 'true'); // hide tooltip for accessibility

      // tooltip header
      this.tooltip.append('div').attr('class', 'tooltip-header');

      // tooltip table
      const tooltipTable = this.tooltip.append('table').attr('class', 'tooltip-table text-start w-100');

      const tooltipTableTbody = tooltipTable.append('tbody');

      tooltipTableTbody
        .selectAll('tr')
        .data(this.data)
        .join((enter) => enter.append('tr'));
    }

    // add legend classes
    if (!this.hideLegend) {
      this.chart.classed('pbds-chart-legend-bottom', this.legendPosition === 'bottom' ? true : false);
      this.chart.append('ul').attr('class', `legend legend-${this.legendPosition}`);
    }

    // add clip path for line animation
    this.svg
      .append('clipPath')
      .attr('id', `clip-path-${this.clipPathId}`)
      .append('rect')
      .attr('width', +this.width - +this.margin.left - +this.margin.right)
      .attr('height', +this.height);

    // add clip path for points animation
    this.svg
      .append('clipPath')
      .attr('id', `clip-path-points-${this.clipPathId}`)
      .append('rect')
      .attr('width', +this.width + +this.margin.left - +this.margin.right)
      .attr('height', +this.height)
      .attr('transform', `translate(-${this.margin.left}, 0)`);

    this.updateChart();

    nextId++;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      this.updateChart();
    }
  }

  ngOnDestroy() {
    if (this.tooltip) this.tooltip.remove();
  }

  updateChart = () => {
    this.mouserect.data(this.data);

    // update the xScale
    if (this.xAxisType === 'date') {
      this.xAxisScale
        .domain(
          d3_extent(this.data.labels, (d: any) => {
            return d3_isoParse(d);
          }),
        )
        .range([0, this.width - this.margin.left - this.margin.right]);
    } else if (this.xAxisType === 'number') {
      this.xAxisScale.domain(
        d3_extent(this.data.labels, (d: any) => {
          return d;
        }),
      );
    } else {
      this.xAxisScale.domain(this.data.labels);
    }

    // update the yScale
    this.yAxisScale
      .domain([
        d3_min(this.data.series, (d: any, i) => {
          const minVal = +d3_min(d.values);
          return minVal - minVal * +this.yAxisMinBuffer;
        }),
        d3_max(this.data.series, (d: any, i) => {
          const maxVal = +d3_max(d.values);
          return maxVal + maxVal * this.yAxisMaxBuffer;
        }),
      ])
      .nice();

    this.xAxis.transition().duration(1000).call(this.xAxisCall);

    this.yAxis.transition().duration(1000).call(this.yAxisCall);

    // update the grids
    if (!this.hideXGrid) {
      this.xGrid.transition().duration(1000).call(this.xGridCall);
    }

    if (!this.hideYGrid) {
      this.yGrid.transition().duration(1000).call(this.yGridCall);
    }

    // lines
    this.svg
      .selectAll('path.line')
      .attr('filter', () =>
        this.type !== 'high' ? `url(${this._location.prepareExternalUrl(this._location.path())}#glow)` : null,
      )
      .data(this.data.series)
      .join(
        (enter) => {
          enter
            .append('path')
            .attr(
              'clip-path',
              `url(${this._location.prepareExternalUrl(this._location.path())}#clip-path-${this.clipPathId})`,
            )
            .attr('class', 'line')
            .style('stroke', (d) => this.colorRange(d.label))
            .style('stroke-width', this.lineWidth)
            .attr('d', (data) => {
              const array = new Array(data.values.length).fill(0);
              return this.d3line(array);
            })
            .call((enter) =>
              enter
                .transition()
                .duration(1000)
                .ease(d3_easeQuadInOut)
                .attr('d', (data) => this.d3line(data.values)),
            );
        },
        (update) =>
          update.call((update) =>
            update
              .transition()
              .duration(1000)
              .ease(d3_easeQuadInOut)
              .attr('d', (d) => this.d3line(d.values)),
          ),
        (exit) => exit.remove(),
      );

    // area
    if (this.area) {
      this.svg
        .selectAll('path.area')
        .data(this.data.series)
        .join(
          (enter) =>
            enter
              .append('path')
              .attr(
                'clip-path',
                `url(${this._location.prepareExternalUrl(this._location.path())}#clip-path-${this.clipPathId})`,
              )
              .attr('class', 'area')
              .attr('d', (data) => {
                const array = new Array(data.values.length).fill(0);
                return this.d3area(array);
              })
              .style('color', (d) => this.colorRange(d.label))
              .call((enter) =>
                enter
                  .transition()
                  .duration(1000)
                  .ease(d3_easeQuadInOut)
                  .attr('d', (data) => this.d3area(data.values)),
              ),
          (update) =>
            update.call((update) => {
              return update
                .transition()
                .duration(1000)
                .ease(d3_easeQuadInOut)
                .attr('d', (d) => this.d3area(d.values));
            }),
          (exit) => exit.remove(),
        );
    }

    // circles
    if (this.linePoints) {
      // add points
      this.svg
        .selectAll('g.points')
        .data(this.data.series)
        .join(
          (enter) =>
            enter
              .append('g')
              .attr('class', 'points')
              .attr(
                'clip-path',
                `url(${this._location.prepareExternalUrl(this._location.path())}#clip-path-points-${this.clipPathId})`,
              )
              .style('color', (d, i) => this.colorRange(d.label))

              .selectAll('circle')
              .data((d) => d.values)
              .join(
                (enter) =>
                  enter
                    .append('circle')
                    .attr('cx', (d, i) => {
                      if (this.xAxisType === 'date') {
                        return this.xAxisScale(d3_isoParse(this.data.labels[i]));
                      } else {
                        return this.xAxisScale(this.data.labels[i]);
                      }
                    })
                    .attr('cy', (d) => this.yAxisScale(0))
                    .attr('r', (d, i) => {
                      // hide circles if there is no data
                      if (d === null) {
                        return 0;
                      }

                      return this.lineWidth * 2;
                    })
                    .style('stroke-width', this.lineWidth)
                    .call((enter) =>
                      enter
                        .transition()
                        .duration(1000)
                        .ease(d3_easeQuadInOut)
                        .attr('cy', (d) => this.yAxisScale(d)),
                    ),
                () => {},
                (exit) => exit.remove(),
              ),
          (update) =>
            update
              .selectAll('circle')
              .data((d) => d.values)
              .join(
                (enter) =>
                  enter
                    .append('circle')
                    .attr('cx', (d, i) => {
                      if (this.xAxisType === 'date') {
                        return this.xAxisScale(d3_isoParse(this.data.labels[i]));
                      } else {
                        return this.xAxisScale(this.data.labels[i]);
                      }
                    })
                    .attr('cy', (d) => this.yAxisScale(d))
                    .attr('r', (d, i) => {
                      // hide circles if there is no data
                      if (d === null) {
                        return 0;
                      }

                      return this.lineWidth * 2;
                    })
                    .style('stroke-width', this.lineWidth),
                (update) =>
                  update.call((update) =>
                    update
                      .transition()
                      .duration(1000)
                      .ease(d3_easeQuadInOut)
                      .attr('cx', (d, i) => {
                        if (this.xAxisType === 'date') {
                          return this.xAxisScale(d3_isoParse(this.data.labels[i]));
                        } else {
                          return this.xAxisScale(this.data.labels[i]);
                        }
                      })
                      .attr('cy', (d) => {
                        if (d === null) {
                          return this.yAxisScale(0);
                        }

                        return this.yAxisScale(d);
                      })
                      .attr('r', (d, i) => {
                        // hide circles if there is no data
                        if (d === null) {
                          return 0;
                        }

                        return this.lineWidth * 2;
                      }),
                  ),
                (exit) => exit.remove(),
              ),
          (exit) => exit.remove(),
        );
    }

    if (!this.hideLegend) {
      this.chart
        .select('.legend')
        .selectAll('.legend-item')
        .data(this.data.series)
        .join(
          (enter) => {
            const li = enter.append('li').attr('class', 'legend-item');

            li.append('span')
              .attr('class', 'legend-key')
              .style('background-color', (d) => this.colorRange(d.label));

            li.append('span')
              .attr('class', 'legend-label')
              .html((d) => {
                switch (this.legendLabelFormatType) {
                  case 'number':
                    return this.legendLabelFormat(d.label);

                  case 'time':
                    const parsedTime = d3_isoParse(d.label);
                    return this.legendLabelFormat(parsedTime);

                  default:
                    return d.label;
                }
              });

            return li;
          },
          (update) => {
            update.select('.legend-label').html((d) => {
              switch (this.legendLabelFormatType) {
                case 'number':
                  return this.legendLabelFormat(d.label);

                case 'time':
                  const parsedTime = d3_isoParse(d.label);
                  return this.legendLabelFormat(parsedTime);

                default:
                  return d.label;
              }
            });

            return update;
          },
          (exit) => exit.remove(),
        )
        .on('mouseover', (event, data) => this.legendMouseOver(event, data))
        .on('mouseout', () => this.legendMouseOut())
        .on('click', (event, data) => this.legendMouseClick(event, data));
    }

    if (!this.hideTooltip) {
      this.tooltip
        .select('.tooltip-table')
        .selectAll('tr')
        .data(this.data.series)
        .join(
          (enter) => {
            const tooltipItem = enter.append('tr').attr('class', 'tooltip-item');

            tooltipItem
              .append('td')
              .style('color', (d) => this.colorRange(d.label))
              .append('span')
              .attr('class', 'pbds-tooltip-key');

            tooltipItem
              .append('td')
              .attr('class', 'tooltip-label pe-2 text-nowrap')
              .html((d) => {
                return this.tooltipLabelFormatType ? this.tooltipLabelFormat(d.label) : d.label;
              });

            tooltipItem
              .append('td')
              .attr('class', 'tooltip-value text-end text-nowrap')
              .html((d) => '');

            return tooltipItem;
          },
          (update) => {
            // update the tooltip label text
            const tooltipLabel = update.select('.tooltip-label');

            tooltipLabel.html((d) => {
              return this.tooltipLabelFormatType ? this.tooltipLabelFormat(d.label) : d.label;
            });
          },
          (exit) => exit.remove(),
        );
    }

    this.svg.selectAll('.points').raise();
    this.mouserect.raise();
  };

  legendMouseOver = (event, data) => {
    // console.log(data, this.linePoints);

    this.chart
      .selectAll('.legend-item')
      .filter((d, i) => {
        return d.label !== data.label;
      })
      .classed('inactive', true);

    this.svg
      .selectAll('.line')
      .filter((d, i) => {
        return d.label !== data.label;
      })
      .classed('inactive', true);

    if (this.area) {
      this.svg
        .selectAll('.area')
        .filter((d, i) => {
          return d.label !== data.label;
        })
        .classed('inactive', true);
    }

    if (this.linePoints) {
      this.svg
        .selectAll('.points')
        .filter((d, i) => {
          return d.label !== data.label;
        })
        .selectAll('circle')
        .classed('inactive', true);
    }

    this.hovered.emit({ event, data });
  };

  legendMouseOut = () => {
    this.chart.selectAll('.legend-item').classed('inactive', false);

    this.chart.selectAll('.line').classed('inactive', false).classed('active', false);

    if (this.linePoints) {
      this.svg.selectAll('circle').classed('active', false).classed('inactive', false);
    }

    if (this.area) {
      this.svg.selectAll('.area').classed('inactive', false);
    }
  };

  legendMouseClick = (event, data) => {
    this.clicked.emit({ event, data });
  };

  mouserectMouseMove = (event, data) => {
    let mouseX; // mouse x position
    let lower;
    let upper;
    let closest;
    let closestIndex;

    let leftIndex = 0;

    // handle string type, no invert function on scalePoint
    if (this.xAxisType === 'string') {
      mouseX = d3_pointer(event)[0];
    } else {
      mouseX = this.xAxisScale.invert(d3_pointer(event)[0]);
    }

    if (this.xAxisType === 'date') {
      leftIndex = d3_bisectRight(this.data.labels, d3_isoFormat(mouseX));

      // prevent error for 0 index
      if (leftIndex === 0) return false;

      lower = new Date(this.data.labels[leftIndex - 1]);
      upper = new Date(this.data.labels[leftIndex]);
      closest = +mouseX - +lower > +upper - mouseX ? upper : lower; // date mouse is closest to
      closestIndex = this.data.labels.indexOf(d3_isoFormat(closest)); // which index the mouse is closest to
      // console.log(+mouseXDate, leftIndex, +dateLower, +dateUpper, +closestDate, closestIndex);
    } else if (this.xAxisType === 'number') {
      leftIndex = d3_bisectRight(this.data.labels, mouseX);

      // prevent error for 0 index
      if (leftIndex === 0) return false;

      lower = this.data.labels[leftIndex - 1];
      upper = this.data.labels[leftIndex];
      closest = +mouseX - +lower > +upper - mouseX ? upper : lower; // date mouse is closest to
      closestIndex = this.data.labels.indexOf(closest); // which index the mouse is closest to
      // console.log(+mouseXDate, leftIndex, +lower, +upper, +closest, closestIndex);
    } else {
      const domain = this.xAxisScale.domain();
      const range = this.xAxisScale.range();
      const rangePoints = d3_range(range[0], range[1], this.xAxisScale.step());
      rangePoints.push(range[1]);

      leftIndex = d3_bisect(rangePoints, mouseX);

      if (leftIndex === 0) return false;

      lower = rangePoints[leftIndex - 1];
      upper = rangePoints[leftIndex];
      closest = +mouseX - +lower > +upper - mouseX ? +upper : +lower;

      const rangeIndex = rangePoints.indexOf(closest);
      closest = domain[rangeIndex];

      closestIndex = this.data.labels.indexOf(domain[rangeIndex]);
    }

    const circles = this.svg.selectAll('.line-group').selectAll('circle');

    circles.filter((d, i) => i === closestIndex).classed('active', true);
    circles.filter((d, i) => i !== closestIndex).classed('active', false);

    this.tooltipLine.attr('x1', this.xAxisScale(closest)).attr('x2', this.xAxisScale(closest)).classed('active', true);

    // console.log(this.tooltipLine.node().getBoundingClientRect(), this._scroll.getScrollPosition());
    this.tooltipShow(this.tooltipLine.node(), closestIndex);

    this.mousedata = {
      label: this.xAxisType === 'date' ? new Date(closest).toISOString() : closest,
      series: this.data.series.map((d) => {
        return {
          label: d.label,
          value: d.values[closestIndex],
        };
      }),
    };

    this.tooltipHovered.emit({ event, data: this.mousedata }); // index of left closest date
  };

  mouserectMouseOut = (event, data) => {
    this.svg.selectAll('circle').classed('active', false);
    this.tooltipLine.classed('active', false);
    this.tooltipHide();
  };

  mouserectMouseClick = (event) => {
    this.clicked.emit({ event, data: this.mousedata });
  };

  private tooltipShow = (node, closestIndex) => {
    const scroll = this._scroll.getScrollPosition();
    const mouserectDimensions = node.getBoundingClientRect();
    const tooltipOffsetHeight = +this.tooltip.node().offsetHeight;
    const tooltipDimensions = this.tooltip.node().getBoundingClientRect();
    const dimensionCalculated = mouserectDimensions.left + tooltipDimensions.width + 8;
    const clientWidth = document.body.clientWidth - 10;
    let position;

    // console.log(scroll, mouserectDimensions, tooltipOffsetHeight, tooltipDimensions, dimensionCalculated, clientWidth);

    this.tooltip.select('.tooltip-header').html((d) => {
      if (this.xAxisType === 'date') {
        const parsedTime = d3_isoParse(this.data.labels[closestIndex]);
        return `${this.tooltipHeadingFormat(parsedTime)}${this.tooltipHeadingSuffix}`;
      } else if (this.xAxisType === 'number') {
        const heading = this.data.labels[closestIndex];
        return `${this.tooltipHeadingFormat(heading)}${this.tooltipHeadingSuffix}`;
      } else {
        return `${this.data.labels[closestIndex]}${this.tooltipHeadingSuffix}`;
      }
    });

    this.tooltip.selectAll('.tooltip-value').html((d, i) => {
      const value = this.data.series[i].values[closestIndex];

      if (value === null || value === undefined) {
        return '';
      }

      return this.tooltipValueFormatType ? this.tooltipValueFormat(value) : value;
    });

    // flip the tooltip positions if near the right edge of the screen
    if (dimensionCalculated > clientWidth) {
      this.tooltip.classed('east', true);
      this.tooltip.classed('west', false);
      position = `${mouserectDimensions.left - tooltipDimensions.width - 8}px`;
    } else if (dimensionCalculated < clientWidth) {
      this.tooltip.classed('east', false);
      this.tooltip.classed('west', true);
      position = `${mouserectDimensions.left + 8}px`;
    }

    // console.log('POSITION: ', position, mouserectDimensions);

    // set the tooltip styles
    this.tooltip.style(
      'top',
      `${mouserectDimensions.top + mouserectDimensions.height / 2 - tooltipOffsetHeight / 2 + scroll[1]}px`,
    );
    this.tooltip.style('left', position);
    this.tooltip.style('opacity', 1);
  };

  private tooltipHide = () => {
    this.tooltip.style('opacity', 0);
  };

  private xAxisFormatter = (item) => {
    if (this.xAxisType === 'date') {
      const parseDate = d3_isoParse(item);
      return this.xAxisFormat(parseDate);
    } else if (this.xAxisType === 'number') {
      return this.xAxisFormat(item);
    } else {
      return item;
      // return this.xAxisFormat(item);
    }
  };

  private yAxisFormatter = (item) => {
    return this.yAxisFormat(item);
  };
}

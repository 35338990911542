import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'pbds-progress-button',
  template: `
    <button class="{{ btnClasses }}" matRipple type="button">
      <ng-container *ngIf="this.isLoading" aria-live="assertive">
        <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 16 16">
          <title>circle anim 3</title>
          <g class="nc-icon-wrapper" fill="currentColor">
            <g class="nc-loop-circle-3-16-icon-f">
              <path
                d="M8 16a8 8 0 1 1 8-8 1 1 0 0 1-2 0 6 6 0 1 0-1.8 4.286 1 1 0 1 1 1.4 1.428A7.956 7.956 0 0 1 8 16z"
                fill="currentColor"
              ></path>
            </g>
            <style>
              .nc-loop-circle-3-16-icon-f {
                --animation-duration: 0.7s;
                transform-origin: 8px 8px;
                animation: nc-loop-circle-3-anim var(--animation-duration) infinite linear;
              }
              @keyframes nc-loop-circle-3-anim {
                0% {
                  transform: rotate(0);
                }
                100% {
                  transform: rotate(360deg);
                }
              }
            </style>
          </g>
        </svg>
        {{ loadingLabel }} <span class="visually-hidden">in progress...</span>
      </ng-container>

      <ng-container *ngIf="!this.isLoading">
        {{ this.initLabel }}
      </ng-container>
    </button>
    <div class="visually-hidden" *ngIf="this.isCompleted" aria-live="assertive">{{ this.loadingLabel }} complete,</div>
  `
})
export class PbdsProgressButtonComponent implements OnChanges {
  @HostBinding('class.pbdsProgressButton') classPbdsProgressButton = true;

  @Input() initLabel: string;

  @Input() btnClasses: string;

  @Input() loadingLabel: string;

  @Input() isLoading: boolean = false;

  isCompleted: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading.currentValue === false && !changes.isLoading.firstChange) {
      this.isCompleted = true;
      setTimeout(() => {
        this.isCompleted = false;
      }, 1000);
    }
  }
}

import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostBinding,
  OnDestroy,
  SimpleChanges,
  ChangeDetectionStrategy
} from '@angular/core';

import { ViewportScroller } from '@angular/common';

import { select as d3_select } from 'd3-selection';
import { scaleLinear as d3_scaleLinear } from 'd3-scale';
import { min as d3_min, max as d3_max } from 'd3-array';
import {
  geoPath as d3_geoPath,
  geoAlbers as d3_geoAlbers,
  geoAlbersUsa as d3_geoAlbersUsa,
  geoMercator as d3_geoMercator
} from 'd3-geo';

import * as topojson from 'topojson-client';

import { PbdsDatavizService } from './dataviz.service';
import { PbdsDatavizMapData } from './dataviz.interfaces';

@Component({
  selector: 'pbds-dataviz-bubble-map',
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PbdsDatavizBubbleMapComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class.pbds-chart')
  chartClass = true;

  @HostBinding('class.pbds-chart-bubble-map')
  bubbleMapClass = true;

  @Input()
  data: Array<PbdsDatavizMapData>;

  @Input()
  topojson;

  @Input()
  feature = '';

  @Input()
  projectionType;

  @Input()
  scale = null;

  @Input()
  center = null;

  @Input()
  width = 306;

  @Input()
  height = 400;

  @Input()
  type: 'medium' | 'high' | 'debug' = 'medium'; // debug to show all chart options

  @Input()
  dot = false;

  @Input()
  marginTop = 0;

  @Input()
  marginRight = 0;

  @Input()
  marginBottom = 0;

  @Input()
  marginLeft = 0;

  @Input()
  color = '#ef8200';

  @Input()
  textColor = '#fff';

  @Input()
  textSizeRange = [14, 24];

  @Input()
  dotSize = 4;

  @Input()
  bubbleSizeRange = [500, 2000];

  @Input()
  bubbleLabelFormatType: 'number' = null;

  @Input()
  bubbleLabelFormatString = '';

  @Input()
  hideTooltip = false;

  @Input()
  hideTooltipValue = false;

  @Input()
  tooltipHeaderSuffix = '';

  @Input()
  tooltipValueFormatType: 'number' = null;

  @Input()
  tooltipValueFormatString = '';

  @Output()
  hovered: EventEmitter<object> = new EventEmitter<object>();

  @Output()
  clicked: EventEmitter<object> = new EventEmitter<object>();

  private projection;
  private geoPath;
  private topojsonFeature;
  private chart;
  private svg;
  private margin;
  private bubbleContainer;
  private bubbleRadius;
  private fontRange;
  private bubbleLabelFormat;
  private tooltip;
  private tooltipValueFormat;

  constructor(private _element: ElementRef, private _scroll: ViewportScroller, private _dataviz: PbdsDatavizService) {}

  ngOnInit() {
    this.margin = {
      top: +this.marginTop,
      right: +this.marginRight,
      bottom: +this.marginBottom,
      left: +this.marginLeft
    };

    if (this.type !== 'debug') {
      // set type defaults
      switch (this.type) {
        case 'medium':
          break;

        case 'high':
          break;
      }
    }

    switch (this.projectionType) {
      case 'geoAlbers':
        this.projection = d3_geoAlbers();
        break;

      case 'geoAlbersUsa':
        this.projection = d3_geoAlbersUsa();
        break;

      case 'geoMercator':
        this.projection = d3_geoMercator();
        break;

      default:
        break;
    }

    // dreate formatters
    this.bubbleLabelFormat = this._dataviz.d3Format(this.bubbleLabelFormatType, this.bubbleLabelFormatString);
    this.tooltipValueFormat = this._dataviz.d3Format(this.tooltipValueFormatType, this.tooltipValueFormatString);

    // console.log('TOPOJSON: ', this.topojson);

    this.topojsonFeature = topojson.feature(this.topojson, this.topojson.objects[this.feature]);
    this.projection.fitSize([+this.width, +this.height], this.topojsonFeature);

    // console.log('TOPOJSON FEATURE: ', this.topojsonFeature);
    // console.log('MESH: ', topojson.mesh(this.topojson, this.topojson.objects[this.feature], (a, b) => a !== b));
    // console.log('DATA: ', this.data);

    if (this.scale) {
      this.projection.scale(+this.scale);
    }

    if (this.center) {
      this.projection.center(this.center);
    }

    this.geoPath = d3_geoPath().projection(this.projection);

    // bubble radius range
    if (this.data && !this.dot) {
      this.bubbleRadius = d3_scaleLinear()
        .range(this.bubbleSizeRange)
        .domain([d3_min(this.data, (d: any) => +d.value), d3_max(this.data, (d: any) => +d.value)]);

      // font range
      this.fontRange = d3_scaleLinear()
        .range(this.textSizeRange)
        .domain([d3_min(this.data, (d: any) => +d.value), d3_max(this.data, (d: any) => +d.value)]);
    }

    // TOOLTIP
    if (!this.hideTooltip) {
      this.tooltip = d3_select('body')
        .append('div')
        .attr('class', 'pbds-tooltip south')
        .style('opacity', 0)
        .attr('aria-hidden', 'true'); // hide tooltip for accessibility

      // tooltip header
      this.tooltip.append('div').attr('class', 'tooltip-header');
      if (!this.hideTooltipValue) this.tooltip.append('div').attr('class', 'tooltip-value');
    }

    // create the chart
    this.chart = d3_select(this._element.nativeElement).attr('aria-hidden', 'true');

    // create chart svg
    this.svg = this.chart
      .append('svg')
      .attr('width', +this.width + this.margin.left + this.margin.right)
      .attr('height', +this.height + this.margin.top + this.margin.bottom)
      .attr('class', 'img-fluid')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr(
        'viewBox',
        `-${this.margin.left} -${this.margin.top} ${+this.width + this.margin.left + this.margin.right} ${
          +this.height + this.margin.top + this.margin.bottom
        }`
      )
      .append('g')
      .attr('class', 'container');

    // map
    this.svg
      .append('g')
      .attr('class', 'map')
      .selectAll('path')
      .data(this.topojsonFeature.features)
      .join((enter) => enter.append('path').attr('class', 'feature').attr('d', this.geoPath));

    // borders
    this.svg
      .append('path')
      .attr('class', 'mesh')
      .datum(topojson.mesh(this.topojson, this.topojson.objects[this.feature], (a, b) => a !== b))
      .attr('d', this.geoPath);

    this.bubbleContainer = this.svg.append('g').attr('class', 'dots').style('color', this.color);

    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      this.updateChart();
    }
  }

  ngOnDestroy() {
    if (this.tooltip) this.tooltip.remove();
  }

  updateChart = () => {
    // bubbles

    this.bubbleContainer
      .selectAll('circle')
      .data(this.data)
      .join(
        (enter) =>
          enter
            .append('circle')
            .attr('class', 'dot-circle')
            .classed('solid', this.dot)
            .attr('cx', (d) => this.projection([d.longitude, d.latitude])[0])
            .attr('cy', (d) => this.projection([d.longitude, d.latitude])[1])
            .attr('r', (d) => (!this.dot ? Math.sqrt(this.bubbleRadius(d.value)) : `${this.dotSize}px`)),
        (update) =>
          update
            .transition()
            .duration(1000)
            .attr('cx', (d) => this.projection([d.longitude, d.latitude])[0])
            .attr('cy', (d) => this.projection([d.longitude, d.latitude])[1])
            .attr('r', (d) => (!this.dot ? Math.sqrt(this.bubbleRadius(d.value)) : `${this.dotSize}px`))
            .transition()
            .selection()
            .attr('pointer-events', 'auto'),
        (exit) => exit.transition().selection().attr('pointer-events', 'none').remove()
      );

    if (!this.hideTooltip) {
      this.bubbleContainer
        .selectAll('circle')
        .on('mouseover', (event, data) => this.bubbleMouseOver(event, data))
        .on('mouseout', (event, data) => this.bubbleMouseOut(event, data))
        .on('click', (event, data) => this.bubbleMouseClick(event, data));

      // bubble text
      if (this.type !== 'high' && !this.dot) {
        this.bubbleContainer
          .selectAll('text')
          .data(this.data)
          .join(
            (enter) =>
              enter
                .append('text')
                .text((d) => (this.bubbleLabelFormat ? this.bubbleLabelFormat(d.value) : d.value))
                .attr('class', 'dot-text')
                .style('fill', this.textColor)
                .style('font-size', (d) => `${Math.round(this.fontRange(d.value))}px`)
                .attr('x', (d) => this.projection([d.longitude, d.latitude])[0])
                .attr('y', (d) => this.projection([d.longitude, d.latitude])[1])
                .attr('dy', '.4em'),
            (update) =>
              update
                .attr('pointer-events', 'none')
                .transition()
                .duration(1000)
                .text((d) => (this.bubbleLabelFormat ? this.bubbleLabelFormat(d.value) : d.value))
                .style('font-size', (d) => `${Math.round(this.fontRange(d.value))}px`)
                .attr('x', (d) => this.projection([d.longitude, d.latitude])[0])
                .attr('y', (d) => this.projection([d.longitude, d.latitude])[1])
                .attr('dy', '.4em')
                .transition()
                .selection()
                .attr('pointer-events', 'auto'),
            (exit) => exit.transition().selection().attr('pointer-events', 'none').remove()
          );
      }
    }
  };

  bubbleMouseOver = (event, data) => {
    this.chart.selectAll('.dot-circle').classed('inactive', true);

    d3_select(event.currentTarget).classed('active', true).classed('inactive', false);

    this.tooltipShow(event, data);

    this.hovered.emit({ event, data });
  };

  bubbleMouseOut = (event, data) => {
    this.chart.selectAll('.dot-circle').classed('active', false).classed('inactive', false);

    this.tooltipHide();
  };

  bubbleMouseClick = (event, data) => {
    this.clicked.emit({ event, data });
  };

  private tooltipShow = (event, data) => {
    const dimensions = event.currentTarget.getBoundingClientRect();
    const scroll = this._scroll.getScrollPosition();

    this.tooltip.select('.tooltip-header').html((d) => `${data.label}${this.tooltipHeaderSuffix}`);

    if (!this.hideTooltipValue) {
      this.tooltip
        .select('.tooltip-value')
        .html((d) => (this.tooltipValueFormat ? `${this.tooltipValueFormat(data.value)}` : `${data.value}`));
    }

    const tooltipOffsetWidth = +this.tooltip.node().offsetWidth / 2;
    const tooltipOffsetHeight = +this.tooltip.node().offsetHeight + 8;

    this.tooltip.style('top', `${+scroll[1] + +dimensions.top - tooltipOffsetHeight}px`); //
    this.tooltip.style('left', `${+scroll[0] + +dimensions.left - tooltipOffsetWidth + +dimensions.width / 2}px`);
    this.tooltip.style('opacity', 1);
  };

  private tooltipHide = () => {
    this.tooltip.style('opacity', 0);
  };
}

import { DOCUMENT } from '@angular/common';
import { AfterContentInit, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'pbds-a11y-widget',
  templateUrl: './a11y-widget.component.html',
  // encapsulation: ViewEncapsulation.ShadowDom,
})
export class PbdsA11yWidgetComponent implements OnInit, AfterContentInit {
  constructor(
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}

  form = this.fb.group({
    animation: [false],
    darkMode: [false],
    dyslexia: [false],
    fontSize: [14],
    highSaturation: [false],
    legibleFont: [false],
    lineHeight: [1.5],
    lowContrast: [false],
    showHeadings: [false],
    showLinks: [false],
    textContrast: [false],
    zoom: [1],
    vision: [false],
  });

  fontBrandRegular = '"PrecisionSans_W_Rg", "Helvetica Neue", Arial, sans-serif';
  fontBrandBold = '"PrecisionSans_W_Bd", "Helvetica Neue", Arial, sans-serif';
  fontBrandLight = '"PrecisionSans_W_Lt", "Helvetica Neue", Arial, sans-serif';
  fontSans = '"Helvetica Neue", Helvetica, Arial, sans-serif';

  ngOnInit() {}

  ngAfterContentInit() {
    this.form.valueChanges.subscribe((value) => {
      if (value.fontSize) {
        this.document.documentElement.style.setProperty('--font_size_base', `${value.fontSize}px`);
      }
      if (value.lineHeight) {
        this.document.documentElement.style.setProperty('--line_height', value.lineHeight);
      }
      if (value.zoom) {
        this.document.documentElement.style.setProperty('--zoom', value.zoom);
      }
      if (value.textContrast) {
        this.document.documentElement.style.setProperty('--text', 'black');
        this.renderer.addClass(this.document.body, 'bgwhite');
      } else {
        this.document.documentElement.style.setProperty('--text', 'var(--rich500)');
        this.renderer.removeClass(this.document.body, 'bgwhite');
      }
      if (value.showHeadings) {
        this.renderer.addClass(this.document.body, 'show-headings');
      } else {
        this.renderer.removeClass(this.document.body, 'show-headings');
      }
      if (value.showLinks) {
        this.renderer.addClass(this.document.body, 'show-links');
      } else {
        this.renderer.removeClass(this.document.body, 'show-links');
      }
      if (value.darkMode) {
        this.renderer.removeClass(this.document.body, 'bgwhite');
        this.renderer.addClass(this.document.body, 'bgdark');
      } else {
        this.renderer.removeClass(this.document.body, 'bgdark');
      }

      if (value.legibleFont) {
        this.document.documentElement.style.setProperty('--font-family-sans-serif', 'AH');
        this.document.documentElement.style.setProperty('--font_family_brand_regular', 'AH');
        this.document.documentElement.style.setProperty('--font_family_brand_bold', 'AHB');
        this.document.documentElement.style.setProperty('--font_family_brand_light', 'AH');
      } else {
        this.resetFonts();
      }
      if (value.dyslexia) {
        this.document.documentElement.style.setProperty('--font-family-sans-serif', 'Dyslexic');
        this.document.documentElement.style.setProperty('--font_family_brand_regular', 'Dyslexic');
        this.document.documentElement.style.setProperty('--font_family_brand_bold', 'DyslexicBold');
        this.document.documentElement.style.setProperty('--font_family_brand_light', 'Dyslexic');
      } else {
        this.resetFonts();
      }
      // if (value.legibleFont) {
      //   this.renderer.addClass(this.document.body, 'hyperlegible');
      // } else {
      //   this.renderer.removeClass(this.document.body, 'hyperlegible');
      // }
      if (value.highSaturation) {
        this.renderer.addClass(this.document.body, 'high-saturation');
      } else {
        this.renderer.removeClass(this.document.body, 'high-saturation');
      }
    });
  }

  resetFonts() {
    this.document.documentElement.style.setProperty('--font-family-sans-serif', this.fontSans);
    this.document.documentElement.style.setProperty('--font_family_brand_regular', this.fontBrandRegular);
    this.document.documentElement.style.setProperty('--font_family_brand_bold', this.fontBrandBold);
    this.document.documentElement.style.setProperty('--font_family_brand_light', this.fontBrandLight);
  }

  reduceSaturation() {}

  increaseSaturation() {}

  toggleVisionOptions(event: MatSlideToggleChange) {
    if (event.checked) {
      this.form.patchValue({
        textContrast: true,
        fontSize: 16,
        lineHeight: 2.0,
        legibleFont: true,
        darkMode: true,
      });
    } else {
      this.form.patchValue({
        textContrast: false,
        fontSize: 14,
        lineHeight: 1.5,
        legibleFont: false,
        darkMode: false,
      });
    }
  }

  toggleCognitiveOptions(event: MatSlideToggleChange) {
    if (event.checked) {
      this.form.patchValue({
        showHeadings: true,
        showLinks: true,
        dyslexia: true,
      });
    } else {
      this.form.patchValue({
        showHeadings: false,
        showLinks: false,
        dyslexia: false,
      });
    }
  }

  toggleLimitDistrations(event: MatSlideToggleChange) {
    if (event.checked) {
      this.form.patchValue({
        lowContrast: true,
        animation: true,
      });
    } else {
      this.form.patchValue({
        lowContrast: false,
        animation: false,
      });
    }
  }

  // checkboxVisual(event: MatCheckboxChange) {
  //   if (event.checked) {
  //     this.form.patchValue({
  //       textContrast: true,
  //       // darkMode: true,
  //       fontSize: 16,
  //       lineHeight: 2.0,
  //       legibleFont: true,
  //     });
  //   } else {
  //     this.form.patchValue({
  //       textContrast: false,
  //       darkMode: false,
  //       fontSize: 14,
  //       lineHeight: 1.5,
  //       legibleFont: false,
  //     });
  //   }
  // }
  // checkboxCognitive(event: MatCheckboxChange) {
  //   if (event.checked) {
  //     this.form.patchValue({
  //       showHeadings: true,
  //       showLinks: true,
  //     });
  //   } else {
  //     this.form.patchValue({
  //       showHeadings: false,
  //       showLinks: false,
  //     });
  //   }
  // }

  // checkboxLimitDistrations(event: MatCheckboxChange) {
  //   if (event.checked) {
  //     this.form.patchValue({
  //       lowContrast: true,
  //     });
  //   } else {
  //     this.form.patchValue({
  //       lowContrast: false,
  //     });
  //   }
  // }

  openA11yWidget(event: MouseEvent, a11yWidget: NgbModal) {
    event.preventDefault();
    this.modalService.open(a11yWidget, {
      ariaLabelledBy: 'modal-basic-title',
      // backdrop: false,
      backdropClass: 'a11y-modal-backdrop',
      centered: false,
      modalDialogClass: 'a11y-widget-dialog',
      size: 'lg',
    });
  }
}

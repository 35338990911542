import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class MockDataService {
  private dataUrl = 'assets/data';

  constructor(private http: HttpClient) {}

  get<T>(filename: string) {
    return this.http.get<T>(`${this.dataUrl}/${filename}`);
  }
  getFiles(filename: string){
    return this.http.get<any>(`${this.dataUrl}/${filename}`)
      .toPromise()
      .then(res => res.data);
  }
}

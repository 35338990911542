import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PbdsProgressButtonComponent } from './progress-button.component';

@NgModule({
  declarations: [PbdsProgressButtonComponent],
  imports: [CommonModule],
  exports: [PbdsProgressButtonComponent]
})
export class PbdsProgressButtonModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


import { PbdsA11yWidgetComponent } from './a11y-widget.component';
@NgModule({
  declarations: [PbdsA11yWidgetComponent, ],
  imports: [CommonModule, MatSlideToggleModule, FormsModule, ReactiveFormsModule],
  exports: [PbdsA11yWidgetComponent]
})
export class PbdsA11yWidgetModule {}

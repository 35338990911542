import { Directive, HostListener, HostBinding } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Directive({
  selector: 'header.bg-brand-header'
})
export class PbdsHeaderShadowDirective {
  @HostBinding('class.pbds-header-shadow')
  shadow: boolean;

  @HostListener('window:scroll', []) onWindowScroll() {
    const offset = this._scroll.getScrollPosition();
    this.shadow = offset[1] > 20;
  }

  constructor(private _scroll: ViewportScroller) {}
}

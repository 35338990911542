import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbDatepickerModule, NgbPopoverModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { MatRadioModule } from '@angular/material/radio';
import { A11yModule } from '@angular/cdk/a11y';

import { PbdsDaterangePopoverComponent } from './daterange-popover.component';

@NgModule({
  declarations: [PbdsDaterangePopoverComponent],
  imports: [
    CommonModule,
    FormsModule,
    A11yModule,
    MatRadioModule,
    NgbDatepickerModule,
    NgbPopoverModule,
    NgbDropdownModule
  ],
  exports: [PbdsDaterangePopoverComponent]
})
export class PbdsDaterangePopoverModule {}

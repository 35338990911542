import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PbdsHeaderShadowDirective } from './header-shadow.directive';

@NgModule({
  declarations: [PbdsHeaderShadowDirective],
  imports: [CommonModule],
  exports: [PbdsHeaderShadowDirective]
})
export class PbdsHeaderShadowModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { PbdsDatavizPieComponent } from './dataviz-pie.component';
import { PbdsDatavizBarComponent } from './dataviz-bar.component';
import { PbdsDatavizLineComponent } from './dataviz-line.component';
import { PbdsDatavizGaugeComponent } from './dataviz-gauge.component';
import { PbdsDatavizSparklineComponent } from './dataviz-sparkline.component';
import { PbdsDatavizBarStackedComponent } from './dataviz-bar-stacked.component';
import { PbdsDatavizMetricBlockComponent } from './dataviz-metric-block.component';
import { PbdsDatavizMetricIndicatorComponent } from './dataviz-metric-indicator.component';
import { PbdsDatavizBubbleMapComponent } from './dataviz-bubble-map.component';
import { PbdsDatavizHeatmapComponent } from './dataviz-heatmap.component';
import { PbdsDatavizChoroplethMapComponent } from './dataviz-choropleth-map.component';
import { PbdsDatavizBarGroupedComponent } from './dataviz-bar-grouped.component';
import { PbdsDatavizBarSingleHorizontalComponent } from './dataviz-bar-single-horizontal.component';
import { PbdsDatavizScatterplotComponent } from './dataviz-scatterplot.component';

// directives
import { PbdsBarStackedAnnotationsDirective } from './dataviz-bar-stacked-annotations.directive';
import { PbdsLineAnnotationsDirective } from './dataviz-line-annotations.directive';
import { PbdsBarGroupedAnnotationsDirective } from './dataviz-bar-grouped-annotations.directive';
import { PbdsBarAnnotationsDirective } from './dataviz-bar-annotations.directive';

@NgModule({
  declarations: [
    PbdsDatavizPieComponent,
    PbdsDatavizBarComponent,
    PbdsDatavizLineComponent,
    PbdsDatavizGaugeComponent,
    PbdsDatavizSparklineComponent,
    PbdsDatavizBarStackedComponent,
    PbdsDatavizMetricBlockComponent,
    PbdsDatavizBubbleMapComponent,
    PbdsDatavizMetricIndicatorComponent,
    PbdsDatavizHeatmapComponent,
    PbdsDatavizChoroplethMapComponent,
    PbdsDatavizBarGroupedComponent,
    PbdsDatavizBarSingleHorizontalComponent,
    PbdsDatavizScatterplotComponent,
    PbdsBarStackedAnnotationsDirective,
    PbdsLineAnnotationsDirective,
    PbdsBarGroupedAnnotationsDirective,
    PbdsBarAnnotationsDirective
  ],
  imports: [CommonModule, NgbTooltipModule],
  exports: [
    PbdsDatavizPieComponent,
    PbdsDatavizBarComponent,
    PbdsDatavizLineComponent,
    PbdsDatavizGaugeComponent,
    PbdsDatavizSparklineComponent,
    PbdsDatavizBarStackedComponent,
    PbdsDatavizMetricBlockComponent,
    PbdsDatavizBubbleMapComponent,
    PbdsDatavizMetricIndicatorComponent,
    PbdsDatavizHeatmapComponent,
    PbdsDatavizChoroplethMapComponent,
    PbdsDatavizBarGroupedComponent,
    PbdsDatavizBarSingleHorizontalComponent,
    PbdsDatavizScatterplotComponent,
    PbdsBarStackedAnnotationsDirective,
    PbdsLineAnnotationsDirective,
    PbdsBarGroupedAnnotationsDirective,
    PbdsBarAnnotationsDirective
  ]
})
export class PbdsDatavizModule {}

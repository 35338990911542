import { Component, OnInit, HostBinding, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { map, filter, mergeMap } from 'rxjs/operators';

import { DemoService } from '@app/shared/services/demo.service';
import { Globals } from '@app/globals';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  @HostBinding('class.demo') isDemo: boolean;

  @HostBinding('class.has-global-alert')
  get hasGlobalAlert(): boolean {
    return this.globals.hasGlobalAlert;
  }

  constructor(
    private _demo: DemoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private globals: Globals
  ) {}

  ngOnInit() {
    // check if this is a demo
    this._demo.currentMessage.subscribe((res) => (this.isDemo = res));
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => this.titleService.setTitle(event['title'] || 'Design System'));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PbdsPageTitleComponent } from './page-title.component';

@NgModule({
  declarations: [PbdsPageTitleComponent],
  imports: [CommonModule],
  exports: [PbdsPageTitleComponent]
})
export class PbdsPageTitleModule {}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'pbds-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PbdsPageTitleComponent {
  @Input()
  ttl: string;
  @Input()
  sub?: string;
  @Input() layout = 'container';
}

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { DemoLayoutComponent } from '@app/demo/layout/layout.component';
import { HeaderAsInternalComponent } from '@app/shared/account-switcher/header-as-internal.component';
import { NewBadgeComponent } from '@app/shared/badges/new-badge.component';

import { StickytableComponent } from '@app/web/stickytable/stickytable.component';
import { PtableAllFeaturesComponent } from '@app/web/tables/ptable/ptable-all-features.component';
import { PtableBasicComponent } from '@app/web/tables/ptable/ptable-basic.component';
import { PtableDragComponent } from '@app/web/tables/ptable/ptable-drag.component';
import { NgbCollapseModule, NgbModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10nIntlModule, L10nTranslationModule } from 'angular-l10n';
import { ClipboardModule } from 'ngx-clipboard';
import { PbdsA11yWidgetModule } from 'pb-design-system/a11y-widget';
import { PbdsAvatarModule } from 'pb-design-system/avatar';
import { PbdsDatavizModule } from 'pb-design-system/dataviz';
import { PbdsDaterangePopoverModule } from 'pb-design-system/daterange-popover';
import { PbdsEmptyStateModule } from 'pb-design-system/empty-state';
import { PbdsErrorIconsModule } from 'pb-design-system/error-icons';
import { PbdsHeaderShadowModule } from 'pb-design-system/header-shadow';
import { PbdsPageTitleModule } from 'pb-design-system/page-title';
import { PbdsProgressButtonModule } from 'pb-design-system/progress-button';
import { PbdsProgressSpinnerModule } from 'pb-design-system/progress-spinner';
import { BadgeModule } from 'primeng/badge';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { ChipsModule } from 'primeng/chips';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { KnobModule } from 'primeng/knob';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { PickListModule } from 'primeng/picklist';
import { SkeletonModule } from 'primeng/skeleton';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { TabletoolbarComponent } from '../web/tables/tabletoolbar/tabletoolbar.component';
import { AboutBoxComponent } from './about-box/about-box.component';
import { HeaderAsExternalComponent } from './account-switcher/header-as-external.component';
import { AccessibilityBadgeComponent } from './badges/accessibility-badge.component';
import { UserTestedBadgeComponent } from './badges/user-tested-badge.component';
import { VersionBadgeComponent } from './badges/version-badge.component';
import { BrandLinkComponent } from './brand-link.component';
import { CatWidgetComponent } from './cat-widget.component';
import { CodeBlockSimpleComponent } from './code-block-simple/code-block-simple.component';
import { CodeBlockComponent } from './code-block/code-block.component';
import { ColorswatchComponent } from './colorswatch/colorswatch.component';
import { FormControlValueDirective } from './directives/form-control-value.directive';
import { ScrollspyDirective } from './directives/scrollspy.directive';
import { FakeInputComponent } from './fake-input/fake-input.component';
import { FakeModalComponent } from './fake-modal/fake-modal.component';
import { FooterComponent } from './footer/footer.component';
import { GlobalAlertComponent } from './global-alert/global-alert.component';
import { HeaderComponent } from './header/header.component';
import { IconswatchComponent } from './iconswatch/iconswatch.component';
import { LanguageSelectorMenuComponent } from './language-selector/language-selector.component';
import { LayoutComponent } from './layout/layout.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PasswordRevealComponent } from './password-reveal/password-reveal.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { YoutubePipe } from './pipes/youtube.pipe';
import { PluginstableComponent } from './plugintables/genericplugin.component';
import { MaterialPluginTableComponent } from './plugintables/material.component';
import { NgbootstrapPluginTableComponent } from './plugintables/ngbootstrap.component';
import { PrimengPluginTableComponent } from './plugintables/primeng.component';
import { ScrollspyComponent } from './scrollspy/scrollspy.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SiteSearchComponent } from './site-search/site-search.component';
import { TableToolbarComponent } from './table-toolbar/table-toolbar.component';
import { ThemeswatchGradientComponent } from './themeswatch-gradient.component';
import { ThemeswatchComponent } from './themeswatch.component';

@NgModule({
  imports: [
    BadgeModule,
    CalendarModule,
    CarouselModule,
    ChipsModule,
    ClipboardModule,
    CommonModule,
    DragDropModule,
    DropdownModule,
    EditorModule,
    FileUploadModule,
    FormsModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    InputMaskModule,
    InputTextModule,
    KnobModule,
    L10nIntlModule,
    L10nTranslationModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatListModule,
    MatRadioModule,
    MatRippleModule,
    MatStepperModule,
    MatSidenavModule,
    MultiSelectModule,
    NgSelectModule,
    NgbModule,
    NgxSliderModule,
    NgbCollapseModule,
    PaginatorModule,
    PbdsDatavizModule,
    PbdsHeaderShadowModule,
    PbdsPageTitleModule,
    PbdsProgressSpinnerModule,
    ReactiveFormsModule,
    RouterModule,
    SkeletonModule,
    SliderModule,
    SpinnerModule,
    InputNumberModule,
    TableModule,
    TreeModule,
    TreeTableModule,
    VirtualScrollerModule,
    PbdsDaterangePopoverModule,
    PbdsEmptyStateModule,
    ContextMenuModule,
    ToastModule,
    MatDialogModule,
    MatTabsModule,
    PickListModule,
    PbdsAvatarModule,
    ToggleButtonModule,
    PbdsProgressButtonModule,
    TreeSelectModule,
    PbdsA11yWidgetModule,
    NgbRatingModule,
    ImageModule,
    PbdsErrorIconsModule,
  ],
  declarations: [
    AboutBoxComponent,
    AccessibilityBadgeComponent,
    BrandLinkComponent,
    CapitalizePipe,
    CodeBlockComponent,
    ColorswatchComponent,
    DemoLayoutComponent,
    FooterComponent,
    FormControlValueDirective,
    GlobalAlertComponent,
    HeaderAsExternalComponent,
    HeaderAsInternalComponent,
    HeaderComponent,
    IconswatchComponent,
    LanguageSelectorMenuComponent,
    LayoutComponent,
    MaterialPluginTableComponent,
    NewBadgeComponent,
    NgbootstrapPluginTableComponent,
    PageHeaderComponent,
    PasswordRevealComponent,
    PluginstableComponent,
    PrimengPluginTableComponent,
    PtableAllFeaturesComponent,
    PtableBasicComponent,
    PtableDragComponent,
    SafePipe,
    ScrollspyComponent,
    ScrollspyDirective,
    SidenavComponent,
    StickytableComponent,
    TabletoolbarComponent,
    UserTestedBadgeComponent,
    VersionBadgeComponent,
    YoutubePipe,
    ThemeswatchComponent,
    ThemeswatchGradientComponent,
    SiteSearchComponent,
    TableToolbarComponent,
    CatWidgetComponent,
    FakeModalComponent,
    FakeInputComponent,
    CodeBlockSimpleComponent,
  ],
  exports: [
    AboutBoxComponent,
    AccessibilityBadgeComponent,
    BadgeModule,
    BrandLinkComponent,
    CalendarModule,
    CapitalizePipe,
    CarouselModule,
    ChipsModule,
    CodeBlockComponent,
    ColorswatchComponent,
    CommonModule,
    DemoLayoutComponent,
    DragDropModule,
    DropdownModule,
    EditorModule,
    FileUploadModule,
    FooterComponent,
    FormControlValueDirective,
    FormsModule,
    GoogleMapsModule,
    HeaderAsExternalComponent,
    HeaderAsInternalComponent,
    HeaderComponent,
    IconswatchComponent,
    InputMaskModule,
    InputTextModule,
    KnobModule,
    L10nIntlModule,
    L10nTranslationModule,
    LanguageSelectorMenuComponent,
    LayoutComponent,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatListModule,
    MatRadioModule,
    MatRippleModule,
    MatStepperModule,
    MatSidenavModule,
    MaterialPluginTableComponent,
    MultiSelectModule,
    NewBadgeComponent,
    NgSelectModule,
    NgbModule,
    NgbCollapseModule,
    NgbootstrapPluginTableComponent,
    NgxSliderModule,
    PageHeaderComponent,
    PaginatorModule,
    PasswordRevealComponent,
    PbdsDatavizModule,
    PbdsHeaderShadowModule,
    PbdsPageTitleModule,
    PbdsProgressSpinnerModule,
    PluginstableComponent,
    PluginstableComponent,
    PrimengPluginTableComponent,
    PtableAllFeaturesComponent,
    PtableBasicComponent,
    PtableDragComponent,
    ReactiveFormsModule,
    SafePipe,
    ScrollspyComponent,
    ScrollspyDirective,
    SidenavComponent,
    SkeletonModule,
    SliderModule,
    SpinnerModule,
    InputNumberModule,
    StickytableComponent,
    TableModule,
    TabletoolbarComponent,
    TreeModule,
    TreeTableModule,
    UserTestedBadgeComponent,
    VersionBadgeComponent,
    VirtualScrollerModule,
    YoutubePipe,
    ContextMenuModule,
    ThemeswatchComponent,
    ThemeswatchGradientComponent,
    TableToolbarComponent,
    PbdsDaterangePopoverModule,
    PbdsEmptyStateModule,
    ToastModule,
    MatDialogModule,
    CatWidgetComponent,
    MatTabsModule,
    PickListModule,
    ToggleButtonModule,
    PbdsAvatarModule,
    PbdsProgressButtonModule,
    FakeModalComponent,
    FakeInputComponent,
    TreeSelectModule,
    PbdsA11yWidgetModule,
    SiteSearchComponent,
    NgbRatingModule,
    CodeBlockSimpleComponent,
    ImageModule,
    PbdsErrorIconsModule,
  ],
})
export class SharedModule {}

import { Component } from '@angular/core';
import { DemoService } from '../services/demo.service';

@Component({
  selector: 'app-layout-site',
  templateUrl: './layout.component.html'
})
export class LayoutComponent {
  constructor(private _demo: DemoService) {
    // set the demo to false
    this._demo.changeMessage(false);
    
  }
}

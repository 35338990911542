<a class="nav-link" (click)="openA11yWidget($event, a11yWidget)" href="" role="button" aria-labelledby="a11y-offscreen">
  <i class="pbi-icon-outline pbi-a11y-solid d-none d-lg-inline" aria-hidden="true"></i>

  <span class="d-lg-none" id="a11y-offscreen">
    <i class="pbi-icon-mini pbi-a11y-solid" aria-hidden="true"></i>&nbsp;Accessibility Options
  </span>
</a>

<ng-template #a11yWidget let-modal>
  <div class="modal-header">
    <h2 class="modal-title visually-hidden" id="a11y-modal-title">Accessibility Options Panel</h2>
    <button class="btn-close" (click)="modal.dismiss()" type="button" aria-label="Close"></button>
  </div>
  <div class="modal-header"></div>

  <div class="modal-body">
    <form [formGroup]="form">
      <section>
        <!-- <mat-checkbox (change)="checkboxVisual($event)">Vision</mat-checkbox> -->
        <div class="section-title">
          <h2>Vision</h2>
          <mat-slide-toggle
            [color]="'primary'"
            (change)="toggleVisionOptions($event)"
            formControlName="vision"
            aria-label="Activate all vision options"
            labelPosition="before"
            >Activate all</mat-slide-toggle
          >
        </div>

        <p>
          Some people may find items on the page difficult to read. Here you can increase the font size, line spacing,
          and other visual settings.
        </p>

        <div class="options">
          <div class="option">
            <p id="text-contrast">Increase Text Contrast</p>
            <mat-slide-toggle
              [color]="'primary'"
              formControlName="textContrast"
              aria-labelledby="text-contrast"
            ></mat-slide-toggle>
            <div>
              <div class="visually-hidden" id="textContrastHelp">
                Change the text color to black to improve readability.
              </div>
            </div>
          </div>

          <div class="option">
            <label class="d-block" for="a11y-font-size">Font Size ({{ form.controls.fontSize.value }} pixels)</label>
            <input id="a11y-font-size" type="range" formControlName="fontSize" min="14" max="24" step="1" />
          </div>
          <div class="option">
            <label class="d-block" for="line-height">Line Spacing ({{ form.controls.lineHeight.value }}x)</label>
            <input id="line-height" type="range" formControlName="lineHeight" min="1.5" max="3" step="0.1" />
          </div>

          <!-- <div class="option ">
            <p id="legible-font">Hyperlegible Font</p>
            <mat-slide-toggle formControlName="legibleFont" aria-labelledby="legible-font" [color]="'primary'"></mat-slide-toggle>
          </div> -->

          <div class="option">
            <label class="d-block" for="zoom">Zoom ({{ form.controls.zoom.value }}x)</label>
            <input id="zoom" type="range" formControlName="zoom" min="1" max="2" step="0.1" />
          </div>

          <!-- <div class="option ">
            <p id="high-saturation">High Saturation</p>
            <mat-slide-toggle formControlName="highSaturation"
                              aria-labelledby="high-saturation"
                              [color]="'primary'"></mat-slide-toggle>
          </div> -->

          <div class="option">
            <p id="dark-mode">Dark Mode</p>
            <mat-slide-toggle
              [color]="'primary'"
              formControlName="darkMode"
              aria-labelledby="dark-mode"
            ></mat-slide-toggle>
          </div>
        </div>
      </section>

      <section>
        <!-- <mat-checkbox (change)="checkboxCognitive($event)">Cognitive</mat-checkbox> -->
        <div class="section-title">
          <h2>Cognitive</h2>
          <mat-slide-toggle
            [color]="'primary'"
            (change)="toggleCognitiveOptions($event)"
            formControlName="vision"
            aria-label="Activate all cognitive options"
            labelPosition="before"
            >Activate all</mat-slide-toggle
          >
        </div>

        <p>
          If the content on the page is hard to sort out for you, you can highlight headers, links, and even use a font
          designed to aid people with dyslexia.
        </p>

        <div class="options">
          <div class="option">
            <p id="show-headings">Show Headings</p>
            <mat-slide-toggle
              [color]="'primary'"
              formControlName="showHeadings"
              aria-label="show-headings"
            ></mat-slide-toggle>
          </div>

          <div class="option">
            <p id="show-links">Show Links</p>
            <mat-slide-toggle
              [color]="'primary'"
              formControlName="showLinks"
              aria-labelledby="show-links"
            ></mat-slide-toggle>
          </div>

          <div class="option">
            <p id="dyslexia">Dyslexia-friendly font</p>
            <mat-slide-toggle
              [color]="'primary'"
              formControlName="dyslexia"
              aria-labelledby="dyslexia"
            ></mat-slide-toggle>
          </div>
        </div>
      </section>

      <!-- <section>

        <div class="section-title">
          <h2>Seizures</h2>
          <mat-slide-toggle formControlName="vision"
                            aria-label="Activate all limit distractions options"
                            [color]="'primary'"
                            (change)="toggleLimitDistrations($event)"
                            labelPosition="before">Activate all </mat-slide-toggle>
        </div>

        <p>Some people might find animations or bright color contrast distracting or annoying.</p>

        <div class="options">
          <div class="option">
            <p id="low-contrast">Reduce Saturation</p>
            <mat-slide-toggle formControlName="lowContrast"
                              aria-labelledby="low-contrast"
                              [color]="'primary'"></mat-slide-toggle>
          </div>
          <div class="option">
            <p id="animation">Stop Animations</p>
            <mat-slide-toggle formControlName="animation"
                              aria-labelledby="animation"
                              [color]="'primary'"></mat-slide-toggle>
          </div>
        </div>
      </section> -->
    </form>
  </div>
  <!-- <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close()"
    >Done</button>
  </div> -->
</ng-template>

import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostBinding,
  OnDestroy,
  SimpleChanges,
  ChangeDetectionStrategy
} from '@angular/core';

import { ViewportScroller } from '@angular/common';

import { select as d3_select, pointer as d3_pointer } from 'd3-selection';
import {
  scaleLinear as d3_scaleLinear,
  scaleThreshold as d3_scaleThreshold,
  scaleQuantile as d3_scaleQuantile,
  scaleQuantize as d3_scaleQuantize
} from 'd3-scale';
import { min as d3_min, max as d3_max, range as d3_range } from 'd3-array';
import {
  geoPath as d3_geoPath,
  geoAlbers as d3_geoAlbers,
  geoAlbersUsa as d3_geoAlbersUsa,
  geoMercator as d3_geoMercator
} from 'd3-geo';
import { axisBottom as d3_axisBottom } from 'd3-axis';

import * as topojson from 'topojson-client';
import { PbdsDatavizService } from './dataviz.service';

import { PbdsDatavizChoroplethMapData } from './dataviz.interfaces';

@Component({
  selector: 'pbds-dataviz-choropleth-map',
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PbdsDatavizChoroplethMapComponent implements OnInit, OnDestroy, OnChanges {
  @HostBinding('class.pbds-chart')
  chartClass = true;

  @HostBinding('class.pbds-chart-choropleth-map')
  choroplethMapClass = true;

  @Input()
  data: Array<PbdsDatavizChoroplethMapData>;

  @Input()
  topojson;

  @Input()
  feature = '';

  @Input()
  projectionType;

  @Input()
  dataField = 'id';

  @Input()
  mesh: string | null = null;

  @Input()
  scale = null;

  @Input()
  center = null;

  @Input()
  width = 960;

  @Input()
  height = 500;

  @Input()
  marginTop = 0;

  @Input()
  marginRight = 0;

  @Input()
  marginBottom = 0;

  @Input()
  marginLeft = 0;

  @Input()
  theme: 'classic' | 'ocean' | 'sunset' | 'twilight' = 'classic';

  @Input()
  colorScale: 'threshold' | 'quantile' | 'quantize' = 'quantile';

  @Input()
  domain: Array<number>;

  @Input()
  hideTooltip = false;

  @Input()
  tooltipHeaderSuffix = '';

  @Input()
  tooltipValueFormatType: 'number' = null;

  @Input()
  tooltipValueFormatString = '';

  @Input()
  hideLegend = false;

  @Input()
  legendWidth = 260;

  @Input()
  legendLabel: string | null = null;

  @Input()
  legendValueFormatType: 'number' = null;

  @Input()
  legendValueFormatString = '';

  @Input()
  legendLeft = 20;

  @Input()
  legendTop = 20;

  @Output()
  hovered: EventEmitter<object> = new EventEmitter<object>();

  @Output()
  clicked: EventEmitter<object> = new EventEmitter<object>();

  private projection;
  private geoPath;
  private topojsonFeature;
  private chart;
  private svg;
  private margin;
  private colorRange;
  private colorDomain;
  private tooltip;
  private tooltipValueFormat;
  private legendValueFormat;

  constructor(private _dataviz: PbdsDatavizService, private _element: ElementRef, private _scroll: ViewportScroller) {}

  ngOnInit() {
    this.margin = {
      top: +this.marginTop,
      right: +this.marginRight,
      bottom: +this.marginBottom,
      left: +this.marginLeft
    };

    // color range
    const colors = this._dataviz.getColors(true, this.theme).slice().reverse();

    const colorDomain: any = [+d3_min(this.data, (d) => d.value), +d3_max(this.data, (d) => d.value)];
    const colorValues = this.data.map((d) => d.value);

    switch (this.colorScale) {
      case 'threshold':
        this.colorRange = d3_scaleThreshold().domain(this.domain).range(colors);

        this.colorDomain = this.colorRange.domain();
        break;

      case 'quantile':
        this.colorRange = d3_scaleQuantile().domain(colorValues).range(colors);

        this.colorDomain = this.colorRange.quantiles();
        break;

      case 'quantize':
        this.colorRange = d3_scaleQuantize().domain(colorDomain).range(colors);

        this.colorDomain = this.colorRange.thresholds();
        break;
    }

    // create formatters
    this.tooltipValueFormat = this._dataviz.d3Format(this.tooltipValueFormatType, this.tooltipValueFormatString);
    this.legendValueFormat = this._dataviz.d3Format(this.legendValueFormatType, this.legendValueFormatString);

    switch (this.projectionType) {
      case 'geoAlbers':
        this.projection = d3_geoAlbers();
        break;

      case 'geoAlbersUsa':
        this.projection = d3_geoAlbersUsa();
        break;

      case 'geoMercator':
        this.projection = d3_geoMercator();
        break;
    }

    this.topojsonFeature = topojson.feature(this.topojson, this.topojson.objects[this.feature]);
    this.projection.fitSize([+this.width, +this.height], this.topojsonFeature);

    if (this.scale) {
      this.projection.scale(+this.scale);
    }

    if (this.center) {
      this.projection.center(this.center);
    }

    this.geoPath = d3_geoPath().projection(this.projection);

    // console.log('TOPOJSON: ', this.topojson);
    // console.log('TOPOJSON FEATURE: ', this.topojsonFeature);
    // console.log('MESH: ', topojson.mesh(this.topojson, this.topojson.objects[this.feature], (a, b) => a !== b));
    // console.log('DATA: ', this.data);

    // create the chart
    this.chart = d3_select(this._element.nativeElement).attr('aria-hidden', 'true');

    // TOOLTIP
    if (!this.hideTooltip) {
      this.tooltip = d3_select('body')
        .append('div')
        .attr('class', 'pbds-tooltip south')
        .style('opacity', 0)
        .attr('aria-hidden', 'true'); // hide tooltip for accessibility

      // tooltip header
      this.tooltip.append('div').attr('class', 'tooltip-header');
      this.tooltip.append('div').attr('class', 'tooltip-value');
    }

    // create chart svg
    this.svg = this.chart
      .append('svg')
      .attr('width', +this.width)
      .attr('height', +this.height + this.margin.top + this.margin.bottom)
      .attr('class', 'img-fluid')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr(
        'viewBox',
        `-${this.margin.left} -${this.margin.top} ${+this.width} ${+this.height + this.margin.top + this.margin.bottom}`
      );

    // map
    this.svg
      .append('g')
      .attr('class', 'map')
      .selectAll('path')
      .data(this.topojsonFeature.features)
      .join((enter) => enter.append('path').attr('class', 'feature').attr('d', this.geoPath));

    // borders
    this.svg
      .append('path')
      .attr('class', 'mesh')
      .datum(topojson.mesh(this.topojson, this.topojson.objects[this.mesh || this.feature], (a, b) => a !== b))
      .attr('d', this.geoPath);

    // legend
    if (!this.hideLegend) {
      this.svg
        .append('g')
        .attr('transform', `translate(${+this.legendLeft}, ${+this.legendTop})`)
        .call(this.legend);
    }

    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      this.updateChart();
    }
  }

  ngOnDestroy() {
    if (this.tooltip) this.tooltip.remove();
  }

  updateChart = () => {
    this.svg
      .select('.map')
      .selectAll('path')
      .style('fill', (d, i) => {
        const match = this.data.find((obj) => obj[this.dataField] === d[this.dataField]);
        if (match) {
          return this.colorRange(match.value);
        }
      })
      .classed('hasData', (d, i) => {
        return this.data.some((obj) => obj[this.dataField] === d[this.dataField]);
      });

    if (!this.hideTooltip) {
      this.svg
        .select('.map')
        .selectAll('path')
        .on('mouseover', (event, data) =>
          this.featureMouseOver(
            event,
            this.data.find((obj) => obj[this.dataField] === data[this.dataField])
          )
        )
        .on('mouseout', (event, data) => this.featureMouseOut(event, this.data))
        .on('mousemove', (event, data) => this.tooltipMove(event))
        .on('click', (event, data) =>
          this.featureMouseClick(
            event,
            this.data.find((obj) => obj[this.dataField] === data[this.dataField])
          )
        );
    }
  };

  featureMouseOver = (event, data) => {
    if (data) {
      this.tooltipShow(event, data);
      this.hovered.emit({ event, data });
    }
  };

  featureMouseOut = (event, data) => {
    this.tooltipHide();
  };

  featureMouseClick = (event, data) => {
    if (data) {
      this.clicked.emit({ event, data });
    }
  };

  private tooltipShow = (event, data) => {
    // console.log('TOOLTIP: ', data, node);

    this.tooltipSetPosition(event);

    if (data.label) {
      this.tooltip.select('.tooltip-header').html((d) => `${data.label}${this.tooltipHeaderSuffix}`);
    }

    this.tooltip
      .select('.tooltip-value')
      .html((d) => (this.tooltipValueFormat ? `${this.tooltipValueFormat(data.value)}` : `${data.value}`));

    this.tooltip.style('opacity', 1);
  };

  private tooltipHide = () => {
    this.tooltip.style('opacity', 0);
  };

  private tooltipMove = (event) => {
    this.tooltipSetPosition(event);
  };

  private tooltipSetPosition = (event) => {
    const mouse = d3_pointer(event, this.chart.node());
    const mouseLeft = +mouse[0];
    const mouseTop = +mouse[1];

    const geometry = this.chart.node().getBoundingClientRect();
    const geometryLeft = +geometry.left;
    const geometryTop = +geometry.top;

    const scroll = this._scroll.getScrollPosition();
    // const scrollLeft = +scroll[0];
    const scrollTop = +scroll[1];

    const tooltipOffsetWidth = +this.tooltip.node().offsetWidth / 2;
    const tooltipOffsetHeight = +this.tooltip.node().offsetHeight;

    this.tooltip.style('top', `${scrollTop + mouseTop + geometryTop - tooltipOffsetHeight - 14}px`);
    this.tooltip.style('left', `${mouseLeft + geometryLeft - tooltipOffsetWidth}px`); //
  };

  legend = (g) => {
    const length = this.colorRange.range().length;

    // console.log(this.colorRange.range().length, this.colorDomain);

    const x = d3_scaleLinear()
      .domain([1, length - 1])
      .rangeRound([+this.legendWidth / length, (this.legendWidth * (length - 1)) / length]);

    g.attr('class', 'legend')
      .selectAll('rect')
      .data(this.colorRange.range())
      .join('rect')
      .attr('height', 8)
      .attr('x', (d, i) => x(i))
      .attr('width', (d, i) => x(i + 1) - x(i))
      .attr('fill', (d) => d);

    if (this.legendLabel) {
      g.append('text').attr('y', -6).attr('text-anchor', 'start').attr('class', 'legend-label').text(this.legendLabel);
    }

    g.call(
      d3_axisBottom(x)
        .tickSize(13)
        .tickValues(d3_range(1, length))
        .tickFormat((i: number) =>
          this.legendValueFormat ? `${this.legendValueFormat(this.colorDomain[i - 1])}` : `${this.colorDomain[i - 1]}`
        )
    )
      .select('.domain')
      .remove();
  };
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PbdsAvatarComponent } from './avatar.component';

@NgModule({
  declarations: [PbdsAvatarComponent],
  imports: [CommonModule],
  exports: [PbdsAvatarComponent]
})
export class PbdsAvatarModule {}

import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostBinding,
  OnDestroy,
  SimpleChanges,
  ChangeDetectionStrategy
} from '@angular/core';

import { ViewportScroller, Location } from '@angular/common';

import { select as d3_select } from 'd3-selection';
import { scaleOrdinal as d3_scaleOrdinal, scaleBand as d3_scaleBand, scaleLinear as d3_scaleLinear } from 'd3-scale';
import { max as d3_max } from 'd3-array';
import { axisBottom as d3_axisBottom, axisLeft as d3_axisLeft } from 'd3-axis';
import { isoParse as d3_isoParse } from 'd3-time-format';

import { PbdsDatavizService } from './dataviz.service';
import { PbdsDatavizBarGrouped } from './dataviz.interfaces';

@Component({
  selector: 'pbds-dataviz-bar-grouped',
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PbdsDatavizBarGroupedComponent implements OnInit, OnDestroy, OnChanges {
  @HostBinding('class.pbds-chart')
  chartClass = true;

  @HostBinding('class.pbds-chart-bar-grouped')
  groupedBarClass = true;

  @Input()
  data: Array<PbdsDatavizBarGrouped>;

  @Input()
  width = 306;

  @Input()
  height = 400;

  @Input()
  vertical = true;

  @Input()
  hideXAxis = false;

  @Input()
  xAxisMaxBuffer = 0.01;

  @Input()
  xAxisFormatType: 'number' | 'time' = null;

  @Input()
  xAxisFormatString = '';

  @Input()
  xAxisTicks = 5;

  @Input()
  xAxisTitle: string | null = null;

  @Input()
  hideYAxis = false;

  @Input()
  yAxisMaxBuffer = 0.01;

  @Input()
  yAxisFormatType: 'number' | 'time' = null;

  @Input()
  yAxisFormatString = '';

  @Input()
  yAxisTicks = 5;

  @Input()
  marginTop = 10;

  @Input()
  marginRight = this.vertical ? 0 : 55;

  @Input()
  marginBottom = 30;

  @Input()
  marginLeft = 55;

  @Input()
  hideLegend = false;

  @Input()
  legendWidth = 105 + 28; // hardcoded legend width + left margin, do not document until feedback

  @Input()
  legendPosition: 'right' | 'bottom' = 'right';

  @Input()
  legendLabelFormatType: 'number' | 'time' = null;

  @Input()
  legendLabelFormatString = '';

  @Input()
  hideTooltip = false;

  @Input()
  tooltipLabelFormatType: 'number' | 'time' = null;

  @Input()
  tooltipLabelFormatString = '';

  @Input()
  tooltipValueFormatType: 'number' = null;

  @Input()
  tooltipValueFormatString = '';

  @Input()
  showGrid = false;

  @Input()
  theme: 'classic' | 'sunset' | 'ocean' | 'twilight' = 'classic';

  @Input()
  gradient = true;

  @Output()
  hovered: EventEmitter<object> = new EventEmitter<object>();

  @Output()
  clicked: EventEmitter<object> = new EventEmitter<object>();

  private barScale;
  private chart;
  private svg;
  private margin;
  private colorRange;
  private xAxisMax;
  private xAxisScale;
  private xAxisCall;
  private xAxis;
  private xAxisTickSize: number;
  private xAxisTickSizeOuter: number;
  private xAxisFormat;
  private xAxisTitleMargin: number;
  private hideXAxisDomain: boolean;
  private hideXAxisZero: boolean;
  private hideXAxisTicks: boolean;
  private xGrid;
  private xGridCall;
  private yAxisMax;
  private yAxisScale;
  private yAxisCall;
  private yAxis;
  private yAxisFormat;
  private yAxisTickSize: number;
  private yAxisTickSizeOuter: number;
  private hideYAxisZero: boolean;
  private hideYAxisDomain: boolean;
  private hideYAxisTicks: boolean;
  private yGrid;
  private yGridCall;
  private legendLabelFormat;
  private hideGrayBars: boolean;
  private tooltip;
  private tooltipValueFormat;
  private tooltipLabelFormat;

  constructor(
    private _dataviz: PbdsDatavizService,
    private _element: ElementRef,
    private _scroll: ViewportScroller,
    private _location: Location
  ) {}

  ngOnInit() {
    this.margin = {
      top: +this.marginTop,
      right: +this.marginRight,
      bottom: +this.marginBottom,
      left: +this.marginLeft
    };

    // create formatters
    this.xAxisFormat = this._dataviz.d3Format(this.xAxisFormatType, this.xAxisFormatString);
    this.yAxisFormat = this._dataviz.d3Format(this.yAxisFormatType, this.yAxisFormatString);
    this.legendLabelFormat = this._dataviz.d3Format(this.legendLabelFormatType, this.legendLabelFormatString);
    this.tooltipLabelFormat = this._dataviz.d3Format(this.tooltipLabelFormatType, this.tooltipLabelFormatString);
    this.tooltipValueFormat = this._dataviz.d3Format(this.tooltipValueFormatType, this.tooltipValueFormatString);

    // defaults for all chart types
    this.hideGrayBars = false;
    this.hideXAxisZero = false;
    this.hideXAxisDomain = false;
    this.hideXAxisTicks = true;
    this.xAxisTickSize = 8;
    this.xAxisTickSizeOuter = 0;
    this.xAxisTitleMargin = this.xAxisTitle ? 30 : 0;
    this.hideYAxisZero = false;
    this.hideYAxisDomain = false;
    this.hideYAxisTicks = true;
    this.yAxisTickSize = 8;
    this.yAxisTickSizeOuter = 0;

    if (!this.hideLegend && this.legendPosition === 'right') {
      this.width = +this.width - +this.legendWidth;
    }

    // create the chart
    this.chart = d3_select(this._element.nativeElement).attr('aria-hidden', 'true');

    // create chart svg
    this.svg = this.chart
      .append('svg')
      .attr('width', () => {
        if (this.vertical) {
          return +this.width;
        } else {
          return +this.width + this.margin.left + this.margin.right;
        }
      })
      .attr('height', +this.height + this.margin.top + this.margin.bottom + this.xAxisTitleMargin)
      .attr('class', 'img-fluid')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', () => {
        if (this.vertical) {
          return `-${this.margin.left} -${this.margin.top} ${+this.width} ${
            +this.height + this.margin.top + this.margin.bottom + this.xAxisTitleMargin
          }`;
        } else {
          return `-${this.margin.left} -${this.margin.top} ${+this.width + this.margin.left + this.margin.right} ${
            +this.height + this.margin.top + this.margin.bottom + this.xAxisTitleMargin
          }`;
        }
      });

    // TOOLTIP
    if (!this.hideTooltip) {
      this.tooltip = d3_select('body')
        .append('div')
        .attr('class', () => {
          return this.vertical ? 'pbds-tooltip south' : 'pbds-tooltip west';
        })
        .style('opacity', 0)
        .attr('aria-hidden', 'true'); // hide tooltip for accessibility
    }

    // add legend classes
    if (!this.hideLegend) {
      this.chart.classed('pbds-chart-legend-bottom', this.legendPosition === 'bottom' ? true : false);
      this.chart.append('ul').attr('class', `legend legend-${this.legendPosition}`);
    }

    // build color ranges
    this.colorRange = d3_scaleOrdinal().range(
      this._dataviz.createGradientDefs(this.svg, false, this.theme, this.vertical)
    );

    if (this.vertical) {
      // X AXIS
      this.xAxisScale = d3_scaleBand()
        .domain(this.data.map((d) => d.key))
        .rangeRound([0, this.width - this.margin.left])
        .align(0);

      // add padding to the scale for gray bars
      !this.hideGrayBars
        ? this.xAxisScale.paddingInner(0.1).paddingOuter(0)
        : this.xAxisScale.paddingInner(0).paddingOuter(0);

      this.xAxisCall = d3_axisBottom(this.xAxisScale)
        .tickSize(this.xAxisTickSize)
        .tickSizeOuter(this.xAxisTickSizeOuter)
        .tickFormat(this.xAxisFormatter);

      this.xAxis = this.svg
        .append('g')
        .attr('class', 'axis axis-x')
        .attr('transform', `translate(0, ${this.height})`)
        .classed('axis-hidden', this.hideXAxis)
        .classed('axis-zero-hidden', this.hideXAxisZero)
        .classed('axis-domain-hidden', this.hideXAxisDomain)
        .classed('axis-ticks-hidden', this.hideXAxisTicks)
        .call(this.xAxisCall);

      // X GRIDLINES
      // if (!this.hideXGrid) {
      //   this.xGridCall = d3_axisBottom(this.xAxisScale).tickSize(-this.height);

      //   this.xGrid = this.svg
      //     .append('g')
      //     .attr('class', 'grid grid-x')
      //     .classed('grid-zero-hidden', this.hideXAxisZero)
      //     .attr('transform', `translate(0, ${this.height})`)
      //     .call(this.xGridCall);
      // }

      // Y AXIS
      this.yAxisMax = d3_max(this.data, (data: any) => {
        const clone = { ...data };
        delete clone.key;

        return d3_max(Object.values(clone));
      });

      this.yAxisMax = this.yAxisMax + this.yAxisMax * this.yAxisMaxBuffer;

      this.yAxisScale = d3_scaleLinear().domain([0, this.yAxisMax]).nice().rangeRound([this.height, 0]);

      this.yAxisCall = d3_axisLeft(this.yAxisScale)
        .ticks(this.yAxisTicks)
        .tickSize(this.yAxisTickSize)
        .tickSizeOuter(this.yAxisTickSizeOuter)
        .tickFormat(this.yAxisFormatter);

      this.yAxis = this.svg
        .append('g')
        .attr('class', 'axis axis-y')
        .classed('axis-hidden', this.hideYAxis)
        .classed('axis-zero-hidden', this.hideYAxisZero)
        .classed('axis-domain-hidden', this.hideYAxisDomain)
        .classed('axis-ticks-hidden', this.hideYAxisTicks)
        .call(this.yAxisCall);

      // Y GRIDLINES
      if (this.showGrid) {
        this.yGridCall = d3_axisLeft(this.yAxisScale)
          .ticks(this.yAxisTicks)
          .tickSize(-this.width + this.margin.left + this.margin.right);

        this.yGrid = this.svg
          .append('g')
          .attr('class', 'grid grid-y')
          .classed('grid-zero-hidden', this.hideYAxisZero)
          .attr('transform', `translate(0, 0)`)
          .call(this.yGridCall);
      }

      // color bar scale
      this.barScale = d3_scaleBand()
        .domain(Object.keys(this.data[0]).slice(1))
        .rangeRound([0, this.xAxisScale.bandwidth()])
        .paddingInner(0.2)
        .paddingOuter(0.5);

      this.updateChartVertical();
    } else {
      // X AXIS
      this.xAxisMax = d3_max(this.data, (data: any) => {
        const clone = { ...data };
        delete clone.key;
        return d3_max(Object.values(clone));
      });

      this.xAxisMax = this.xAxisMax + this.xAxisMax * this.xAxisMaxBuffer;

      this.xAxisScale = d3_scaleLinear().domain([0, this.xAxisMax]).rangeRound([0, this.width]).nice();

      this.xAxisCall = d3_axisBottom(this.xAxisScale)
        .ticks(this.xAxisTicks)
        .tickSize(this.xAxisTickSize)
        .tickSizeOuter(this.xAxisTickSizeOuter)
        .tickFormat(this.xAxisFormatter);

      this.xAxis = this.svg
        .append('g')
        .attr('class', 'axis axis-x')
        .attr('transform', `translate(0, ${this.height})`)
        .classed('axis-hidden', this.hideXAxis)
        .classed('axis-zero-hidden', this.hideXAxisZero)
        .classed('axis-domain-hidden', this.hideXAxisDomain)
        .classed('axis-ticks-hidden', this.hideXAxisTicks)
        .call(this.xAxisCall);

      // Y AXIS
      this.yAxisScale = d3_scaleBand()
        .domain(this.data.map((d) => d.key))
        .rangeRound([0, this.height])
        .align(1);

      // add padding to the scale for gray bars
      !this.hideGrayBars
        ? this.yAxisScale.paddingInner(0.1).paddingOuter(0)
        : this.yAxisScale.paddingInner(0).paddingOuter(0);

      this.yAxisCall = d3_axisLeft(this.yAxisScale)
        .tickSize(this.yAxisTickSize)
        .tickSizeOuter(this.yAxisTickSizeOuter)
        .tickFormat(this.yAxisFormatter);

      this.yAxis = this.svg
        .append('g')
        .attr('class', 'axis axis-y')
        .classed('axis-hidden', this.hideYAxis)
        .classed('axis-zero-hidden', this.hideYAxisZero)
        .classed('axis-domain-hidden', this.hideYAxisDomain)
        .classed('axis-ticks-hidden', this.hideYAxisTicks)
        .call(this.yAxisCall);

      // X GRIDLINES
      if (this.showGrid) {
        this.xGridCall = d3_axisBottom(this.xAxisScale).tickSize(-this.height);
        this.xGrid = this.svg
          .append('g')
          .attr('class', 'grid grid-x')
          .classed('grid-zero-hidden', this.hideXAxisZero)
          .attr('transform', `translate(0, ${this.height})`)
          .call(this.xGridCall);
      }

      // Y GRIDLINES
      // if (!this.hideYGrid) {
      //   this.yGridCall = d3_axisLeft(this.yAxisScale)
      //     .ticks(this.yAxisTicks)
      //     .tickSize(-this.width);

      //   this.yGrid = this.svg
      //     .append('g')
      //     .attr('class', 'grid grid-y')
      //     .classed('grid-zero-hidden', this.hideYAxisZero)
      //     .attr('transform', `translate(0, 0)`)
      //     .call(this.yGridCall);
      // }

      // color bar scale
      this.barScale = d3_scaleBand()
        .domain(Object.keys(this.data[0]).slice(1))
        .rangeRound([this.yAxisScale.bandwidth(), 0])
        .paddingInner(0.2)
        .paddingOuter(0.5);

      this.updateChartHorizontal();
    }

    // X AXIS TITLE
    if (this.xAxisTitle) {
      this.svg
        .append('text')
        .attr('class', 'axis-title')
        .attr('text-anchor', 'middle')
        .attr(
          'transform',
          `translate(${this.svg.attr('width') / 2 - this.margin.left / 2 - this.margin.right / 2}, ${
            this.height + this.margin.top + (this.hideXAxis ? 20 : 40)
          })`
        )
        .text(this.xAxisTitle);
    }
  }

  ngOnDestroy() {
    if (this.tooltip) this.tooltip.remove();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      if (this.vertical) {
        this.updateChartVertical();
      } else {
        this.updateChartHorizontal();
      }
    }
  }

  updateChartVertical() {
    // update the xScale
    this.xAxisScale.domain(this.data.map((d) => d.key));

    // update the yScale
    this.yAxisMax = d3_max(this.data, (data: any) => {
      const clone = { ...data };
      delete clone.key;

      return d3_max(Object.values(clone));
    });

    this.yAxisMax = this.yAxisMax + this.yAxisMax * this.yAxisMaxBuffer;

    this.yAxisScale.domain([0, this.yAxisMax]).rangeRound([this.height, 0]).nice();

    this.xAxis.transition().duration(1000).call(this.xAxisCall);

    this.yAxis.transition().duration(1000).call(this.yAxisCall);

    // update the grids
    // if (!this.hideXGrid) {
    //   this.xGrid
    //     .transition()
    //     .duration(1000)
    //     .call(this.xGridCall);
    // }

    if (this.showGrid) {
      this.yGrid.transition().duration(1000).call(this.yGridCall);
    }

    // update the color bar scale
    this.barScale.domain(Object.keys(this.data[0]).slice(1)).rangeRound([0, this.xAxisScale.bandwidth()]);

    this.svg
      .selectAll('.gray-bar')
      .data(this.data)
      .join(
        (enter) =>
          enter
            .append('rect')
            .attr('class', 'gray-bar')
            .attr('x', (d) => this.xAxisScale(d.key))
            .attr('y', (d) => this.yAxisScale(d.value))
            .attr('width', this.xAxisScale.bandwidth())
            .attr('height', this.height),
        (update) =>
          update
            .transition()
            .duration(1000)
            .attr('x', (d) => this.xAxisScale(d.key))
            .attr('y', (d) => this.yAxisScale(d.value))
            .attr('width', this.xAxisScale.bandwidth())
            .attr('height', this.height)
            .selection()
      );

    this.svg
      .selectAll('.bar-group')
      .data(this.data)
      .join(
        (enter) =>
          enter
            .append('g')
            .attr('class', 'bar-group')
            .attr('transform', (d, i) => {
              return `translate(${this.xAxisScale(d.key)}, 0)`;
            }),
        (update) =>
          update
            .transition()
            .duration(1000)
            .attr('transform', (d, i) => {
              return `translate(${this.xAxisScale(d.key)}, 0)`;
            })
            .selection()
      );

    this.svg
      .selectAll('.bar-group')
      // .selectAll('.bar')
      .selectChildren()
      .data((d, i) => {
        const clone = { ...d };
        delete clone.key;

        const keys = Object.keys(clone);

        const keyData = keys.map(function (key) {
          return { label: key, value: d[key], parentIndex: i };
        });

        return keyData;
      })
      .join(
        (enter) =>
          enter
            .append('rect')
            .attr('class', 'bar')
            .attr('fill', (d) => this.barFill(d))
            .attr('data-color', (d) => this.colorRange(d.label))
            .attr('data-parent-index', (d) => d.parentIndex)
            .attr('x', (d) => this.barScale(d.label))
            .attr('width', this.barScale.bandwidth())
            .attr('y', this.height)
            .attr('height', 0)
            .call((enter) => {
              return enter
                .attr('pointer-events', 'none')
                .transition()
                .duration(0) // 500
                .attr('height', (d) => this.height - this.yAxisScale(d.value))
                .attr('y', (d) => this.yAxisScale(d.value))
                .transition()
                .attr('pointer-events', 'auto');
            }),

        (update) =>
          update
            .attr('pointer-events', 'none')
            .transition()
            .duration(1000)
            .attr('x', (d) => this.barScale(d.label))
            .attr('width', this.barScale.bandwidth())
            .attr('height', (d) => this.height - this.yAxisScale(d.value))
            .attr('y', (d) => this.yAxisScale(d.value))
            .transition()
            .selection()
            .attr('pointer-events', 'auto'),
        (exit) =>
          exit
            .transition()
            .duration(0) // 100
            .selection()
            .attr('pointer-events', 'none')
            .attr('height', 0)
            .attr('y', this.height)
      )
      .on('mouseover', (event, data: PbdsDatavizBarGrouped[]) => this.barMouseOver(event, data))
      .on('mouseout', (event, data) => this.barMouseOut())
      .on('click', (event, data) => this.barMouseClick(event, data));

    this.updateLegend();

    this.svg.selectAll('.axis').raise();
  }

  updateChartHorizontal() {
    // update the xScale
    this.xAxisMax = d3_max(this.data, (data: any) => {
      const clone = { ...data };
      delete clone.key;
      return d3_max(Object.values(clone));
    });

    this.xAxisMax = this.xAxisMax + this.xAxisMax * this.xAxisMaxBuffer;

    this.xAxisScale.domain([0, this.xAxisMax]).rangeRound([0, this.width]).nice();

    // update the yScale
    this.yAxisScale.domain(this.data.map((d) => d.key));

    this.xAxis.transition().duration(1000).call(this.xAxisCall);

    this.yAxis.transition().duration(1000).call(this.yAxisCall);

    // update the grids
    if (this.showGrid) {
      this.xGrid.transition().duration(1000).call(this.xGridCall);
    }

    // if (!this.hideYGrid) {
    //   this.yGrid
    //     .transition()
    //     .duration(1000)
    //     .call(this.yGridCall);
    // }

    // update the color bar scale
    this.barScale.domain(Object.keys(this.data[0]).slice(1)).rangeRound([0, this.yAxisScale.bandwidth()]);

    this.svg
      .selectAll('.gray-bar')
      .data(this.data)
      .join(
        (enter) =>
          enter
            .append('rect')
            .attr('class', 'gray-bar')
            .attr('y', (d) => this.yAxisScale(d.key))
            .attr('width', this.width)
            .attr('height', this.yAxisScale.bandwidth()),
        (update) =>
          update
            .transition()
            .duration(1000)
            .attr('y', (d) => this.yAxisScale(d.key))
            .attr('width', this.width)
            .attr('height', this.yAxisScale.bandwidth())
            .selection()
      );

    this.svg
      .selectAll('.bar-group')
      .data(this.data)
      .join(
        (enter) =>
          enter
            .append('g')
            .attr('class', 'bar-group')
            .attr('transform', (d, i) => {
              return `translate(0, ${this.yAxisScale(d.key)})`;
            }),
        (update) =>
          update
            .transition()
            .duration(1000)
            .attr('transform', (d, i) => {
              return `translate(0, ${this.yAxisScale(d.key)})`;
            })
            .selection()
      );

    this.svg
      .selectAll('.bar-group')
      .selectAll('.bar')
      .data((d, i) => {
        const clone = { ...d };
        delete clone.key;

        const keys = Object.keys(clone);

        const keyData = keys.map(function (key) {
          return { label: key, value: d[key], parentIndex: i };
        });

        return keyData;
      })
      .join(
        (enter) =>
          enter
            .append('rect')
            .attr('class', 'bar')
            .attr('fill', (d) => this.barFill(d, true))
            .attr('data-color', (d) => this.colorRange(d.label))
            .attr('data-parent-index', (d) => d.parentIndex)
            .attr('x', 0)
            .attr('width', 0)
            .attr('y', (d) => this.barScale(d.label))
            .attr('height', this.barScale.bandwidth())
            .call((enter) => {
              return enter
                .attr('pointer-events', 'none')
                .transition()
                .duration(0) // 500
                .attr('width', (d) => this.xAxisScale(d.value))
                .transition()
                .attr('pointer-events', 'auto');
            }),
        (update) =>
          update
            .attr('pointer-events', 'none')
            .transition()
            .duration(1000)
            .attr('width', (d) => this.xAxisScale(d.value))
            .attr('height', this.barScale.bandwidth())
            .attr('y', (d) => this.barScale(d.label))
            .transition()
            .selection()
            .attr('pointer-events', 'auto'),
        (exit) =>
          exit
            .transition()
            .duration(0) // 100
            .selection()
            .attr('pointer-events', 'none')
            .attr('width', 0)
      )
      .on('mouseover', (event, data) => this.barMouseOver(event, data))
      .on('mouseout', (event, data) => this.barMouseOut())
      .on('click', (event, data) => this.barMouseClick(event, data));

    this.updateLegend();

    this.svg.selectAll('.axis').raise();
  }

  updateLegend() {
    // legend
    if (!this.hideLegend) {
      const legendData = { ...this.data[0] };
      delete legendData.key;
      const legendKeys = Object.keys(legendData).map(function (key) {
        return { label: key };
      });

      this.chart
        .select('.legend')
        .selectAll('.legend-item')
        .data(legendKeys)
        .join(
          (enter) => {
            const li = enter.append('li').attr('class', 'legend-item');

            li.insert('span')
              .attr('class', 'legend-key')
              .style('background-color', (d) => this.colorRange(d.label));

            li.insert('span', '.legend-item')
              .attr('class', 'legend-label')
              .html((d) => {
                switch (this.legendLabelFormatType) {
                  case 'number':
                    return this.legendLabelFormat(d.label);

                  case 'time':
                    const parsedTime = d3_isoParse(d.label);
                    return this.legendLabelFormat(parsedTime);

                  default:
                    return d.label;
                }
              });

            return li;
          },
          (update) => {
            update.select('.legend-label').html((d) => {
              switch (this.legendLabelFormatType) {
                case 'number':
                  return this.legendLabelFormat(d.label);

                case 'time':
                  const parsedTime = d3_isoParse(d.label);
                  return this.legendLabelFormat(parsedTime);

                default:
                  return d.label;
              }
            });

            return update;
          },
          (exit) => exit.remove()
        )
        .on('mouseover', (event, data) => this.legendMouseOver(event, data))
        .on('mouseout', () => this.legendMouseOut())
        .on('click', (event, data) => this.legendMouseClick(event, data));
    }
  }

  barMouseOver = (event, data: PbdsDatavizBarGrouped[]) => {
    const node = d3_select(event.currentTarget);

    this.chart.selectAll('.bar-group').selectAll('.bar').classed('inactive', true);

    node.classed('inactive', false).style('fill', node.attr('data-color'));

    this.tooltipShow(event, data);

    this.hovered.emit({ event, data });
  };

  barMouseOut = () => {
    this.chart.selectAll('.bar').classed('inactive', false).style('fill', null);

    this.tooltipHide();
  };

  barMouseClick = (event, data) => {
    this.clicked.emit({ event, data });
  };

  legendMouseOver = (event, data) => {
    this.chart
      .selectAll('.legend-item')
      .filter((d, i) => d.label !== data.label)
      .classed('inactive', true);

    this.chart
      .selectAll('.bar-group')
      .selectAll('.bar')
      .filter((d, i) => d.label !== data.label)
      .classed('inactive', true);

    const bar = this.chart
      .selectAll('.bar-group')
      .selectAll('.bar')
      .filter((d, i) => d.label === data.label)
      .classed('inactive', null);

    const barColor = bar.attr('data-color');

    bar.style('fill', () => barColor);

    this.hovered.emit({ event, data });
  };

  legendMouseOut = () => {
    this.chart.selectAll('.legend-item').classed('inactive', false);

    this.chart.selectAll('.bar-group').selectAll('.bar').classed('inactive', false).style('fill', null);
  };

  legendMouseClick = (event, data) => {
    this.clicked.emit({ event, data });
  };

  private tooltipShow = (event, data) => {
    const dimensions = event.currentTarget.getBoundingClientRect();
    const scroll = this._scroll.getScrollPosition();
    let label;

    switch (this.tooltipLabelFormatType) {
      case 'number':
        label = this.tooltipLabelFormat(data.label);
        break;

      case 'time':
        const parsedTime = d3_isoParse(data.label);
        label = this.tooltipLabelFormat(parsedTime);
        break;

      default:
        label = data.label;
    }

    const value =
      this.tooltipValueFormat === null
        ? `<div class="tooltip-value">${data.value}</div>`
        : `<div class="tooltip-value">${this.tooltipValueFormat(data.value)}</div>`;

    this.tooltip.html(
      `
        ${label}
        ${value}
      `
    );

    const tooltipOffsetWidth = +this.tooltip.node().offsetWidth / 2;
    const tooltipOffsetHeight = +this.tooltip.node().offsetHeight;
    const tooltipTipSize = 8;

    if (this.vertical) {
      this.tooltip.style('top', `${+scroll[1] + +dimensions.top - tooltipOffsetHeight - tooltipTipSize}px`);
      this.tooltip.style('left', `${+scroll[0] + +dimensions.left - tooltipOffsetWidth + +dimensions.width / 2}px`);
    } else {
      this.tooltip.style('top', `${+scroll[1] + +dimensions.top + +dimensions.height / 2 - tooltipOffsetHeight / 2}px`);
      this.tooltip.style('left', `${+scroll[0] + +dimensions.right + tooltipTipSize}px`);
    }

    this.tooltip.style('opacity', 1);
  };

  private tooltipHide = () => {
    this.tooltip.style('opacity', 0);
  };

  private xAxisFormatter = (item) => {
    switch (this.xAxisFormatType) {
      case 'number':
        return this.xAxisFormat(item);

      case 'time':
        const parseDate = d3_isoParse(item);
        return this.xAxisFormat(parseDate);

      default:
        return item;
    }
  };

  private yAxisFormatter = (item) => {
    switch (this.yAxisFormatType) {
      case 'number':
        return this.yAxisFormat(item);

      case 'time':
        const parseDate = d3_isoParse(item);
        return this.yAxisFormat(parseDate);

      default:
        return item;
    }
  };

  private barFill(d, isHorizontal = false) {
    const horizontal = isHorizontal ? '-horizontal' : '';
    const path = this._location.path();
    const url = this._location.prepareExternalUrl(path);
    const colorRange = this.colorRange(d.label);

    if (this.gradient) {
      return `url(${url}#gradient${horizontal}-${colorRange.substr(1)})`;
    } else {
      return colorRange;
    }
  }
}

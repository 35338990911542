import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccordionService {
  public subject = new Subject<void>();
  currentSubject = this.subject.asObservable;

  public category = new Subject<any>();
  currentCategory = this.category.asObservable;

  sendCall() {
    this.subject.next();
  }

  getCall(): Observable<any> {
    return this.subject.asObservable();
  }

  sendCategory(category) {
    this.category.next(category);
  }
  getCategory(): Observable<any> {
    return this.category.asObservable();
  }
}

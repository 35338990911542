import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorIconAlertComponent } from './error-icon-alert.component';
import { ErrorIconClockComponent } from './error-icon-clock.component';
import { ErrorIconLockComponent } from './error-icon-lock.component';
import { ErrorIconToolsComponent } from './error-icon-tools.component';
import { ErrorIconXComponent } from './error-icon-x.component';

@NgModule({
  declarations: [
    ErrorIconAlertComponent,
    ErrorIconClockComponent,
    ErrorIconXComponent,
    ErrorIconToolsComponent,
    ErrorIconLockComponent
  ],
  imports: [CommonModule],
  exports: [
    ErrorIconAlertComponent,
    ErrorIconClockComponent,
    ErrorIconXComponent,
    ErrorIconToolsComponent,
    ErrorIconLockComponent
  ]
})
export class PbdsErrorIconsModule {}

import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';

import { PbdsDaterangeLocale } from './daterange-popover.interfaces';

@Injectable({
  providedIn: 'root'
})
export class PbdsDaterangeService {
  private locale = this.localeId.toLowerCase();

  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  setLocale(locale: PbdsDaterangeLocale) {
    this.locale = `${locale.language}-${locale.country}`.toLowerCase();

    // set the angular LOCALE_ID dynamically for ng-bootstrap datepicker
    registerLocaleData(locale.locale, this.locale);
  }

  getCurrentLocale() {
    return this.locale;
  }
  openPopover(){
    
  }
}

import { Injectable } from '@angular/core';

import { format as d3_format, timeFormat as d3_timeFormat } from 'd3';

@Injectable({
  providedIn: 'root'
})
export class PbdsDatavizService {
  private colors = {
    classic: {
      full: [
        '#B70077',
        '#0384D4',
        '#EE6B0B',
        '#A319B1',
        '#11A611',
        '#1BB9FF',
        '#495A9C',
        '#EDB700',
        '#8B98C8',
        '#E6C49C',
        '#CCB8CE',
        '#9B9B9B'
      ],
      mono: ['#001D56', '#003296', '#4B74C5', '#89A1D0', '#A3BCEE', '#C9D7F3'] // blue
    },
    twilight: {
      full: [
        '#A319B1',
        '#11A611',
        '#1BB9FF',
        '#EE6B0B',
        '#B70077',
        '#0384D4',
        '#495A9C',
        '#EDB700',
        '#8B98C8',
        '#E6C49C',
        '#CCB8CE',
        '#9B9B9B'
      ],
      mono: ['#05395C', '#0A5B92', '#0072B8', '#5DA9DC', '#A5D4F3', '#D1EDFF'] // light blue
    },
    ocean: {
      full: [
        '#0384D4',
        '#B70077',
        '#1BB9FF',
        '#495A9C',
        '#EDB700',
        '#A319B1',
        '#EE6B0B',
        '#11A611',
        '#8B98C8',
        '#E6C49C',
        '#CCB8CE',
        '#9B9B9B'
      ],
      mono: ['#394B4D', '#3A6B6E', '#14767D', '#99BFC2', '#C9E6E8', '#DEECED'] // blue-green
    },
    sunset: {
      full: [
        '#B70077',
        '#EE6B0B',
        '#1BB9FF',
        '#EDB700',
        '#11A611',
        '#A319B1',
        '#0384D4',
        '#CCB8CE',
        '#495A9C',
        '#E6C49C',
        '#8B98C8',
        '#9B9B9B'
      ],
      mono: ['#31254A', '#50248F', '#7945C4', '#9A79E2', '#C4A8FF', '#D9C7FF'] // purple
    }
  };

  constructor() {}

  getColors = (mono = false, theme = 'classic') => {
    return mono ? this.colors[theme].mono : this.colors[theme].full;
  };

  createGradientDefs = (svg, mono = false, theme = 'classic', vertical = true) => {
    const colors = mono ? [this.colors[theme].mono[2]] : this.colors[theme].full;

    for (let i = 0; i < colors.length; i++) {
      const color = mono ? this.colors[theme].mono[2] : this.colors[theme].full[i];
      let gradient;

      if (vertical) {
        gradient = svg
          .append('defs')
          .append('linearGradient')
          .attr('id', `gradient-${color.replace('#', '')}`)
          .attr('x1', '0')
          .attr('y1', '0')
          .attr('x2', '0')
          .attr('y2', '1')
          .attr('spreadMethod', 'pad');

        gradient
          .append('stop')
          .attr('offset', '0%')
          .attr('stop-color', color)
          .attr('stop-opacity', '1'); // top of bar will be full opacity

        gradient
          .append('stop')
          .attr('offset', '100%')
          .attr('stop-color', color)
          .attr('stop-opacity', '.3'); // bottom of bar will be .3 opacity
      } else {
        gradient = svg
          .append('defs')
          .append('linearGradient')
          .attr('id', `gradient-horizontal-${color.replace('#', '')}`)
          .attr('x1', '1')
          .attr('y1', '0')
          .attr('x2', '0')
          .attr('y2', '0')
          .attr('spreadMethod', 'pad');

        gradient
          .append('stop')
          .attr('offset', '0%')
          .attr('stop-color', color)
          .attr('stop-opacity', '1'); // top of bar will be full opacity

        gradient
          .append('stop')
          .attr('offset', '100%')
          .attr('stop-color', color)
          .attr('stop-opacity', '.3'); // bottom of bar will be .3 opacity
      }
    }

    return colors;
  };

  createGlowFilter = svg => {
    // add a new definition
    const glow = svg
      .append('defs')
      .append('filter')
      .attr('id', 'glow')
      .attr('width', '200%')
      .attr('height', '200%');

    glow
      .append('feGaussianBlur')
      .attr('in', 'SourceGraphic')
      .attr('stdDeviation', 4);

    // build two dropshadows with different intensities
    const feOffsets = [
      {
        dy: 2,
        slope: 0.2
      },
      {
        dy: 5,
        slope: 0.05
      }
    ];

    for (let i = 0; i < feOffsets.length; i++) {
      glow
        .append('feOffset')
        .attr('result', 'offsetBlur' + i)
        .attr('dx', 0)
        .attr('dy', feOffsets[i].dy);
    }

    for (let y = 0; y < feOffsets.length; y++) {
      glow
        .append('feComponentTransfer')
        .attr('result', 'coloredBlur' + y)
        .attr('in', 'offsetBlur' + y)
        .append('feFuncA')
        .attr('type', 'linear')
        .attr('slope', feOffsets[y].slope);
    }

    const merge = glow.append('feMerge');

    merge.append('feMergeNode').attr('in', 'SourceGraphic');

    for (let x = 0; x < feOffsets.length; x++) {
      merge.append('feMergeNode').attr('in', 'coloredBlur' + x);
    }
  };

  d3Format(type: string, string: string) {
    let format;

    switch (type) {
      case 'number':
        format = d3_format(string);
        break;
      case 'time':
        format = d3_timeFormat(string);
        break;
      default:
        format = null;
        break;
    }

    return format;
  }
}

import { Component, ContentChild, ElementRef, HostBinding, Input, OnInit } from '@angular/core';

import { PbdsDatavizMetricIndicatorComponent } from './dataviz-metric-indicator.component';

@Component({
  selector: 'pbds-dataviz-metric-block',
  template: `
    <div class="metric-block-inner">
      <div class="metric-block-heading" *ngIf="heading">
        {{ heading }}
        <i
          class="pbi-icon-mini pbi-info-circle-open ms-1 align-middle"
          *ngIf="infoMessage"
          ngbTooltip="{{ infoMessage }}"
          container="body"
        ></i>
      </div>
      <div class="metric-block-data-block">
        <div class="metric-block-contents">
          <div class="metric-block-value" [ngClass]="{ 'me-0': hideValueMargin }">
            {{ value
            }}<span [ngClass]="{ 'metric-block-unit': isUnit, 'metric-block-percentage': isPercentUnit }">{{
              unit
            }}</span>
          </div>

          <div>
            <ng-content select="pbds-dataviz-metric-indicator"></ng-content>
          </div>
          <div class="metric-block-description" *ngIf="description">{{ description }}</div>
        </div>
        <ng-content select="pbds-dataviz-sparkline"></ng-content>
      </div>
    </div>
  `,
  styles: [],
})
export class PbdsDatavizMetricBlockComponent implements OnInit {
  @Input()
  class = '';

  @Input()
  heading: string = null;

  @Input()
  value = 0;

  @Input()
  unit: string = null;

  @Input()
  description: string = null;

  @Input()
  centered = false;

  @Input()
  centeredText = false;

  @Input()
  vertical = false;

  @Input()
  infoMessage: string | null = null;

  hideValueMargin = false;
  isPercentUnit = false;
  isUnit = false;

  @HostBinding('class')
  get hostClasses() {
    return [
      'metric-block',
      this.centered ? 'metric-block-centered' : '',
      this.centeredText ? 'metric-block-centered-text' : '',
      this.vertical ? 'metric-block-vertical' : '',
      this.class,
    ].join(' ');
  }

  @ContentChild(PbdsDatavizMetricIndicatorComponent, { static: true }) indicatorRef: ElementRef;

  ngOnInit() {
    if (!this.indicatorRef) {
      this.hideValueMargin = true;
    }

    if (this.unit !== '%' && this.unit !== null) {
      this.isUnit = true;
    } else if (this.unit === '%') {
      this.isPercentUnit = true;
    }
  }
}

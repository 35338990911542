import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { Location, ViewportScroller } from '@angular/common';

import { sum as d3_sum } from 'd3-array';
import { axisBottom as d3_axisBottom } from 'd3-axis';
import { easeLinear as d3_easeLinear } from 'd3-ease';
import { format as d3_format } from 'd3-format';
import { scaleLinear as d3_scaleLinear, scaleOrdinal as d3_scaleOrdinal } from 'd3-scale';
import { select as d3_select } from 'd3-selection';
import { isoParse as d3_isoParse, timeFormat as d3_timeFormat } from 'd3-time-format';

import { PbdsDatavizBarSingleHorizontal, PbdsDatavizBarSingleHorizontalCompare } from './dataviz.interfaces';
import { PbdsDatavizService } from './dataviz.service';

@Component({
  selector: 'pbds-dataviz-bar-single-horizontal',
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PbdsDatavizBarSingleHorizontalComponent implements OnInit, OnDestroy, OnChanges {
  @HostBinding('class.pbds-chart')
  chartClass = true;

  @HostBinding('class.pbds-chart-bar-single-horizontal')
  singleStackedBarClass = true;

  @Input()
  data: Array<PbdsDatavizBarSingleHorizontal | PbdsDatavizBarSingleHorizontalCompare>;

  @Input()
  width = 300;

  @Input()
  height = 40;

  @Input()
  nullValueText = 'No data available';

  @Input()
  percentage = false;

  @Input()
  marginTop = 10;

  @Input()
  marginRight = 20;

  @Input()
  marginBottom = 35;

  @Input()
  marginLeft = 15;

  @Input()
  barMargin = 2;

  @Input()
  hideXAxis = false;

  @Input()
  xAxisTicks = 6;

  @Input()
  xAxisTitle: string | null = null;

  @Input()
  xAxisFormatType: 'number' = null;

  @Input()
  xAxisFormatString = '';

  @Input()
  xAxisTickLabelSuffix = '';

  @Input()
  hideXGrid = false;

  @Input()
  hideLegend = false;

  @Input()
  hideLegendTooltip = true;

  @Input()
  legendWidth = 105 + 28; // hardcoded legend width + left margin, do not document until feedback

  @Input()
  legendPosition: 'right' | 'bottom' = 'bottom';

  @Input()
  legendLabelFormatType: 'number' | 'time' = null;

  @Input()
  legendLabelFormatString = '';

  @Input()
  hideTooltip = false;

  @Input()
  tooltipLabelFormatType: 'number' | 'time' = null;

  @Input()
  tooltipLabelFormatString = '';

  @Input()
  tooltipDateFormatString = '%b %e, %Y';

  @Input()
  tooltipValueFormatType: 'number' = null;

  @Input()
  tooltipValueFormatString = '';

  @Input()
  tooltipValueSuffix = '';

  @Input()
  tooltipPercentFormatString = '.2%';

  @Input()
  compareChangeFormatString = '.2%';

  @Input()
  monochrome = false;

  @Input()
  customColor: boolean = false;

  @Input()
  colorsArray = [];

  @Input()
  theme: 'classic' | 'ocean' | 'sunset' | 'twilight';

  @Input() gradient = true;

  @Output()
  hovered: EventEmitter<object> = new EventEmitter<object>();

  @Output()
  clicked: EventEmitter<object> = new EventEmitter<object>();

  private isSingleData = false;
  private isCompare = false;
  private chart;
  private svg;
  private margin;
  private colorRange;
  private barPadding = 40;
  private xAxisCall;
  private xAxis;
  private xAxisScale;
  private xAxisTickSize: number;
  private xAxisTickSizeOuter: number;
  private xAxisFormat;
  private xAxisTitleMargin: number;
  private hideXAxisDomain: boolean;
  private hideXAxisZero: boolean;
  private hideXAxisTicks: boolean;
  private xGrid;
  private xGridCall;
  private legendLabelFormat;
  private tooltip;
  private tooltipLabelFormat;
  private tooltipValueFormat;
  private tooltipDateFormat;
  private tooltipPercentFormat;
  private tooltipCompareChangeFormat;

  constructor(
    private _dataviz: PbdsDatavizService,
    private _element: ElementRef,
    private _scroll: ViewportScroller,
    private _location: Location,
  ) {}

  ngOnInit() {
    this.height = +this.height + this.barPadding;

    this.margin = {
      top: +this.marginTop,
      right: +this.marginRight,
      bottom: +this.marginBottom,
      left: +this.marginLeft,
    };

    this.isSingleData = this.data.length === 1 ? true : false;
    this.isCompare = Object.keys(this.data[0]).includes('compareValue');

    // create formatters
    this.xAxisFormat = this._dataviz.d3Format(this.xAxisFormatType, this.xAxisFormatString);
    this.legendLabelFormat = this._dataviz.d3Format(this.legendLabelFormatType, this.legendLabelFormatString);
    this.tooltipValueFormat = this._dataviz.d3Format(this.tooltipValueFormatType, this.tooltipValueFormatString);
    this.tooltipDateFormat = d3_timeFormat(this.tooltipDateFormatString);
    this.tooltipPercentFormat = d3_format(this.tooltipPercentFormatString);
    this.tooltipCompareChangeFormat = d3_format(this.compareChangeFormatString);

    // defaults for all chart types
    this.hideXAxisZero = false;
    this.hideXAxisDomain = true;
    this.hideXAxisTicks = true;
    this.xAxisTickSize = 8;
    this.xAxisTickSizeOuter = 0;
    this.xAxisTitleMargin = this.xAxisTitle ? 30 : 0;

    if (!this.hideLegend && this.legendPosition === 'right') {
      this.width = +this.width - +this.legendWidth;
    }

    // create the chart
    this.chart = d3_select(this._element.nativeElement).attr('aria-hidden', 'true');

    // create chart svg
    this.svg = this.chart
      .append('svg')
      .attr('width', () => {
        return +this.width + this.margin.left + this.margin.right;
      })
      .attr('height', +this.height + this.margin.top + this.margin.bottom + this.xAxisTitleMargin)
      .attr('class', 'img-fluid')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', () => {
        return `-${this.margin.left} -${this.margin.top} ${+this.width + this.margin.left + this.margin.right} ${
          +this.height + this.margin.top + this.margin.bottom + this.xAxisTitleMargin
        }`;
      });

    // TOOLTIP
    if (!this.hideTooltip) {
      this.tooltip = d3_select('body')
        .append('div')
        .attr('class', 'pbds-tooltip south')
        .classed('pbds-tooltip-compare', this.isCompare)
        .style('opacity', 0)
        .attr('aria-hidden', 'true'); // hide tooltip for accessibility
    }

    // add legend classes
    if (!this.hideLegend && this.data.length > 1) {
      this.chart.classed('pbds-chart-legend-bottom', this.legendPosition === 'bottom' ? true : false);
      this.chart.append('ul').attr('class', `legend legend-${this.legendPosition}`);
    }

    // X AXIS
    this.xAxisScale = d3_scaleLinear()
      .domain([0, Math.ceil(d3_sum(this.data, (d: any) => d.value))])
      .range([0, +this.width]);

    this.xAxisCall = d3_axisBottom(this.xAxisScale)
      // .tickValues([0, d3_sum(this.data, (d: any) => d.value)])
      .ticks(this.xAxisTicks)
      .tickSize(this.xAxisTickSize)
      .tickSizeOuter(this.xAxisTickSizeOuter)
      .tickFormat(this.xAxisFormatter);

    this.xAxis = this.svg
      .append('g')
      .attr('class', 'axis axis-x')
      .attr('transform', `translate(0, ${this.height})`)
      .classed('axis-hidden', this.hideXAxis)
      .classed('axis-zero-hidden', this.hideXAxisZero)
      .classed('axis-domain-hidden', this.hideXAxisDomain)
      .classed('axis-ticks-hidden', this.hideXAxisTicks);
    // .call(this.xAxisCall);

    // X GRIDLINES
    if (!this.hideXGrid) {
      this.xGridCall = d3_axisBottom(this.xAxisScale).tickSize(-this.height);

      this.xGrid = this.svg
        .append('g')
        .attr('class', 'grid grid-x')
        .classed('grid-zero-hidden', this.hideXAxisZero)
        .attr('transform', `translate(0, ${this.height})`)
        .call(this.xGridCall);
    }

    if (this.xAxisTitle) {
      this.svg
        .append('text')
        .attr('class', 'axis-title')
        .attr('text-anchor', 'middle')
        .attr(
          'transform',
          `translate(${this.svg.attr('width') / 2 - this.margin.left / 2 - this.margin.right / 2}, ${
            this.height + this.margin.top + (this.hideXAxis ? 20 : 40)
          })`,
        )
        // .attr('x', this.width / 2 - this.margin.left)
        // .attr('y', this.height + this.margin.top + (!this.hideXAxis ? 40 : 0))
        .text(this.xAxisTitle);
    }

    // build color ranges
    let colors;

    if (this.isSingleData) {
      colors = this._dataviz.createGradientDefs(this.svg, this.monochrome, this.theme, false);
    } else if (this.monochrome) {
      colors = this._dataviz.getColors(this.monochrome, this.theme).reverse();
    } else {
      colors = this.customColor ? this.colorsArray : this._dataviz.getColors(this.monochrome, this.theme);
    }

    this.colorRange = d3_scaleOrdinal().range(colors);

    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      this.updateChart();
    }
  }

  ngOnDestroy() {
    if (this.tooltip) this.tooltip.remove();
  }

  updateChart() {
    this.isSingleData = this.data.length === 1 ? true : false;
    this.isCompare = Object.keys(this.data[0]).includes('compareValue');

    const sumValues = d3_sum(this.data, (d: any) => d.value);
    const isLastBarZero =
      this.data[this.data.length - 1].value === 0 || this.data[this.data.length - 1].value === null ? true : false;

    let lastBarZeroCount = 0;
    const cloneData = [...this.data];
    let isLast = false;

    cloneData.reverse().forEach((value, index, array) => {
      if ((value.value === 0 || value.value === null) && !isLast) {
        lastBarZeroCount++;
      } else {
        isLast = true;
      }
    });

    if (this.percentage && !this.isSingleData) {
      this.xAxisScale.domain([0, sumValues]).range([0, +this.width]);
      this.xAxisCall.tickValues([0, sumValues * 0.25, sumValues * 0.5, sumValues * 0.75, sumValues]);
      this.xAxis.call(this.xAxisCall);

      this.xGridCall.tickValues([0, sumValues * 0.25, sumValues * 0.5, sumValues * 0.75, sumValues]);
      this.xGrid.call(this.xGridCall);

      this.svg
        .select('.axis-x')
        .selectAll('text')
        .html((d, i) => {
          const format = d3_format('.0%');
          return format(i * 0.25);
        });
    } else if (this.percentage && this.isSingleData) {
      this.xAxisScale.domain([0, 1.0]).range([0, +this.width]);
      this.xAxisCall.tickValues([0, 0.25, 0.5, 0.75, 1.0]);
      this.xAxis.call(this.xAxisCall);

      this.xGridCall.tickValues([0, 0.25, 0.5, 0.75, 1.0]);
      this.xGrid.call(this.xGridCall);

      this.svg
        .select('.axis-x')
        .selectAll('text')
        .html((d, i) => {
          const format = d3_format('.0%');
          return format(i * 0.25);
        });
    } else {
      this.xAxisScale.domain([0, Math.ceil(sumValues)]).range([0, +this.width]);
      this.xGridCall.tickValues(this.xAxisScale.ticks().filter((n) => Number.isInteger(n))); // remove decimal grid values

      this.xAxis.transition().duration(1000).call(this.xAxisCall);

      // update the grids
      if (!this.hideXGrid) {
        this.xGrid.transition().duration(1000).call(this.xGridCall);
      }
    }

    this.svg
      .selectAll('.bar')
      .data(this.data)
      .join(
        (enter) =>
          enter
            .append('rect')
            .attr('class', 'bar')
            .attr('width', 0)
            .attr('height', () => {
              return this.height - this.barPadding;
            })
            .attr('fill', (d) => this.barFill(d))
            .attr('y', () => {
              return this.barPadding / 2;
            })
            .attr('x', (d, i) => {
              return this.data.slice(0, i).reduce((acc, item) => {
                // console.log(acc, item, acc + this.xAxisScale(item.value) + this.barMargin);
                return +acc + +this.xAxisScale(item.value);
              }, 1);
            })
            .attr('pointer-events', 'none')
            .call((enter) => {
              return (
                enter
                  .transition()
                  // .duration(0)
                  .delay((d, i) => i * 250)
                  .ease(d3_easeLinear)
                  .attr('width', (d, i) => {
                    // debugger;
                    if (i === this.data.length - lastBarZeroCount - 1 && isLastBarZero) {
                      return this.xAxisScale(d.value);
                    } else if (i !== this.data.length - 1) {
                      let width = this.xAxisScale(d.value) - +this.barMargin;
                      width = Math.sign(width) === -1 ? 0 : width; // handle negative values
                      return width;
                    } else {
                      return this.xAxisScale(d.value);
                    }
                  })
                  .transition()
                  .attr('pointer-events', 'auto')
              );
            }),
        (update) =>
          update
            .attr('pointer-events', 'none')
            .transition()
            .duration(1000)
            .attr('width', (d, i) => {
              // debugger;
              if (d.value === null || d.value === 0) {
                return this.xAxisScale(0);
              } else if (i === this.data.length - 1) {
                return this.xAxisScale(d.value);
              } else {
                return this.xAxisScale(d.value) - this.barMargin;
              }
            })
            .attr('x', (d, i) => {
              return this.data.slice(0, i).reduce((acc, item) => {
                return acc + +this.xAxisScale(item.value);
              }, 0);
            })
            .transition()
            .selection()
            .attr('pointer-events', 'auto'),
        (exit) => exit.transition().selection().attr('pointer-events', 'none').remove(),
      )
      .datum((d, i) => {
        return { data: this.data, index: i };
      })
      .on('mouseover', (event, data) => this.barMouseOver(event, data))
      .on('mouseout', (event, data) => this.barMouseOut())
      .on('click', (event, data) => this.barMouseClick(event, data));

    if (!this.hideLegend) {
      this.chart
        .select('.legend')
        .selectAll('.legend-item')
        .data(this.data)
        .join(
          (enter) => {
            const li = enter.append('li').attr('class', 'legend-item').classed('align-items-start', this.isCompare);

            li.insert('span')
              .attr('class', 'legend-key')
              .style('background-color', (d) => this.colorRange(d.label))
              .classed('mt-1', this.isCompare);

            li.insert('span')
              .attr('class', 'legend-description')
              .classed('d-flex', this.isCompare)
              .classed('flex-column', this.isCompare);

            li.select('.legend-description')
              .insert('span')
              .attr('class', 'legend-label')
              .html((d) => {
                switch (this.legendLabelFormatType) {
                  case 'number':
                    return this.legendLabelFormat(d.label);

                  case 'time':
                    const parsedTime = d3_isoParse(d.label);
                    return this.legendLabelFormat(parsedTime);

                  default:
                    return d.label;
                }
              });

            li.select('.legend-description')
              .insert('div')
              .attr('class', 'legend-change')
              .classed('d-none', !this.isCompare);

            li.select('.legend-change').html((d) => {
              return `<div class="metric-block-indicator ${d.compareChangeDirection} ${
                d.compareChangeInverse ? 'inverse' : ''
              } mt-1"><span>${this.tooltipCompareChangeFormat(d.compareChangeValue)}</span></div>`;
            });

            return li;
          },
          (update) => {
            update.classed('align-items-start', this.isCompare);
            update.select('.legend-key').classed('mt-1', this.isCompare);
            update.select('.legend-change').classed('d-none', !this.isCompare);

            if (this.isCompare) {
              update.select('.legend-change').html((d) => {
                return `<div class="metric-block-indicator ${d.compareChangeDirection} ${
                  d.compareChangeInverse ? 'inverse' : ''
                } mt-1"><span>${this.tooltipCompareChangeFormat(d.compareChangeValue)}</span></div>`;
              });
            }

            update.select('.legend-label').html((d) => {
              switch (this.legendLabelFormatType) {
                case 'number':
                  return this.legendLabelFormat(d.label);

                case 'time':
                  const parsedTime = d3_isoParse(d.label);
                  return this.legendLabelFormat(parsedTime);

                default:
                  return d.label;
              }
            });

            return update;
          },
          (exit) => exit.remove(),
        )
        .datum((d, i) => {
          return { data: this.data, index: i };
        })
        .on('mouseover', (event, data) => this.legendMouseOver(event, data))
        .on('mouseout', () => this.legendMouseOut())
        .on('click', (event, data) => this.legendMouseClick(event, data));
    }
  }

  barMouseOver = (event, data) => {
    const node = d3_select(event.currentTarget);

    this.chart.selectAll('.bar').classed('inactive', true);

    node.classed('inactive', false);

    this.chart
      .selectAll('.legend-item')
      .filter((d, i) => {
        // debugger;
        return i !== data.index;
      })
      .classed('inactive', true);

    this.tooltipShow(event, data.data[data.index]);

    this.hovered.emit({ event, data });
  };

  barMouseOut = () => {
    this.chart.selectAll('.bar').classed('inactive', false).style('fill', null);

    this.chart.selectAll('.legend-item').classed('inactive', false);

    this.tooltipHide();
  };

  barMouseClick = (event, data) => {
    this.clicked.emit({ event, data });
  };

  private tooltipShow = (event, data, node?) => {
    const dimensions = node ? node.getBoundingClientRect() : event.currentTarget.getBoundingClientRect();
    const scroll = this._scroll.getScrollPosition();

    const percentage = data.value / d3_sum(this.data, (d: any) => d.value);
    const comparePercentage = data.compareValue / d3_sum(this.data, (d: any) => d.compareValue);

    let tooltipLabel = ``;
    let tooltipCompareDaterangeMargin = ``;
    let tooltipCompareDaterange = ``;
    let tooltipCompareValue = ``;
    let tooltipDaterangeMargin = ``;
    let tooltipDaterange = ``;
    let tooltipValue = `${this.nullValueText}`;
    let tooltipIndicator = '';

    // tooltip label
    if (!this.isSingleData) {
      this.tooltip.classed('pbds-tooltip-compare', null);

      switch (this.tooltipLabelFormatType) {
        case 'number':
          tooltipLabel = this.tooltipLabelFormat(data.label);
          break;

        case 'time':
          const parsedTime = d3_isoParse(data.label);
          tooltipLabel = this.tooltipLabelFormat(parsedTime);
          break;

        default:
          tooltipLabel = data.label;
      }
    }

    // tooltip compare daterange
    if (this.isCompare && data.compareStartDate && data.compareEndDate) {
      this.tooltip.classed('pbds-tooltip-compare', this.isCompare);
      tooltipCompareDaterangeMargin = `mt-2`;

      tooltipCompareDaterange = `${this.tooltipDateFormat(
        d3_isoParse(data.compareStartDate),
      )} - ${this.tooltipDateFormat(d3_isoParse(data.compareEndDate))}`;
    }

    // tooltip compare value
    if (this.percentage && this.isCompare && data.compareValue) {
      tooltipCompareValue =
        this.tooltipValueFormat === null
          ? `${this.tooltipPercentFormat(comparePercentage)} (${data.comparveValue}${this.tooltipValueSuffix})`
          : `${this.tooltipPercentFormat(comparePercentage)} (${this.tooltipValueFormat(data.compareValue)}${
              this.tooltipValueSuffix
            })`;
    } else if (this.isCompare && data.compareValue !== null) {
      tooltipCompareValue =
        this.tooltipValueFormat === null
          ? `${data.compareValue}${this.tooltipValueSuffix} (${this.tooltipPercentFormat(comparePercentage)})`
          : `${this.tooltipValueFormat(data.compareValue)}${this.tooltipValueSuffix} (${this.tooltipPercentFormat(
              comparePercentage,
            )})`;
    } else if (this.isCompare && data.compareValue === null) {
      tooltipCompareValue = `${this.nullValueText}`;
    }

    // tooltip daterange
    if (data.startDate && data.endDate) {
      tooltipDaterange = `${this.tooltipDateFormat(d3_isoParse(data.startDate))} - ${this.tooltipDateFormat(
        d3_isoParse(data.endDate),
      )}`;
    }

    //tooltip daterange margin
    if (tooltipLabel !== '') {
      tooltipDaterangeMargin = `mt-2`;
    }

    // tooltip value
    if (this.isSingleData && this.percentage && data.value) {
      tooltipValue = this.tooltipValueFormat === null ? `${data.value}` : `${this.tooltipValueFormat(data.value)}`;
    } else if (this.isSingleData && data.value !== null) {
      tooltipValue =
        this.tooltipValueFormat === null
          ? `${data.value}${this.tooltipValueSuffix}`
          : `${this.tooltipValueFormat(data.value)}${this.tooltipValueSuffix}`;
    } else if (!this.isSingleData && this.percentage && data.value !== null) {
      tooltipValue =
        this.tooltipValueFormat === null
          ? `${this.tooltipPercentFormat(percentage)} (${data.value}${this.tooltipValueSuffix})`
          : `${this.tooltipPercentFormat(percentage)} (${this.tooltipValueFormat(data.value)}${
              this.tooltipValueSuffix
            })`;
    } else if (!this.isSingleData && data.value !== null) {
      tooltipValue =
        this.tooltipValueFormat === null
          ? `${data.value}${this.tooltipValueSuffix} (${this.tooltipPercentFormat(percentage)})`
          : `${this.tooltipValueFormat(data.value)}${this.tooltipValueSuffix} (${this.tooltipPercentFormat(
              percentage,
            )})`;
    }

    // tooltip metric indicator
    if (!this.isSingleData && this.isCompare && data.value !== null && data.compareValue !== null) {
      tooltipIndicator = `<div class="metric-block-indicator ${data.compareChangeDirection} ${
        data.compareChangeInverse ? 'inverse' : ''
      } ms-2"><span>${this.tooltipCompareChangeFormat(data.compareChangeValue)}</span></div>`;
    }

    this.tooltip.html(() => {
      return `
        <div class="tooltip-label fw-bold">${tooltipLabel}</div>
        <div class="${tooltipCompareDaterangeMargin}">${tooltipCompareDaterange}</div>
        <div class="tooltip-value fw-bold">${tooltipCompareValue}</div>
        <div class="${tooltipDaterangeMargin}">${tooltipDaterange}</div>
        <div class="tooltip-value"><span class="fw-bold">${tooltipValue}</span> <span>${tooltipIndicator}</span></div>
      `;
    });

    const tooltipOffsetWidth = +this.tooltip.node().offsetWidth / 2;
    const tooltipOffsetHeight = +this.tooltip.node().offsetHeight;
    const tooltipTipSize = 8;

    this.tooltip.style('top', `${+scroll[1] + +dimensions.top - tooltipOffsetHeight - tooltipTipSize}px`);

    if (this.data.length > 1) {
      this.tooltip.style('left', `${+scroll[0] + +dimensions.left - tooltipOffsetWidth + +dimensions.width / 2}px`);
    } else {
      this.tooltip.style('left', `${+scroll[0] - tooltipOffsetWidth + +dimensions.right}px`);
    }

    this.tooltip.style('opacity', 1);
  };

  private tooltipHide = () => {
    this.tooltip.style('opacity', 0);
  };

  legendMouseOver = (event, data) => {
    if (!this.hideLegendTooltip) {
      const barHover = this.svg
        .selectAll('.bar')
        .filter((d, i) => i === data.index)
        .node();

      this.tooltipShow(event, data.data[data.index], barHover);
    }

    this.chart
      .selectAll('.legend-item')
      .filter((d, i) => i !== data.index)
      .classed('inactive', true);

    this.chart
      .selectAll('.bar')
      .filter((d, i) => i !== data.index)
      .classed('inactive', true);

    // this.chart
    //   .selectAll('.bar')
    //   .filter((d, i) => i === data.index)
    //   .classed('inactive', null);

    this.hovered.emit({ event, data });
  };

  legendMouseOut = () => {
    this.chart.selectAll('.legend-item').classed('inactive', false);

    this.chart.selectAll('.bar').classed('inactive', false).style('fill', null);

    // hide tooltip for zero/null values
    this.tooltipHide();
  };

  legendMouseClick = (event, data) => {
    this.clicked.emit({ event, data });
  };

  private xAxisFormatter = (item) => {
    switch (this.xAxisFormatType) {
      case 'number':
        return `${this.xAxisFormat(item)}${this.xAxisTickLabelSuffix}`;

      default:
        return `${item}${this.xAxisTickLabelSuffix}`;
    }
  };

  private barFill(d) {
    const path = this._location.path();
    const url = this._location.prepareExternalUrl(path);
    const colorRange = this.colorRange(d.label);

    if (this.gradient && this.isSingleData) {
      return `url(${url}#gradient-horizontal-${colorRange.substr(1)})`;
    } else {
      return colorRange;
    }
  }
}

import {
  Component,
  OnInit,
  Input,
  ElementRef,
  HostBinding,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';

import { select as d3_select, pointer as d3_pointer } from 'd3-selection';
import { scaleOrdinal as d3_scaleOrdinal } from 'd3-scale';
import { pie as d3_pie, arc as d3_arc } from 'd3-shape';
import { interpolate as d3_interpolate } from 'd3-interpolate';
import { format as d3_format } from 'd3-format';
import { isoParse as d3_isoParse } from 'd3-time-format';

import { PbdsDatavizService } from './dataviz.service';
import { PbdsDatavizPie } from './dataviz.interfaces';

@Component({
  selector: 'pbds-dataviz-pie',
  template: ``,
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PbdsDatavizPieComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class.pbds-chart')
  chartClass = true;

  @HostBinding('class.pbds-chart-pie')
  pieClass = true;

  @Input()
  data: Array<PbdsDatavizPie>;

  @Input()
  width = 300;

  @Input()
  type: 'pie' | 'donut' = 'pie';

  @Input()
  monochrome = false;

  @Input()
  legendLabelFormatType: 'time' = null;

  @Input()
  legendLabelFormatString = '';

  @Input()
  legendValueFormatString = '';

  @Input()
  tooltipLabelFormatType: 'time' = null;

  @Input()
  tooltipLabelFormatString = '';

  @Input()
  tooltipLabelSuffix = '';

  @Input()
  tooltipValueFormatString = '';

  @Input()
  theme;

  @Input()
  customColor :boolean = false;

  @Input()
  colorsArray=[];

  @Output()
  hovered: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clicked: EventEmitter<any> = new EventEmitter<any>();

  private currentData = [];
  private height;
  private chart;
  private margin;
  private colors;
  private colorRange;
  private arc;
  private arcZoom;
  private svg;
  private pie;
  private legendLabelFormat;
  private legendValueFormat;
  private innerRadius;
  private anglePad;
  private outerRadius;
  private tooltip;
  private tooltipLabelFormat;
  private tooltipValueFormat;

  constructor(private _dataviz: PbdsDatavizService, private _element: ElementRef) {}

  ngOnInit() {
    this.margin = { top: 10, right: 10, bottom: 10, left: 10 };
    this.width = this.width - this.margin.left - this.margin.right;
    this.height = this.width - this.margin.top - this.margin.bottom;
    this.colors = (this.customColor && !this.monochrome) ? this.colorsArray : this._dataviz.getColors(this.monochrome, this.theme);
    this.innerRadius = Math.min(this.width, this.height) / 2.5;
    this.outerRadius = Math.min(this.width, this.height) / 2;
    this.arcZoom = 10;
    this.anglePad = 0.02;
    this.legendValueFormat = d3_format(this.legendValueFormatString);
    this.tooltipValueFormat = d3_format(this.tooltipValueFormatString);

    // create formatters
    this.legendLabelFormat = this._dataviz.d3Format(this.legendLabelFormatType, this.legendLabelFormatString);
    this.tooltipLabelFormat = this._dataviz.d3Format(this.tooltipLabelFormatType, this.tooltipLabelFormatString);

    this.colorRange = d3_scaleOrdinal()
      .range(this.colors)
      .domain(this.data.map((c) => c.label));

    if (this.type === 'pie') {
      this.innerRadius = 0;
      this.anglePad = 0;
    }

    this.pie = d3_pie()
      .padAngle(this.anglePad)
      .value((d: any) => d.value)
      .sort(null);

    this.arc = d3_arc().padRadius(this.outerRadius).innerRadius(this.innerRadius);

    this.chart = d3_select(this._element.nativeElement).attr('aria-hidden', 'true');

    this.svg = this.chart
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('class', 'img-fluid')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr(
        'viewBox',
        `-${this.width / 2 + this.margin.left} -${this.height / 2 + this.margin.top} ${
          this.width + this.margin.left + this.margin.right
        } ${this.height + this.margin.top + this.margin.bottom}`
      );

    this.chart.append('ul').attr('class', 'legend legend-right');

    this.tooltip = this.chart
      .append('div')
      .style('opacity', 0)
      .attr('class', 'pbds-tooltip')
      .attr('aria-hidden', 'true');

    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      this.updateChart(false);
    }
  }

  ngOnDestroy() {
    if (this.tooltip) this.tooltip.remove();
  }

  updateChart = (firstRun = true) => {
    // slices
    this.svg
      .selectAll('path')
      .data(this.pie(this.data))
      .join(
        (enter) => {
          const path = enter.append('path');

          path
            .each((d: any) => (d.outerRadius = this.outerRadius))
            .attr('fill', (d: any) => this.colorRange(d.data.label))
            .attr('class', 'slice')
            .each((d, i, nodes) => {
              // save the current data to be used in arc update tween
              this.currentData.splice(i, 1, d);
            });

          if (this.type === 'pie') {
            path.style('stroke', '#fff').style('stroke-width', 2).style('stroke-alignment', 'inner');
          }

          path.call((path) =>
            path
              .transition()
              .delay(500)
              .duration((d, i, n) => (firstRun ? 0 : 500))
              .attrTween('d', this.arcEnterTween)
          );

          return path;
        },
        (update) => {
          this.tooltipHide();

          update.each((d: any) => {
            return (d.outerRadius = this.outerRadius);
          });

          update.transition().duration(500).attrTween('d', this.arcUpdateTween);

          return update;
        },
        (exit) => exit.remove()
      )
      .on('mouseover', (event, data) => {
        this.pathMouseOver(event, data);
        this.tooltipShow(event, data);
      })
      .on('mousemove', (event, data) => {
        this.tooltipShow(event, data);
        // this.tooltipMove(event, data);
        this.tooltipMove(event, this.chart.node());
      })
      .on('mouseout', (event, data) => {
        this.pathMouseOut(event, data);
        this.tooltipHide();
      })
      .on('click', (event, data) => {
        this.pathClick(event, data);
      });

    // legend
    this.chart
      .select('.legend')
      .selectAll('.legend-item')
      .data(this.data)
      .join(
        (enter) => {
          const li = enter.append('li').attr('class', 'legend-item');

          li.append('span')
            .attr('class', 'legend-key')
            .style('background-color', (d: any) => this.colorRange(d.label));

          const description = li.append('span').attr('class', 'legend-description');

          description
            .append('span')
            .attr('class', 'legend-label')
            .html((d: any) => {
              switch (this.legendLabelFormatType) {
                case 'time':
                  const parsedTime = d3_isoParse(d.label);
                  return this.legendLabelFormat(parsedTime);

                default:
                  return d.label;
              }
            });

          description
            .append('span')
            .attr('class', 'legend-value')
            .html((d: any) => this.legendValueFormat(d.value));

          return li;
        },
        (update) => {
          update.selectAll('.legend-key').style('background-color', (d: any) => this.colorRange(d.label));

          update.select('.legend-label').html((d: any) => {
            switch (this.legendLabelFormatType) {
              case 'time':
                const parsedTime = d3_isoParse(d.label);
                return this.legendLabelFormat(parsedTime);

              default:
                return d.label;
            }
          });

          update.select('.legend-value').html((d: any) => this.legendValueFormat(d.value));

          return update;
        },
        (exit) => exit.remove()
      )
      .datum((d, i) => {
        return { data: d, index: i };
      })
      .on('mouseover focus', (event, data) => {
        this.legendMouseOverFocus(event, data);
        this.pathMouseOver(event, data);
      })
      .on('mouseout blur', (event, data) => {
        this.legendMouseOutBlur(event, data);
        this.pathMouseOut(event, data);
      })
      .on('click', (event, data) => {
        this.clicked.emit({ event: event, data: data.data });
      });
  };

  private arcEnterTween = (data) => {
    const i = d3_interpolate(data.startAngle, data.endAngle);
    return (t) => {
      data.endAngle = i(t);
      return this.arc(data);
    };
  };

  // see https://bl.ocks.org/HarryStevens/e1acaf628b1693f1b32e5f2e1a7f73fb
  private arcUpdateTween = (data, index, n) => {
    const interpolate = d3_interpolate(this.currentData[index], data);

    // update the current data for this slice
    this.currentData[index] = interpolate(0);

    return (t) => {
      return this.arc(interpolate(t));
    };
  };

  private arcMouseOverTween = (data) => {
    const i = d3_interpolate(data.outerRadius, this.outerRadius + this.arcZoom);

    return (t) => {
      data.outerRadius = i(t);
      return this.arc(data);
    };
  };

  private arcMouseOutTween = (data) => {
    // debugger;
    const i = d3_interpolate(data.outerRadius, this.outerRadius);
    return (t) => {
      data.outerRadius = i(t);
      return this.arc(data);
    };
  };

  private legendMouseOverFocus = (event, data) => {
    this.chart
      .selectAll('.legend-item')
      .filter((d, i) => {
        return `${d.label}` !== `${data.label}`;
      })
      .classed('inactive', true);
  };

  private legendMouseOutBlur = (event, data) => {
    this.chart.selectAll('.legend-item').classed('inactive', false);
  };

  private pathMouseOver = (event, data) => {
    // console.log(data);

    const slices = this.chart.selectAll('.slice');
    const slice = slices.filter((d, i) => i === data.index);

    this.chart
      .selectAll('.legend-item')
      .filter((d, i) => i !== data.index)
      .classed('inactive', true);

    slices.filter((d, i) => i !== data.index).classed('inactive', true);

    slice.transition().duration(300).delay(0).attrTween('d', this.arcMouseOverTween);

    this.hovered.emit({
      event: event,
      data: data.data ? data.data : data // legend hover data is different than slice hover data
    });
  };

  private pathMouseOut = (event, data) => {
    const slices = this.chart.selectAll('.slice');
    const slice = slices.filter((d, i) => d.label === data.label);

    this.chart.selectAll('.legend-item').classed('inactive', false);

    slices.classed('inactive', false);

    slice.transition().duration(300).delay(0).attrTween('d', this.arcMouseOutTween);
  };

  private pathClick = (event, data) => {
    this.clicked.emit({
      event: event,
      data: data.data
    });
  };

  private tooltipShow = (event, data) => {
    // debugger;
    this.tooltipSetPosition(event);

    const percentage = (data.endAngle - data.startAngle) / (2 * Math.PI);
    let label;

    switch (this.tooltipLabelFormatType) {
      case 'time':
        const parsedTime = d3_isoParse(data.data.label);
        label = `${this.tooltipLabelFormat(parsedTime)}${this.tooltipLabelSuffix}`;
        break;

      default:
        label = `${data.data.label}${this.tooltipLabelSuffix}`;
    }

    this.tooltip.html(
      `
        <div class="tooltip-label">${label}</div>
        <div class="tooltip-value">${this.tooltipValueFormat(percentage)}</div>
      `
    );

    this.tooltip.style('opacity', 1);
  };

  private tooltipMove = (event, node) => {
    this.tooltipSetPosition(event, node);
  };

  private tooltipHide = () => {
    this.tooltip.style('opacity', 0);
  };

  private tooltipSetPosition = (event, node?) => {
    // debugger;
    const coordinates = d3_pointer(event, node);

    this.tooltip.style('left', `${coordinates[0] + 16}px`);
    this.tooltip.style('top', `${coordinates[1] + 16}px`);
  };
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { ViewportScroller } from '@angular/common';

import { select as d3_select } from 'd3-selection';

import { extent as d3_extent, max as d3_max, min as d3_min } from 'd3-array';
import { axisBottom as d3_axisBottom, axisLeft as d3_axisLeft } from 'd3-axis';
import { format as d3_format } from 'd3-format';
import {
  scaleLinear as d3_scaleLinear,
  scaleOrdinal as d3_scaleOrdinal,
  scalePoint as d3_scalePoint,
  scaleTime as d3_scaleTime,
} from 'd3-scale';
import { isoParse as d3_isoParse, timeFormat as d3_timeFormat } from 'd3-time-format';

import { PbdsDatavizService } from './dataviz.service';

import { PbdsDatavizScatterplot } from './dataviz.interfaces';

@Component({
  selector: 'pbds-dataviz-scatterplot',
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PbdsDatavizScatterplotComponent implements OnInit, OnDestroy, OnChanges {
  @HostBinding('class.pbds-chart') chartClass = true;

  @HostBinding('class.pbds-chart-scatterplot') scatterplotClass = true;

  @Input() data: PbdsDatavizScatterplot[];

  @Input() width = 306;

  @Input() height = 400;

  @Input() jitterX = 4;

  @Input() jitterY = 4;

  @Input() xAxisType: 'date' | 'number' | 'string' = 'date';

  @Input() xAxisFormatString = '';

  @Input() xAxisTicks = 6;

  @Input() yAxisFormatString = '';

  @Input() yAxisTicks = 5;

  @Input() yAxisMinBuffer = 0.01;

  @Input() yAxisMaxBuffer = 0.01;

  @Input()
  hideXGrid = false;

  @Input()
  hideYGrid = false;

  @Input()
  hideLegend = false;

  @Input()
  legendWidth = 105 + 28; // hardcoded legend width + left margin, do not document until feedback

  @Input()
  legendPosition: 'right' | 'bottom' = 'right';

  @Input()
  legendLabelFormatType: 'number' | 'time' = null;

  @Input()
  tooltipXLabel = 'X';

  @Input()
  tooltipXValueFormatType: 'number' | 'time' = null;

  @Input()
  tooltipXValueFormatString = '';

  @Input()
  tooltipYLabel = 'Y';

  @Input()
  tooltipYValueFormatType: 'number' | 'time' = null;

  @Input()
  tooltipYValueFormatString = '';

  @Input()
  tooltipValueLabel = 'Value';

  @Input()
  tooltipValueFormatType: 'number' | 'time' = null;

  @Input()
  tooltipValueFormatString = '';

  @Input() marginTop = 10;

  @Input() marginRight = 20;

  @Input() marginBottom = 30;

  @Input() marginLeft = 55;

  @Input()
  theme;

  @Input()
  customColor: boolean = false;

  @Input()
  colorsArray = [];

  @Output()
  hovered: EventEmitter<object> = new EventEmitter<object>();

  @Output()
  clicked: EventEmitter<object> = new EventEmitter<object>();

  @Output()
  tooltipHovered: EventEmitter<object> = new EventEmitter<object>();

  @Output()
  tooltipClicked: EventEmitter<object> = new EventEmitter<object>();

  private chart;
  private svg;
  private margin;
  private colorRange;
  private xAxisScale;
  private xAxisCall;
  private xAxis;
  private xAxisFormat;
  private yAxisScale;
  private yAxisCall;
  private yAxis;
  private yAxisFormat;
  private xGrid;
  private xGridCall;
  private yGrid;
  private yGridCall;
  private valueScale;
  private xAxisTickSize: number;
  private xAxisTickSizeOuter: number;
  private yAxisTickSize: number;
  private yAxisTickSizeOuter: number;
  private hideXAxis: boolean;
  private hideYAxis: boolean;
  private hideXAxisDomain: boolean;
  private hideYAxisDomain: boolean;
  private hideXAxisZero: boolean;
  private hideYAxisZero: boolean;
  private hideXAxisTicks: boolean;
  private hideYAxisTicks: boolean;
  private legendLabelFormat;
  private tooltip;
  private hideTooltip: boolean;
  private tooltipXValueFormat;
  private tooltipYValueFormat;
  private tooltipValueFormat;

  constructor(
    private _dataviz: PbdsDatavizService,
    private _element: ElementRef,
    private _scroll: ViewportScroller,
  ) {}

  ngOnInit() {
    this.margin = {
      top: +this.marginTop,
      right: +this.marginRight,
      bottom: +this.marginBottom,
      left: +this.marginLeft,
    };

    // create formatters
    this.xAxisFormat =
      this.xAxisType === 'date' ? d3_timeFormat(this.xAxisFormatString) : d3_format(this.xAxisFormatString);

    this.yAxisFormat = d3_format(this.yAxisFormatString);
    this.tooltipXValueFormat = this._dataviz.d3Format(this.tooltipXValueFormatType, this.tooltipXValueFormatString);
    this.tooltipYValueFormat = this._dataviz.d3Format(this.tooltipYValueFormatType, this.tooltipYValueFormatString);
    this.tooltipValueFormat = this._dataviz.d3Format(this.tooltipValueFormatType, this.tooltipValueFormatString);

    this.xAxisTickSize = 8;
    this.xAxisTickSizeOuter = 0;
    this.yAxisTickSize = 8;
    this.yAxisTickSizeOuter = 0;

    // create the chart
    this.chart = d3_select(this._element.nativeElement).attr('aria-hidden', 'true');

    // create chart svg
    this.svg = this.chart
      .append('svg')
      .attr('width', +this.width + this.margin.right)
      .attr('height', +this.height + this.margin.top + this.margin.bottom)
      .attr('class', 'img-fluid')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr(
        'viewBox',
        `-${this.margin.left} -${this.margin.top} ${+this.width + this.margin.right} ${
          +this.height + this.margin.top + this.margin.bottom
        }`,
      );

    // X AXIS
    if (this.xAxisType === 'date') {
      this.xAxisScale = d3_scaleTime()
        .domain(
          d3_extent(this.data, (d: any) => {
            return d3_isoParse(d.x);
          }),
        )
        .range([0, this.width - this.margin.left - this.margin.right]);
    } else if (this.xAxisType === 'number') {
      this.xAxisScale = d3_scaleLinear()
        .domain(
          d3_extent(this.data, (d: any) => {
            return +d.x;
          }),
        )
        .range([0, this.width - this.margin.left - this.margin.right]);
    } else {
      const keys: any = this.data.map((d) => d.x);

      console.log('KEYS: ', keys);

      this.xAxisScale = d3_scalePoint()
        .domain(keys)
        .range([0, this.width - this.margin.left - this.margin.right]);
    }

    this.xAxisCall = d3_axisBottom(this.xAxisScale)
      .ticks(+this.xAxisTicks)
      .tickSize(this.xAxisTickSize)
      .tickSizeOuter(this.xAxisTickSizeOuter)
      .tickFormat(this.xAxisFormatter);

    this.xAxis = this.svg
      .append('g')
      .attr('class', 'axis axis-x')
      .attr('transform', `translate(0, ${this.height})`)
      .classed('axis-hidden', this.hideXAxis)
      .classed('axis-zero-hidden', this.hideXAxisZero)
      .classed('axis-domain-hidden', this.hideXAxisDomain)
      .classed('axis-ticks-hidden', this.hideXAxisTicks)
      .call(this.xAxisCall);

    // X GRIDLINES
    if (!this.hideXGrid) {
      if (this.xAxisType === 'string') {
        this.xGridCall = d3_axisBottom(this.xAxisScale)
          .tickSize(-this.height)
          .tickValues(
            this.xAxisScale.domain().filter((d, i) => {
              console.log('GRID: ', d, i);
              // see https://github.com/d3/d3-scale/issues/182
              // d3 cannot determine number of strings to show on xaxis with scalePoint()
              return i % this.xAxisTicks === 0;
            }),
          );
      } else {
        this.xGridCall = d3_axisBottom(this.xAxisScale).tickSize(-this.height);
      }

      this.xGrid = this.svg
        .append('g')
        .attr('class', 'grid grid-x')
        .classed('grid-zero-hidden', this.hideXAxisZero)
        .attr('transform', `translate(0, ${this.height})`)
        .call(this.xGridCall);
    }

    // Y AXIS
    this.yAxisScale = d3_scaleLinear()
      .domain([
        d3_min(this.data, (d: any, i) => {
          const minVal = +d.y;
          return minVal - minVal * +this.yAxisMinBuffer;
        }),
        d3_max(this.data, (d: any, i) => {
          const maxVal = +d.y;
          return maxVal + maxVal * this.yAxisMaxBuffer;
        }),
      ])
      .nice()
      .range([this.height, 0]);

    this.yAxisCall = d3_axisLeft(this.yAxisScale)
      .ticks(this.yAxisTicks)
      .tickSize(this.yAxisTickSize)
      .tickSizeOuter(this.yAxisTickSizeOuter)
      .tickFormat(this.yAxisFormatter);

    this.yAxis = this.svg
      .append('g')
      .attr('class', 'axis axis-y')
      .classed('axis-hidden', this.hideYAxis)
      .classed('axis-zero-hidden', this.hideYAxisZero)
      .classed('axis-domain-hidden', this.hideYAxisDomain)
      .classed('axis-ticks-hidden', this.hideYAxisTicks)
      .call(this.yAxisCall);

    // Y GRIDLINES
    if (!this.hideYGrid) {
      this.yGridCall = d3_axisLeft(this.yAxisScale)
        .ticks(this.yAxisTicks)
        .tickSize(-this.width + this.margin.left + this.margin.right);

      this.yGrid = this.svg
        .append('g')
        .attr('class', 'grid grid-y')
        .classed('grid-zero-hidden', this.hideYAxisZero)
        .attr('transform', `translate(0, 0)`)
        .call(this.yGridCall);
    }

    // create value scale if passed in data
    if ('value' in this.data[0]) {
      this.valueScale = d3_scaleLinear()
        .domain(
          d3_extent(this.data, (d: any) => {
            return +d.value;
          }),
        )
        .range([5, 50]);
    }

    // TOOLTIP
    if (!this.hideTooltip) {
      this.tooltip = d3_select('body')
        .append('div')
        .attr('class', 'pbds-tooltip west')
        .style('opacity', 0)
        .attr('aria-hidden', 'true'); // hide tooltip for accessibility

      // tooltip header
      this.tooltip.append('div').attr('class', 'tooltip-header');

      // tooltip table
      this.tooltip.append('table').attr('class', 'tooltip-table text-start w-100').append('tbody');
    }

    // add legend classes
    if (!this.hideLegend) {
      this.chart.classed('pbds-chart-legend-bottom', this.legendPosition === 'bottom' ? true : false);
      this.chart.append('ul').attr('class', `legend legend-${this.legendPosition}`);
    }

    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      this.updateChart();
    }
  }

  ngOnDestroy() {
    if (this.tooltip) this.tooltip.remove();
  }

  updateChart = () => {
    // build color ranges
    const labels = new Set();
    this.data.map((d) => labels.add(d.key));

    const labelsArray: any[] = Array.from(labels.values());

    //custom colors

    const colors = this.customColor ? this.colorsArray : this._dataviz.getColors(false, this.theme);

    this.colorRange = d3_scaleOrdinal().domain(labelsArray).range(colors);

    // update the xScale
    if (this.xAxisType === 'date') {
      this.xAxisScale.domain(
        d3_extent(this.data, (d: any) => {
          return d3_isoParse(d.x);
        }),
      );
    } else if (this.xAxisType === 'number') {
      this.xAxisScale.domain(
        d3_extent(this.data, (d: any) => {
          return +d.x;
        }),
      );
    }

    // update the yScale
    this.yAxisScale
      .domain([
        d3_min(this.data, (d: any, i) => {
          const minVal = +d.y;
          return minVal - minVal * +this.yAxisMinBuffer;
        }),
        d3_max(this.data, (d: any, i) => {
          const maxVal = +d.y;
          return maxVal + maxVal * this.yAxisMaxBuffer;
        }),
      ])
      .nice();

    this.xAxis.transition().duration(1000).call(this.xAxisCall);
    this.yAxis.transition().duration(1000).call(this.yAxisCall);

    // update the grids
    if (!this.hideXGrid) {
      this.xGrid.transition().duration(1000).call(this.xGridCall);
    }
    if (!this.hideYGrid) {
      this.yGrid.transition().duration(1000).call(this.yGridCall);
    }

    this.svg
      .selectAll('circle')
      .data(this.data)
      .join(
        (enter) => {
          return enter
            .append('circle')
            .attr('cx', (d: any) => {
              if (this.xAxisType === 'date') {
                return this.xAxisScale(d3_isoParse(d.x)) - this.jitter(this.jitterX);
              }

              return this.xAxisScale(d.x) - this.jitter(this.jitterX);
            })
            .attr('cy', (d: any) => this.yAxisScale(0))
            .attr('r', (d) => {
              if (d.value) {
                return this.valueScale(d.value);
              }

              return 5;
            })
            .attr('fill', (d: any) => this.colorRange(d.key))
            .attr('stroke', (d: any) => this.colorRange(d.key))
            .call((enter) => {
              enter.attr('cy', (d: any) => this.yAxisScale(d.y) - this.jitter(this.jitterY));
            });
        },
        (update) => {
          update
            .transition()
            .duration(1000)
            .attr('cx', (d: any) => this.xAxisScale(d.x) - this.jitter(this.jitterX))
            .attr('cy', (d: any) => this.yAxisScale(d.y) - this.jitter(this.jitterY));
          return update;
        },
        (exit) => {
          exit
            .attr('cy', (d: any) => this.yAxisScale(0) - this.jitter(this.jitterY))
            .attr('r', 0)
            .remove();
        },
      )
      .on('mouseover', (event, data) => this.tooltipShow(event, data))
      .on('mouseout', (event, data) => this.tooltipHide())
      .on('click', (event, data) => this.tooltipClicked.emit({ event, data: data }));

    if (!this.hideLegend) {
      const uniqueKeys = new Set();

      this.data.forEach((d) => {
        uniqueKeys.add(d.key);
      });

      this.chart
        .select('.legend')
        .selectAll('.legend-item')
        .data(Array.from(uniqueKeys.values()))
        .join(
          (enter) => {
            const li = enter.append('li').attr('class', 'legend-item');
            li.append('span')
              .attr('class', 'legend-key')
              .style('background-color', (d) => this.colorRange(d));
            li.append('span')
              .attr('class', 'legend-label')
              .html((d) => {
                switch (this.legendLabelFormatType) {
                  case 'number':
                    return this.legendLabelFormat(d);
                  case 'time':
                    const parsedTime = d3_isoParse(d);
                    return this.legendLabelFormat(parsedTime);
                  default:
                    return d;
                }
              });
            return li;
          },
          (update) => {
            update.select('.legend-label').html((d) => {
              switch (this.legendLabelFormatType) {
                case 'number':
                  return this.legendLabelFormat(d);
                case 'time':
                  const parsedTime = d3_isoParse(d);
                  return this.legendLabelFormat(parsedTime);
                default:
                  return d;
              }
            });
            return update;
          },
          (exit) => exit.remove(),
        )
        .on('mouseover', (event, data) => this.legendMouseOver(event, data))
        .on('mouseout', () => this.legendMouseOut())
        .on('click', (event, data) => this.legendMouseClick(event, data));
    }
  };

  legendMouseOver = (event, data) => {
    const filteredData = this.data.filter((d) => d.key === data);

    this.chart
      .selectAll('.legend-item')
      .filter((d, i) => {
        return d !== data;
      })
      .classed('inactive', true);

    this.svg
      .selectAll('circle')
      .filter((d, i) => {
        return d.key !== data;
      })
      .classed('inactive', true);

    this.hovered.emit({ event, data: filteredData });
  };

  legendMouseOut = () => {
    this.chart.selectAll('.legend-item').classed('inactive', false);

    this.svg.selectAll('circle').classed('inactive', false);
  };

  legendMouseClick = (event, data) => {
    const filteredData = this.data.filter((d) => d.key === data);

    this.clicked.emit({ event, data: filteredData });
  };

  private tooltipShow = (event, data) => {
    const scroll = this._scroll.getScrollPosition();
    const boundRect = event.currentTarget.getBoundingClientRect();

    this.svg.selectAll('circle').classed('inactive', true);
    d3_select(event.currentTarget).classed('inactive', false).classed('active', true);

    const clientWidth = document.body.clientWidth - 10;

    let html = `
      <tr>
        <td class="pe-3">${this.tooltipXLabel}</td><td class="text-end">${this.tooltipXValueFormatter(data.x)}</td>
      </tr>
      <tr>
        <td class="pe-3">${this.tooltipYLabel}</td><td class="text-end">${this.tooltipYValueFormatter(data.y)}</td>
      </tr>
    `;

    if (data.value) {
      html += `
      <tr>
        <td class="pe-3">${this.tooltipValueLabel}</td><td class="text-end">${this.tooltipValueFormatter(
          data.value,
        )}</td>
      </tr>
      `;
    }

    this.tooltip.select('tbody').html(html);

    const tooltipOffsetHeight = +this.tooltip.node().offsetHeight;
    const tooltipDimensions = this.tooltip.node().getBoundingClientRect();
    const dimensionCalculated = boundRect.left + tooltipDimensions.width + 8;

    let position;

    // flip the tooltip positions if near the right edge of the screen
    if (dimensionCalculated > clientWidth) {
      this.tooltip.classed('east', true);
      this.tooltip.classed('west', false);
      position = `${boundRect.left - boundRect.width / 2 - tooltipDimensions.width - 8}px`;
    } else if (dimensionCalculated < clientWidth) {
      this.tooltip.classed('east', false);
      this.tooltip.classed('west', true);
      position = `${boundRect.left + boundRect.width / 2 + 8}px`;
    }

    // set the tooltip styles
    this.tooltip.style('top', `${boundRect.top + boundRect.height / 2 - tooltipOffsetHeight / 2 + scroll[1]}px`);

    this.tooltip.style('left', position);
    this.tooltip.style('opacity', 1);

    this.tooltipHovered.emit({ event, data: data });
  };

  private tooltipHide = () => {
    this.svg.selectAll('circle').classed('inactive', false).classed('active', false);

    this.tooltip.style('opacity', 0);
  };

  private xAxisFormatter = (item) => {
    if (this.xAxisType === 'date') {
      const parseDate = d3_isoParse(item);
      return this.xAxisFormat(parseDate);
    } else if (this.xAxisType === 'number') {
      return this.xAxisFormat(item);
    } else {
      return item;
    }
  };

  private yAxisFormatter = (item) => {
    return this.yAxisFormat(item);
  };

  private tooltipXValueFormatter = (item) => {
    if (this.tooltipXValueFormatType === 'time') {
      const parseDate = d3_isoParse(item);
      return this.tooltipXValueFormat(parseDate);
    } else if (this.tooltipXValueFormatType === 'number') {
      return this.tooltipXValueFormat(item);
    } else {
      return item;
    }
  };

  private tooltipYValueFormatter = (item) => {
    if (this.tooltipYValueFormatType === 'time') {
      const parseDate = d3_isoParse(item);
      return this.tooltipYValueFormat(parseDate);
    } else if (this.tooltipYValueFormatType === 'number') {
      return this.tooltipYValueFormat(item);
    } else {
      return item;
    }
  };

  private tooltipValueFormatter = (item) => {
    if (this.tooltipValueFormatType === 'time') {
      const parseDate = d3_isoParse(item);
      return this.tooltipValueFormat(parseDate);
    } else if (this.tooltipValueFormatType === 'number') {
      return this.tooltipValueFormat(item);
    } else {
      return item;
    }
  };

  private jitter(jitter): number {
    return Math.random() * jitter;
  }
}

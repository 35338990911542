import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { ViewportScroller } from '@angular/common';

import { max as d3_max, min as d3_min } from 'd3-array';
import { axisBottom as d3_axisBottom, axisLeft as d3_axisLeft } from 'd3-axis';
import { scaleBand as d3_scaleBand, scaleLinear as d3_scaleLinear, scaleOrdinal as d3_scaleOrdinal } from 'd3-scale';
import { select as d3_select } from 'd3-selection';
import { stack as d3_stack, stackOrderNone as d3_stackOrderNone, stackOffsetDiverging } from 'd3-shape';
import { isoParse as d3_isoParse } from 'd3-time-format';

import { PbdsDatavizBarStacked } from './dataviz.interfaces';
import { PbdsDatavizService } from './dataviz.service';

@Component({
  selector: 'pbds-dataviz-bar-stacked',
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PbdsDatavizBarStackedComponent implements OnInit, OnDestroy, OnChanges {
  @HostBinding('class.pbds-chart')
  chartClass = true;

  @HostBinding('class.pbds-chart-stacked-bar')
  stackedBarClass = true;

  @Input()
  data: Array<PbdsDatavizBarStacked>;

  @Input()
  width = 306;

  @Input()
  height = 400;

  @Input()
  type: 'low' | 'medium' | 'high' | 'debug' = 'medium'; // debug to show all chart options

  @Input()
  marginTop = 10; // hardcoded on purpose, do not document until feedback

  @Input()
  marginRight = 0; // hardcoded on purpose, do not document until feedback

  @Input()
  marginBottom = 30; // hardcoded on purpose, do not document until feedback

  @Input()
  marginLeft = 55; // hardcoded on purpose, do not document until feedback

  @Input()
  hideXAxis = false;

  @Input()
  xAxisFormatType: 'number' | 'time' = null;

  @Input()
  xAxisFormatString = '';

  @Input()
  xAxisTitle: string | null = null;

  @Input()
  yAxisFormatType: 'number' | 'time' = null;

  @Input()
  yAxisFormatString = '';

  @Input()
  yAxisTicks = 5;

  @Input()
  yAxisMaxBuffer = 0.01;

  @Input()
  hideLegend = false;

  @Input()
  legendWidth = 105 + 28; // hardcoded legend width + left margin, do not document until feedback

  @Input()
  legendPosition: 'right' | 'bottom' = 'bottom';

  @Input()
  legendLabelFormatType: 'number' | 'time' = null;

  @Input()
  legendLabelFormatString = '';

  @Input()
  tooltipHeadingFormatType: 'time' = null;

  @Input()
  tooltipHeadingFormatString = '';

  @Input()
  tooltipHeadingSuffix = '';

  @Input()
  tooltipHeadingValueFormatType: 'number' = null;

  @Input()
  tooltipHeadingValueFormatString = '';

  @Input()
  tooltipLabelFormatType: 'time' = null;

  @Input()
  tooltipLabelFormatString = '';

  @Input()
  tooltipValueFormatType: 'number' = null;

  @Input()
  tooltipValueFormatString = '';

  @Input()
  theme;

  @Input()
  customColor: boolean = false;

  @Input()
  colorsArray = [];

  @Input()
  totalSavings;
  @Input() rotateXaxis = true;
  @Input() isDiverging = false;

  @Output()
  hovered: EventEmitter<object> = new EventEmitter<object>();

  @Output()
  clicked: EventEmitter<object> = new EventEmitter<object>();

  private dataStack;
  private dataKeys;
  private chart;
  private svg;
  private grayBars;
  private mouseBars;
  private bars;
  private margin;
  private colorRange;
  private hideGrayBars: boolean;
  private xAxisScale;
  private xAxisCall;
  private xAxis;
  private xAxisFormat;
  private xAxisTickSize: number;
  private xAxisTickSizeOuter: number;
  private xAxisTitleMargin: number;
  private hideXAxisDomain: boolean;
  private hideXAxisZero: boolean;
  private hideXAxisTicks: boolean;
  private hideXGrid: boolean;
  private hideYGrid: boolean;
  private yAxisMax;
  private yAxisScale;
  private yAxisCall;
  private yAxis;
  private yAxisFormat;
  private yAxisTickSize: number;
  private yAxisTickSizeOuter: number;
  private xGrid;
  private xGridCall;
  private yGrid;
  private yGridCall;
  private hideYAxis: boolean;
  private hideYAxisZero: boolean;
  private hideYAxisDomain: boolean;
  private hideYAxisTicks: boolean;
  private legendLabelFormat;
  private tooltip;
  private hideTooltip: boolean;
  private tooltipHeadingFormat;
  private tooltipHeadingValueFormat;
  private tooltipValueFormat;
  private tooltipLabelFormat;
  private centerline;
  yAxisMin: any;

  constructor(
    private _dataviz: PbdsDatavizService,
    private _element: ElementRef,
    private _scroll: ViewportScroller,
  ) {}

  ngOnInit() {
    // extract keys for stack data
    this.dataKeys = Object.keys(this.data[0]).filter((item) => item !== 'key');

    // create the D3 stack data
    if (this.isDiverging) {
      this.dataStack = d3_stack().keys(this.dataKeys).offset(stackOffsetDiverging)(this.data);
    } else {
      this.dataStack = d3_stack().keys(this.dataKeys).order(d3_stackOrderNone)(this.data);
    }

    //////////////////////////////////////////

    this.margin = {
      top: +this.marginTop,
      right: +this.marginRight,
      bottom: +this.marginBottom,
      left: +this.marginLeft,
    };

    // create formatters
    this.xAxisFormat = this._dataviz.d3Format(this.xAxisFormatType, this.xAxisFormatString);
    this.yAxisFormat = this._dataviz.d3Format(this.yAxisFormatType, this.yAxisFormatString);
    this.legendLabelFormat = this._dataviz.d3Format(this.legendLabelFormatType, this.legendLabelFormatString);
    this.tooltipHeadingFormat = this._dataviz.d3Format(this.tooltipHeadingFormatType, this.tooltipHeadingFormatString);
    this.tooltipHeadingValueFormat = this._dataviz.d3Format(
      this.tooltipHeadingValueFormatType,
      this.tooltipHeadingValueFormatString,
    );
    this.tooltipLabelFormat = this._dataviz.d3Format(this.tooltipLabelFormatType, this.tooltipLabelFormatString);
    this.tooltipValueFormat = this._dataviz.d3Format(this.tooltipValueFormatType, this.tooltipValueFormatString);

    // defaults for all chart types
    this.hideGrayBars = false;
    this.hideYAxis = false;
    this.hideXAxisZero = false;
    this.hideYAxisZero = false;
    this.hideXGrid = false;
    this.hideYGrid = false;
    this.hideXAxisDomain = false;
    this.hideYAxisDomain = false;
    this.hideTooltip = false;
    this.hideXAxisTicks = false;
    this.hideYAxisTicks = false;
    this.xAxisTickSize = 8;
    this.xAxisTickSizeOuter = 0;
    this.yAxisTickSize = 8;
    this.yAxisTickSizeOuter = 0;
    // this.hideTooltipLabel = false;

    if (this.type !== 'debug') {
      // set type defaults
      switch (this.type) {
        case 'low':
          this.hideGrayBars = true;
          this.hideXAxisTicks = true;
          this.hideXGrid = true;
          this.hideYAxisDomain = false;
          this.hideYAxisTicks = true;
          this.legendPosition = 'bottom';
          break;

        case 'medium':
          this.hideXAxisDomain = true;
          this.hideXGrid = true;
          this.hideXAxisTicks = true;
          this.hideYAxisDomain = true;
          this.hideYAxisTicks = true;
          this.hideYGrid = true;
          break;

        case 'high':
          this.hideXAxis = true;
          this.hideXAxisTicks = true;
          this.hideXAxisDomain = true;
          this.hideXGrid = true;
          this.hideYAxisDomain = true;
          this.hideYAxisTicks = true;
          this.hideYGrid = true;
          this.hideLegend = false;
          this.legendPosition = 'bottom';
          break;
      }
    }

    this.xAxisTitleMargin = this.xAxisTitle ? 30 : 0;

    // adjust margin if xAxis hidden
    //if (this.hideXAxis) this.margin.bottom = 10; // need small margin for yAxis with 0 tick label

    if (!this.hideLegend && this.legendPosition === 'right') {
      this.width = +this.width - +this.legendWidth;
    }

    // create the chart
    this.chart = d3_select(this._element.nativeElement).attr('aria-hidden', 'true');

    // create chart svg
    this.svg = this.chart
      .append('svg')
      .attr('width', +this.width)
      .attr('height', +this.height + this.margin.top + this.margin.bottom)
      .attr('class', 'img-fluid')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr(
        'viewBox',
        `-${this.margin.left} -${this.margin.top} ${+this.width} ${+this.height + this.margin.top + this.margin.bottom}`,
      );

    this.grayBars = this.svg.append('g').attr('class', 'gray-bars');
    this.mouseBars = this.svg.append('g').attr('class', 'mouseover-bars');
    this.bars = this.svg.append('g').attr('class', 'bars');

    // build color ranges
    const colors = this.customColor ? this.colorsArray : this._dataviz.getColors(false, this.theme);
    this.colorRange = d3_scaleOrdinal().range(colors);
    // this.colorRange = d3_scaleOrdinal().range(this._dataviz.getColors(false, this.theme));

    // X AXIS
    this.xAxisScale = d3_scaleBand()
      .domain(this.data.map((d) => d.key))
      .rangeRound([0, this.width - this.margin.left])
      .align(0);

    // add padding to the scale for gray bars
    !this.hideGrayBars
      ? this.xAxisScale.paddingInner(0.1).paddingOuter(0)
      : this.xAxisScale.paddingInner(0).paddingOuter(0);

    this.xAxisCall = d3_axisBottom(this.xAxisScale)
      .tickSize(this.xAxisTickSize)
      .tickSizeOuter(this.xAxisTickSizeOuter)
      .tickFormat(this.xAxisFormatter);

    this.xAxis = this.svg
      .append('g')
      .attr('class', 'axis axis-x')
      .attr('transform', `translate(0, ${this.height})`)
      .classed('axis-hidden', this.hideXAxis)
      .classed('axis-zero-hidden', this.hideXAxisZero)
      .classed('axis-domain-hidden', this.hideXAxisDomain)
      .classed('axis-ticks-hidden', this.hideXAxisTicks)
      .call(this.xAxisCall);

    // X GRIDLINES
    if (!this.hideXGrid) {
      this.xGridCall = d3_axisBottom(this.xAxisScale).tickSize(-this.height);

      this.xGrid = this.svg
        .append('g')
        .attr('class', 'grid grid-x')
        .classed('grid-zero-hidden', this.hideXAxisZero)
        .attr('transform', `translate(0, ${this.height})`)
        .call(this.xGridCall);
    }

    // X AXIS TITLE
    if (this.xAxisTitle) {
      this.svg
        .append('text')
        .attr('class', 'axis-title')
        .attr('text-anchor', 'middle')
        .attr(
          'transform',
          `translate(${this.svg.attr('width') / 2 - this.margin.left / 2 - this.margin.right / 2}, ${
            +this.height + +this.margin.top + (this.hideXAxis ? 20 : 40)
          })`,
        )
        .text(this.xAxisTitle);
    }

    // KEEP: use this block to debug yAxisMax
    // console.log(
    //   d3_max(this.dataStack, (data: any) => {
    //     // console.log(data);

    //     return d3_max(data, (d: any) => {
    //       // console.log('D: ', d);
    //       return d[1];
    //     });
    //   })
    // );

    // Y AXIS
    this.yAxisMin = d3_min(this.dataStack, (data: any) => {
      return d3_min(data, (d: any) => {
        return d[0];
      });
    });
    this.yAxisMax = d3_max(this.dataStack, (data: any) => {
      return d3_max(data, (d: any) => {
        return d[1];
      });
    });

    this.yAxisMax = this.yAxisMax + this.yAxisMax * this.yAxisMaxBuffer;

    this.yAxisScale = d3_scaleLinear().domain([this.yAxisMin, this.yAxisMax]).nice().rangeRound([this.height, 0]);

    this.yAxisCall = d3_axisLeft(this.yAxisScale)
      .ticks(this.yAxisTicks)
      .tickSize(this.yAxisTickSize)
      .tickSizeOuter(this.yAxisTickSizeOuter)
      .tickFormat(this.yAxisFormatter);

    this.yAxis = this.svg
      .append('g')
      .attr('class', 'axis axis-y')
      .classed('axis-hidden', this.hideYAxis)
      .classed('axis-zero-hidden', this.hideYAxisZero)
      .classed('axis-domain-hidden', this.hideYAxisDomain)
      .classed('axis-ticks-hidden', this.hideYAxisTicks)
      .call(this.yAxisCall);

    if (this.isDiverging) {
      this.centerline = this.svg
        .append('line')
        .attr('class', 'centerline')
        .attr('x2', +this.width - this.margin.right - this.margin.left)
        .attr('transform', `translate(0,  ${this.yAxisScale(0)})`);
    } else {
      this.svg.selectAll('.centerline').remove();
    }

    // Y GRIDLINES
    if (!this.hideYGrid) {
      this.yGridCall = d3_axisLeft(this.yAxisScale)
        .ticks(this.yAxisTicks)
        .tickSize(-this.width + this.margin.left + this.margin.right);

      this.yGrid = this.svg
        .append('g')
        .attr('class', 'grid grid-y')
        .classed('grid-zero-hidden', this.hideYAxisZero)
        .attr('transform', `translate(0, 0)`)
        .call(this.yGridCall);
    }

    // TOOLTIP
    if (!this.hideTooltip) {
      this.tooltip = d3_select('body')
        .append('div')
        .attr('class', 'pbds-tooltip west')
        .style('opacity', 0)
        .attr('aria-hidden', 'true'); // hide tooltip for accessibility

      // tooltip header
      this.tooltip.append('div').attr('class', 'tooltip-header');
      this.tooltip.append('div').attr('class', 'tooltip-header-value');

      // tooltip table
      this.tooltip.append('table').attr('class', 'tooltip-table text-start w-100').append('tbody');
    }

    // add legend classes
    if (!this.hideLegend) {
      this.chart.classed('pbds-chart-legend-bottom', this.legendPosition === 'bottom' ? true : false);
      this.chart.append('ul').attr('class', `legend legend-${this.legendPosition}`);
    }

    this.updateChart();
  }

  ngOnDestroy() {
    if (this.tooltip) this.tooltip.remove();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      this.updateChart(false);
    }
  }

  updateChart = (firstRun = true) => {
    this.dataKeys = Object.keys(this.data[0]).filter((item) => item !== 'key');

    // create the D3 stack data
    if (this.isDiverging) {
      this.dataStack = d3_stack().keys(this.dataKeys).offset(stackOffsetDiverging)(this.data);
    } else {
      this.dataStack = d3_stack().keys(this.dataKeys).order(d3_stackOrderNone)(this.data);
    }

    // update the xScale
    this.xAxisScale.domain(this.data.map((d) => d.key));

    // update the yScale
    this.yAxisMin = d3_min(this.dataStack, (data: any) => {
      return d3_min(data, (d: any) => {
        return d[0];
      });
    });
    this.yAxisMax = d3_max(this.dataStack, (data: any) => {
      return d3_max(data, (d: any) => {
        return d[1];
      });
    });

    this.yAxisMax = this.yAxisMax + this.yAxisMax * this.yAxisMaxBuffer;

    this.yAxisScale.domain([this.yAxisMin, this.yAxisMax]).rangeRound([this.height, 0]).nice();

    this.xAxis
      .transition()
      .duration(1000) // 1000
      .call(this.xAxisCall);

    this.yAxis
      .transition()
      .duration(1000) // 1000
      .call(this.yAxisCall);

    // update the grids
    if (!this.hideXGrid) {
      this.xGrid
        .transition()
        .duration(1000) // 1000
        .call(this.xGridCall);
    }

    if (!this.hideYGrid) {
      this.yGrid
        .transition()
        .duration(1000) // 1000
        .call(this.yGridCall);
    }

    if (this.isDiverging) {
      this.centerline
        .transition()
        .duration(1000) // 1000
        .attr('transform', `translate(0,  ${this.yAxisScale(0)})`);
    }

    // add gray bars
    if (!this.hideGrayBars) {
      this.grayBars
        .selectAll('.gray-bar')
        .data(this.data)
        .join(
          (enter) =>
            enter
              .append('rect')
              .attr('class', 'gray-bar')
              .attr('x', (d) => this.xAxisScale(d.key))
              .attr('width', this.xAxisScale.bandwidth())
              .attr('height', this.height),
          (update) =>
            update
              .transition()
              .duration((d, i, n) => (firstRun ? 0 : 1000))
              .attr('x', (d) => this.xAxisScale(d.key))
              .attr('width', this.xAxisScale.bandwidth())
              .attr('height', this.height)
              .selection(),
          (exit) => exit.remove(),
        );
    }

    // add colored bars
    const barGroups = this.bars
      .selectAll('.bar-group')
      .data(this.dataStack)
      .join(
        (enter) =>
          enter
            .append('g')
            .attr('class', 'bar-group')
            .attr('fill', (d) => this.colorRange(d.index)),
        (update) => update.attr('fill', (d) => this.colorRange(d.index)),
        (exit) => exit.remove(),
      );

    barGroups
      .selectAll('.bar')
      .data((d) => d)
      .join(
        (enter) =>
          enter
            .append('rect')
            .attr('class', 'bar')
            .classed('bar-divided', this.type !== 'high')
            .classed('bar-divided-low', this.type === 'low')
            .attr('x', (d, i) => {
              let x;

              if (this.type === 'medium') {
                x = this.xAxisScale(d.data.key) + (this.xAxisScale.bandwidth() / 8) * 3;
              } else {
                x = this.xAxisScale(d.data.key) + (this.xAxisScale.bandwidth() / 4) * 1;
              }

              return x;
            })
            .attr('y', (d) => this.yAxisScale(d[0]))
            .attr('width', (d) => {
              let width;

              if (this.type === 'medium') {
                width = this.xAxisScale.bandwidth() / 4;
              } else {
                width = this.xAxisScale.bandwidth() / 2;
              }

              return width;
            })
            .attr('height', 0)
            .call((enter) => {
              enter
                .transition()
                .duration((d, i, n) => (firstRun ? 0 : 500))
                .delay((d, i, n) => (firstRun ? 0 : 750))
                .attr('y', (d) => this.yAxisScale(d[1]))
                .attr('height', (d) => {
                  return this.yAxisScale(d[0]) - this.yAxisScale(d[1]);
                });

              return enter;
            }),
        (update) =>
          update.call((update) => {
            update
              .transition()
              .duration(1000)
              .attr('width', (d) => {
                let width;

                if (this.type === 'medium') {
                  width = this.xAxisScale.bandwidth() / 4;
                } else {
                  width = this.xAxisScale.bandwidth() / 2;
                }

                return width;
              })
              .attr('x', (d, i) => {
                let x;

                if (this.type === 'medium') {
                  x = this.xAxisScale(d.data.key) + (this.xAxisScale.bandwidth() / 8) * 3;
                } else {
                  x = this.xAxisScale(d.data.key) + (this.xAxisScale.bandwidth() / 4) * 1;
                }

                return x;
              })
              .attr('y', (d) => this.yAxisScale(d[1]))
              .attr('height', (d) => this.yAxisScale(d[0]) - this.yAxisScale(d[1]))
              .selection();

            return update;
          }),

        (exit) => exit.remove(),
      );

    // mouseover bars
    this.mouseBars
      .selectAll('.mouseover-bar')
      .data(this.data)
      .join(
        (enter) =>
          enter
            .append('rect')
            .attr('class', 'mouseover-bar')
            .style('opacity', 0)
            .attr('x', (d) => this.xAxisScale(d.key))
            .attr('width', this.xAxisScale.bandwidth())
            .attr('height', this.height),
        (update) =>
          update
            .attr('pointer-events', 'none')
            .attr('x', (d) => this.xAxisScale(d.key))
            .attr('width', this.xAxisScale.bandwidth())
            .attr('height', this.height)
            .transition()
            .selection()
            .attr('pointer-events', 'auto'),
        (exit) => exit.transition().selection().attr('pointer-events', 'none').remove(),
      )
      .datum((d, i) => {
        return { data: d, index: i };
      })
      .on('mouseover', (event, data) => this.barMouseOver(event, data))
      .on('mouseout', (event, data) => this.barMouseOut())
      .on('click', (event, data) => this.barMouseClick(event, data));

    this.bars.raise();
    this.xAxis.raise();
    this.mouseBars.raise();

    if (!this.hideLegend) {
      this.chart
        .select('.legend')
        .selectAll('.legend-item')
        .data(this.dataStack)
        .join(
          (enter) => {
            const li = enter.append('li').attr('class', 'legend-item');

            li.append('span')
              .attr('class', 'legend-key')
              .style('background-color', (d) => this.colorRange(d.index));

            li.append('span')
              .attr('class', 'legend-label')
              .html((d) => {
                switch (this.legendLabelFormatType) {
                  case 'number':
                    return this.legendLabelFormat(d.key);

                  case 'time':
                    const parsedTime = d3_isoParse(d.key);
                    return this.legendLabelFormat(parsedTime);

                  default:
                    return d.key;
                }
              });

            return li;
          },
          (update) => {
            update.select('.legend-label').html((d) => {
              switch (this.legendLabelFormatType) {
                case 'number':
                  return this.legendLabelFormat(d.key);

                case 'time':
                  const parsedTime = d3_isoParse(d.key);
                  return this.legendLabelFormat(parsedTime);

                default:
                  return d.key;
              }
            });

            return update;
          },
          (exit) => exit.remove(),
        )
        .datum((d, i) => {
          return { data: this.data, index: i };
        })
        .on('mouseover', (event, data) => this.legendMouseOver(event, data))
        .on('mouseout', () => this.legendMouseOut())
        .on('click', (event, data) => this.legendMouseClick(event, data));
    }
  };

  barMouseOver = (event, data) => {
    this.chart
      .selectAll('.bar-group')
      .selectAll('.bar')
      .filter((d, i) => {
        return i !== data.index;
      })
      .classed('inactive', true);

    this.tooltipShow(event, data);

    this.hovered.emit({ event, data });
  };

  barMouseOut = () => {
    this.chart.selectAll('.bar').classed('inactive', false);

    this.tooltipHide();
  };

  barMouseClick = (event, data) => {
    this.clicked.emit({ event, data });
  };

  legendMouseOver = (event, data) => {
    this.chart
      .selectAll('.legend-item')
      .filter((d, i) => i !== data.index)
      .classed('inactive', true);

    this.chart
      .selectAll('.bar-group')
      .filter((d, i) => i !== data.index)
      .classed('inactive', true);

    this.hovered.emit({ event, data });
  };

  legendMouseOut = () => {
    this.chart.selectAll('.legend-item').classed('inactive', false);

    this.chart.selectAll('.bar-group').classed('inactive', false);

    this.tooltipHide();
  };

  legendMouseClick = (event, data) => {
    this.clicked.emit({ event, data });
  };

  private xAxisFormatter = (item) => {
    switch (this.xAxisFormatType) {
      case 'number':
        return this.xAxisFormat(item);

      case 'time':
        const parseDate = d3_isoParse(item);
        return this.xAxisFormat(parseDate);

      default:
        return item;
    }
  };

  private tooltipShow = (event, data) => {
    const scroll = this._scroll.getScrollPosition();
    const mouserectDimensions = event.currentTarget.getBoundingClientRect();
    const clientWidth = document.body.clientWidth - 10;
    let dimensionCalculated;
    let tooltipDimensions;
    let tooltipOffsetHeight;
    // const yPosition = event.currentTarget.getBoundingClientRect();
    let yMaxBar = 0;
    let yPosition;
    let xPosition;

    // console.log(scroll, mouserectDimensions, tooltipOffsetHeight, tooltipDimensions, dimensionCalculated, clientWidth);

    this.tooltip.select('.tooltip-header').html((d) => {
      switch (this.tooltipHeadingFormatType) {
        case 'time':
          const parseDate = d3_isoParse(data.data.key);
          return `${this.tooltipHeadingFormat(parseDate)}${this.tooltipHeadingSuffix}`;

        default:
          return `${data.data.key}${this.tooltipHeadingSuffix}`;
      }
    });

    this.tooltip.select('.tooltip-header-value').html((d) => {
      let total = 0;

      Object.keys(data.data).map((e) => {
        if (e !== 'key') {
          total = total + data.data[e];
        }
      });

      return this.tooltipHeadingValueFormat(total);
    });

    this.tooltip
      .select('.tooltip-table')
      .select('tbody')
      .html((d) => {
        let html = ``;

        Object.keys(data.data)
          .filter((key) => key !== 'key') // remove the 'key' property
          .map((key, index) => {
            let label;
            let value = data.data[key];

            switch (this.tooltipLabelFormatType) {
              case 'time':
                const parseDate = d3_isoParse(key);
                label = this.tooltipHeadingFormat(parseDate);
                break;

              default:
                label = key;
            }

            switch (this.tooltipValueFormatType) {
              case 'number':
                if (value === null || value === undefined) {
                  value = '';
                } else {
                  value = this.tooltipValueFormat(data.data[key]);
                }
                break;

              default:
                value = data.data[key];
            }

            html += `
              <tr class='tooltip-item'>
                <td style="color: ${this.colorRange(index)}">
                  <span class="pbds-tooltip-key"></span>
                </td>
                <td class="tooltip-label pe-2 text-nowrap">${label}</td>
                <td class="tooltip-value text-end text-nowrap">${value}</td>
              </tr>
            `;
          });

        return html;
      });

    tooltipDimensions = this.tooltip.node().getBoundingClientRect();
    dimensionCalculated = mouserectDimensions.left + mouserectDimensions.width + tooltipDimensions.width + 8;
    tooltipOffsetHeight = +this.tooltip.node().offsetHeight;

    // flip the tooltip positions if near the right edge of the screen
    if (dimensionCalculated > clientWidth) {
      this.tooltip.classed('east', true);
      this.tooltip.classed('west', false);

      if (this.type === 'medium') {
        xPosition = `${mouserectDimensions.left + (mouserectDimensions.width / 8) * 3 - tooltipDimensions.width - 8}px`;
      } else {
        xPosition = `${mouserectDimensions.left + (mouserectDimensions.width / 4) * 1 - tooltipDimensions.width - 8}px`;
      }
    } else if (dimensionCalculated < clientWidth) {
      this.tooltip.classed('east', false);
      this.tooltip.classed('west', true);

      if (this.type === 'medium') {
        xPosition = `${mouserectDimensions.left + (mouserectDimensions.width / 8) * 5 + 8}px`;
      } else {
        xPosition = `${mouserectDimensions.left + (mouserectDimensions.width / 4) * 3 + 8}px`;
      }
    }

    yPosition = this.svg
      .selectAll('.bar-group')
      .selectAll('.bar')
      .filter((d, i) => {
        return i === data.index;
      })
      .each((d, i) => {
        yMaxBar = d[1] > yMaxBar ? d[1] : yMaxBar;
      })
      .filter((d, i) => {
        return d[1] === yMaxBar;
      })
      .node()
      .getBoundingClientRect();

    // set the tooltip styles
    this.tooltip.style('top', `${yPosition.top - tooltipOffsetHeight / 2 + scroll[1]}px`);
    this.tooltip.style('left', xPosition);
    this.tooltip.style('opacity', 1);
  };

  private tooltipHide = () => {
    this.tooltip.style('opacity', 0);
  };

  private yAxisFormatter = (item) => {
    switch (this.yAxisFormatType) {
      case 'number':
        return this.yAxisFormat(item);

      case 'time':
        const parseDate = d3_isoParse(item);
        return this.yAxisFormat(parseDate);

      default:
        return item;
    }
  };
}

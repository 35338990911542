<div class="page-header">
  <div class="page-title" [ngClass]="layout">
    <div class="row">
      <div class="col">
        <h1 [ngClass]="{ 'mb-0': sub, 'has-sub': sub }">{{ ttl }}</h1>
        <h2 *ngIf="sub">{{ sub }}</h2>
      </div>
    </div>
  </div>
</div>

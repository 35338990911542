<footer>
  <div [ngClass]="footerContainerType">
    <div class="row align-items-center">
      <div class="col-xs-12 col-md-8 col-lg-6">
        <div class="footer-logo-copyright">
          <a class="footer-logo" href="http://www.pb.com" aria-label="Pitney Bowes"></a>
          <span class="footer-copyright"
            >&copy;{{ date }} Pitney Bowes Inc.
            <span *ngIf="footerLogin">All rights reserved.</span>
          </span>
        </div>
      </div>

      <!-- standard footer links -->
      <div class="col-xs-12 col-md-4 col-lg-6 text-center text-md-end" *ngIf="!footerLogin">
        <ul class="list-inline">
          <li class="list-inline-item"><a [routerLink]="[]">Legal</a></li>
          <li class="list-inline-item"><a [routerLink]="[]">Accessibility</a></li>
          <li class="list-inline-item"><a [routerLink]="[]">Contact us</a></li>
        </ul>
      </div>

      <!-- signin footer links -->
      <div class="col-xs-12 col-md-4 col-lg-6 text-center text-md-end" *ngIf="footerLogin">
        <ul class="list-inline">
          <li class="list-inline-item"><a [routerLink]="[]">Legal</a></li>
          <li class="list-inline-item"><a [routerLink]="[]">Privacy</a></li>
          <li class="list-inline-item"><a [routerLink]="[]">Accessibility</a></li>
          <li class="list-inline-item"><a [routerLink]="[]">Terms of use</a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>

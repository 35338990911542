<div class="site-search">
  <form>
    <div class="algolia-typeahead">
      <input
        id="algolia-search"
        #algoliaSearch
        [ngbTypeahead]="search"
        [resultFormatter]="resultFormatter"
        [ngClass]="{ 'form-control form-control-lg shadow': location === 'home' }"
        (selectItem)="selectItem($event)"
        type="text"
        placeholder="Search"
        aria-label="Search"
      />
      <span class="typeahead-loader" *ngIf="searching">
        <i class="pbi-icon-mini pbi-loader-circle spin"></i>
      </span>
    </div>
  </form>
</div>

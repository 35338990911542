import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PbdsProgressSpinnerComponent } from './progress-spinner.component';

@NgModule({
  declarations: [PbdsProgressSpinnerComponent],
  imports: [CommonModule],
  exports: [PbdsProgressSpinnerComponent]
})
export class PbdsProgressSpinnerModule {}

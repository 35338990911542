import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { L10nConfig, L10nLoader, L10nStorage, L10nLocale, L10nTranslationLoader, L10nProvider } from 'angular-l10n';

export const l10nConfig: L10nConfig = {
  format: 'language-region',
  cache: true,
  keySeparator: '.',
  defaultLocale: { language: 'en-US', currency: 'USD' },
  schema: [
    {
      text: 'English (United States)',
      locale: {
        language: 'en-US',
        currency: 'USD'
      },
      dir: 'ltr'
    },
    {
      text: 'Español (Estados Unidos)',
      locale: {
        language: 'es-US',
        currency: 'USD'
      },
      dir: 'ltr'
    },
    {
      text: 'Dansk', // Danish
      locale: {
        language: 'da-DK',
        currency: 'DKK'
      }
    },

    {
      text: 'Suomi',
      locale: {
        language: 'fi-FI',
        currency: 'EUR'
      }
    },
    {
      text: 'Français (Canada)',
      locale: {
        language: 'fr-CA',
        currency: 'CAD'
      }
    },
    {
      text: 'Français (France)',
      locale: {
        language: 'fr-FR',
        currency: 'EUR'
      }
    },
    {
      text: 'Deutsch',
      locale: {
        language: 'de-DE',
        currency: 'EUR'
      }
    },
    {
      text: 'Italiano',
      locale: {
        language: 'it-IT',
        currency: 'EUR'
      }
    },
    {
      text: '日本語',
      locale: {
        language: 'ja-JP',
        currency: 'JPY'
      }
    },
    {
      text: 'Norsk',
      locale: {
        language: 'nb-NO',
        currency: 'NOK'
      }
    },
    {
      text: 'Português (Brasil)',
      locale: {
        language: 'pt-BR',
        currency: 'BRL'
      }
    },
    {
      text: 'Svenska',
      locale: {
        language: 'sv-SE',
        currency: 'SEK'
      }
    }
  ],
  providers: []
};

export function initL10n(l10nLoader: L10nLoader): () => Promise<void> {
  return () => l10nLoader.init();
}

@Injectable()
export class AppStorage implements L10nStorage {
  private hasStorage: boolean;

  constructor() {
    this.hasStorage = typeof Storage !== 'undefined';
  }

  public async read(): Promise<L10nLocale | null> {
    if (this.hasStorage) {
      return Promise.resolve(JSON.parse(localStorage.getItem('locale')));
    }
    return Promise.resolve(null);
  }

  public async write(locale: L10nLocale): Promise<void> {
    if (this.hasStorage) {
      localStorage.setItem('locale', JSON.stringify(locale));
    }
  }
}

@Injectable()
export class HttpTranslationLoader implements L10nTranslationLoader {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(@Optional() private http: HttpClient) {}

  public get(language: string, provider: L10nProvider): Observable<{ [key: string]: any }> {
    const url = `${provider.asset}/${language}.json`;
    const options = {
      headers: this.headers
    };
    return this.http.get(url, options);
  }
}

import { ViewportScroller } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { FooterComponent } from '@app/shared/footer/footer.component';
import { AccordionService } from '@app/shared/services/accordion.service';
import { MockDataService } from '@app/shared/services/mock-data.service';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ShepherdService } from 'angular-shepherd';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {
  @ViewChild('offcanvas') offcanvas: NgbOffcanvas;
  @ViewChild('singleChoiceActionModal', { static: true }) singleChoiceActionModal: ElementRef;
  @ViewChild('footer') footer: FooterComponent;
  closeResult = '';
  hasSeenCoachMark: boolean;

  versionlist = [
    {
      designsystem: '16.0.4',
      angular: '16.2',
      bootstrap: '5.2',
      releaseNotes: {
        link: '/pages/release-notes',
        internal: true,
      },
    },
    {
      designsystem: '7.1',
      angular: '15.2',
      bootstrap: '5.2',
      releaseNotes: {
        link: 'https://ds7.designsystem.pitneycloud.com/pages/release-notes',
      },
      migration: {
        link: 'https://ds7.designsystem.pitneycloud.com/pages/release-notes#ss-7.0.0-%E2%80%93-angular-15/bootstrap-5-9',
      },
      documentation: {
        link: 'https://ds7.designsystem.pitneycloud.com/',
      },
    },
    {
      designsystem: '6.19',
      angular: '14.3',
      bootstrap: '4.6',
      releaseNotes: {
        link: 'https://ds6.designsystem.pitneycloud.com/pages/release-notes',
      },
      documentation: {
        link: 'https://ds6.designsystem.pitneycloud.com/',
      },
    },
  ];
  slideData$;
  constructor(
    public accordionService: AccordionService,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private viewportScroller: ViewportScroller,
    private offcanvasService: NgbOffcanvas,
    private shepherdService: ShepherdService,
    private mockDataService: MockDataService,
    private modalService: NgbModal,
  ) {
    this.renderer.addClass(document.body, 'home');
    this.hasSeenCoachMark = localStorage.getItem('hasSeenCoachMark') === 'true';
  }

  tooltip = {
    copy: 'Click to Copy',
  };
  tooltipMessage = this.tooltip.copy;

  searchCatForm = this.fb.group({
    searchCat: ['web'],
  });

  open(content) {
    this.offcanvasService.open(content, { ariaLabelledBy: 'offcanvas-basic-title' });
  }

  dismissSidebar(content) {
    this.offcanvasService.dismiss(content);
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    }, 100);
  }

  ngAfterViewInit() {
    // this.shepherdService.confirmCancel = false;
    // if (!this.hasSeenCoachMark) {
    //   this.shepherdService.start();
    // }

    setTimeout(() => {
      this.footer.footerContainerType = 'container-fluid';
    }, 0);
  }

  ngOnInit() {
    this.slideData$ = this.mockDataService.get('slides.json');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'home');
    this.offcanvasService.dismiss(this.offcanvas);
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.tooltipMessage = `Copied`;
  }

  tooltipHidden() {
    this.tooltipMessage = this.tooltip.copy;
  }
}

import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostBinding,
  OnDestroy,
  SimpleChanges,
  ChangeDetectionStrategy
} from '@angular/core';

import { ViewportScroller, Location } from '@angular/common';

import { select as d3_select } from 'd3-selection';
import { scaleOrdinal as d3_scaleOrdinal, scaleLinear as d3_scaleLinear, scaleBand as d3_scaleBand } from 'd3-scale';
import { min as d3_min, max as d3_max } from 'd3-array';
import { axisBottom as d3_axisBottom, axisLeft as d3_axisLeft } from 'd3-axis';
import { isoParse as d3_isoParse } from 'd3-time-format';

import { PbdsDatavizService } from './dataviz.service';
import { PbdsDatavizBar } from './dataviz.interfaces';

@Component({
  selector: 'pbds-dataviz-bar',
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PbdsDatavizBarComponent implements OnInit, OnDestroy, OnChanges {
  @HostBinding('class.pbds-chart')
  chartClass = true;

  @HostBinding('class.pbds-chart-bar')
  barClass = true;

  @Input()
  data: PbdsDatavizBar[];

  @Input()
  width = 306;

  @Input()
  height = 400;

  @Input()
  type: 'low' | 'medium' | 'high' | 'debug' = 'medium'; // debug to show all chart options

  @Input()
  singleSeries = false;

  @Input()
  xAxisFormatType: 'number' | 'time' = null;

  @Input()
  xAxisFormatString = '';

  @Input()
  xAxisTitle: string | null = null;

  @Input()
  yAxisFormatType: 'number' | 'time' = null;

  @Input()
  yAxisFormatString = '';

  @Input()
  yAxisTicks = 5;

  @Input()
  yAxisMinBuffer = 0.01;

  @Input()
  yAxisMaxBuffer = 0.01;

  @Input()
  hideLegend = false;

  @Input()
  legendWidth = 105 + 28; // hardcoded legend width + left margin, do not document until feedback

  @Input()
  legendPosition: 'right' | 'bottom' = 'right';

  @Input()
  legendLabelFormatType: 'number' | 'time' = null;

  @Input()
  legendLabelFormatString = '';

  @Input()
  tooltipLabelFormatType: 'number' | 'time' = null;

  @Input()
  tooltipLabelFormatString = '';

  @Input()
  tooltipLabelSuffix = '';

  @Input()
  hideTooltipLabel: boolean = true;

  @Input()
  tooltipValueFormatType: 'number' | 'time' = null;

  @Input()
  tooltipValueFormatString = '';

  @Input()
  marginTop = 10; // hardcoded on purpose, do not document until feedback

  @Input()
  marginRight = 0; // hardcoded on purpose, do not document until feedback

  @Input()
  marginBottom = 30; // hardcoded on purpose, do not document until feedback

  @Input()
  marginLeft = 55; // hardcoded on purpose, do not document until feedback

  @Input()
  threshold = null;

  @Input()
  thresholdLabel = 'Threshold';

  @Input()
  average = null;

  @Input()
  averageLabel = 'Average';

  @Input()
  theme;

  @Input() gradient = true;

  @Output()
  hovered: EventEmitter<object> = new EventEmitter<object>();

  @Output()
  clicked: EventEmitter<object> = new EventEmitter<object>();

  private chart;
  private svg;
  private margin;
  private colorRange;
  private hideGrayBars: boolean;
  private yThreshold;
  private yAverage;
  private xAxisScale;
  private xAxisCall;
  private xAxis;
  private xAxisFormat;
  private xAxisTitleMargin: number;
  private yAxisScale;
  private yAxisCall;
  private yAxis;
  private yAxisFormat;
  private xGrid;
  private xGridCall;
  private yGrid;
  private yGridCall;
  private xAxisTickSize: number;
  private xAxisTickSizeOuter: number;
  private yAxisTickSize: number;
  private yAxisTickSizeOuter: number;
  private hideXAxis: boolean;
  private hideYAxis: boolean;
  private hideXAxisDomain: boolean;
  private hideYAxisDomain: boolean;
  private hideXAxisZero: boolean;
  private hideYAxisZero: boolean;
  private hideXGrid: boolean;
  private hideYGrid: boolean;
  private hideXAxisTicks: boolean;
  private hideYAxisTicks: boolean;
  private legendLabelFormat;
  private tooltip;
  private hideTooltip: boolean;
  private tooltipValueFormat;
  private tooltipLabelFormat;

  constructor(
    private _dataviz: PbdsDatavizService,
    private _element: ElementRef,
    private _scroll: ViewportScroller,
    private _location: Location
  ) {}

  ngOnInit() {
    this.margin = {
      top: +this.marginTop,
      right: +this.marginRight,
      bottom: +this.marginBottom,
      left: +this.marginLeft
    };

    // create formatters
    this.xAxisFormat = this._dataviz.d3Format(this.xAxisFormatType, this.xAxisFormatString);
    this.yAxisFormat = this._dataviz.d3Format(this.yAxisFormatType, this.yAxisFormatString);
    this.legendLabelFormat = this._dataviz.d3Format(this.legendLabelFormatType, this.legendLabelFormatString);
    this.tooltipLabelFormat = this._dataviz.d3Format(this.tooltipLabelFormatType, this.tooltipLabelFormatString);
    this.tooltipValueFormat = this._dataviz.d3Format(this.tooltipValueFormatType, this.tooltipValueFormatString);

    // defaults for all chart types
    this.hideGrayBars = false;
    this.hideXAxis = false;
    this.hideYAxis = false;
    this.hideXAxisZero = false;
    this.hideYAxisZero = false;
    this.hideXGrid = false;
    this.hideYGrid = false;
    this.hideXAxisDomain = false;
    this.hideYAxisDomain = false;
    this.hideTooltip = false;
    this.hideXAxisTicks = false;
    this.hideYAxisTicks = false;
    this.xAxisTickSize = 8;
    this.xAxisTickSizeOuter = 0;
    this.yAxisTickSize = 8;
    this.yAxisTickSizeOuter = 0;

    if (this.type !== 'debug') {
      // set type defaults
      switch (this.type) {
        case 'low':
          this.hideGrayBars = true;
          this.hideXAxis = !this.hideLegend;
          this.hideXAxisTicks = true;
          this.hideXGrid = true;
          this.hideYAxisDomain = false;
          this.hideYAxisTicks = true;
          this.legendPosition = 'bottom';
          break;

        case 'medium':
          this.hideXAxisDomain = true;
          this.hideXAxis = !this.hideLegend;
          this.hideXGrid = true;
          this.hideXAxisTicks = true;
          this.hideYAxisDomain = true;
          this.hideYAxisTicks = true;
          this.hideYGrid = true;
          break;

        case 'high':
          this.hideXAxis = true;
          this.hideXAxisDomain = true;
          this.hideXGrid = true;
          this.hideYAxisDomain = true;
          this.hideYAxisTicks = true;
          this.hideYGrid = true;
          this.hideLegend = true;
          this.hideTooltipLabel = false;
          break;
      }

      // single series overrides
      if (this.singleSeries) {
        this.hideLegend = true;
        this.hideXAxis = true;
        this.hideXGrid = true;
        this.hideTooltipLabel = false;
      }

      this.xAxisTitleMargin = this.xAxisTitle ? 30 : 0;
    }

    // adjust margin if xAxis hidden
    if (this.hideXAxis) this.margin.bottom = 10; // need small margin for yAxis with 0 tick label

    if (!this.hideLegend && this.legendPosition === 'right') {
      this.width = +this.width - +this.legendWidth;
    }

    // create the chart
    this.chart = d3_select(this._element.nativeElement).attr('aria-hidden', 'true');

    // create chart svg
    this.svg = this.chart
      .append('svg')
      .attr('width', +this.width)
      .attr('height', +this.height + this.margin.top + this.margin.bottom + this.xAxisTitleMargin)
      .attr('class', 'img-fluid')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr(
        'viewBox',
        `-${this.margin.left} -${this.margin.top} ${+this.width} ${
          +this.height + this.margin.top + this.margin.bottom + this.xAxisTitleMargin
        }`
      );

    // build color ranges
    this.colorRange = d3_scaleOrdinal().range(
      this._dataviz.createGradientDefs(this.svg, this.singleSeries, this.theme)
    );

    // X AXIS
    this.xAxisScale = d3_scaleBand()
      .domain(this.data.map((d) => d.label))
      .rangeRound([0, this.width - this.margin.left])
      .align(0);

    // add padding to the scale for gray bars
    !this.hideGrayBars
      ? this.xAxisScale.paddingInner(0.1).paddingOuter(0)
      : this.xAxisScale.paddingInner(0).paddingOuter(0);

    this.xAxisCall = d3_axisBottom(this.xAxisScale)
      .tickSize(this.xAxisTickSize)
      .tickSizeOuter(this.xAxisTickSizeOuter)
      .tickFormat(this.xAxisFormatter);

    this.xAxis = this.svg
      .append('g')
      .attr('class', 'axis axis-x')
      .attr('transform', `translate(0, ${this.height})`)
      .classed('axis-hidden', this.hideXAxis)
      .classed('axis-zero-hidden', this.hideXAxisZero)
      .classed('axis-domain-hidden', this.hideXAxisDomain)
      .classed('axis-ticks-hidden', this.hideXAxisTicks)
      .call(this.xAxisCall);

    // X GRIDLINES
    if (!this.hideXGrid) {
      this.xGridCall = d3_axisBottom(this.xAxisScale).tickSize(-this.height);

      this.xGrid = this.svg
        .append('g')
        .attr('class', 'grid grid-x')
        .classed('grid-zero-hidden', this.hideXAxisZero)
        .attr('transform', `translate(0, ${this.height})`)
        .call(this.xGridCall);
    }

    // X AXIS TITLE
    if (this.xAxisTitle) {
      this.svg
        .append('text')
        .attr('class', 'axis-title')
        .attr('text-anchor', 'middle')
        .attr(
          'transform',
          `translate(${this.svg.attr('width') / 2 - this.margin.left / 2 - this.margin.right / 2}, ${
            this.height + this.margin.top + (this.hideXAxis ? 20 : 40)
          })`
        )
        .text(this.xAxisTitle);
    }

    // Y AXIS
    this.yAxisScale = d3_scaleLinear()
      .domain([
        d3_min(this.data, (d) => d.value - d.value * +this.yAxisMinBuffer),
        d3_max(this.data, (d) => d.value + d.value * +this.yAxisMaxBuffer)
      ])
      .nice()
      .rangeRound([this.height, 0]);

    this.yAxisCall = d3_axisLeft(this.yAxisScale)
      .ticks(this.yAxisTicks)
      .tickSize(this.yAxisTickSize)
      .tickSizeOuter(this.yAxisTickSizeOuter)
      .tickFormat(this.yAxisFormatter);

    this.yAxis = this.svg
      .append('g')
      .attr('class', 'axis axis-y')
      .classed('axis-hidden', this.hideYAxis)
      .classed('axis-zero-hidden', this.hideYAxisZero)
      .classed('axis-domain-hidden', this.hideYAxisDomain)
      .classed('axis-ticks-hidden', this.hideYAxisTicks)
      .call(this.yAxisCall);

    // Y GRIDLINES
    if (!this.hideYGrid) {
      this.yGridCall = d3_axisLeft(this.yAxisScale)
        .ticks(this.yAxisTicks)
        .tickSize(-this.width + this.margin.left + this.margin.right);

      this.yGrid = this.svg
        .append('g')
        .attr('class', 'grid grid-y')
        .classed('grid-zero-hidden', this.hideYAxisZero)
        .attr('transform', `translate(0, 0)`)
        .call(this.yGridCall);
    }

    // Y THRESHOLD
    if (this.threshold) {
      this.yThreshold = this.svg
        .append('line')
        .attr('class', 'threshold')
        .attr('x2', +this.width - this.margin.right - this.margin.left)
        .attr('transform', `translate(0,  ${this.yAxisScale(+this.threshold)})`);
    }

    // Y AVERAGE
    if (this.average) {
      this.yAverage = this.svg
        .append('line')
        .attr('class', 'average')
        .attr('x2', +this.width - this.margin.right - this.margin.left)
        .attr('transform', `translate(0,  ${this.yAxisScale(+this.average)})`);
    }

    // TOOLTIP
    if (!this.hideTooltip) {
      this.tooltip = d3_select('body')
        .append('div')
        .attr('class', 'pbds-tooltip south')
        .style('opacity', 0)
        .attr('aria-hidden', 'true'); // hide tooltip for accessibility
    }

    // add legend classes
    if (!this.hideLegend) {
      this.chart.classed('pbds-chart-legend-bottom', this.legendPosition === 'bottom' ? true : false);
      this.chart.append('ul').attr('class', `legend legend-${this.legendPosition}`);
    }

    // add average to legend
    if (this.average && !this.hideLegend) {
      this.chart
        .select('.legend')
        .append('li')
        .attr('class', 'legend-static legend-average')
        .html(() => {
          return `
          <span class="legend-key"></span>
          <span class="legend-label">${this.averageLabel}</span>
        `;
        });
    }

    // add threshold to legend
    if (this.threshold && !this.hideLegend) {
      this.chart
        .select('.legend')
        .append('li')
        .attr('class', 'legend-static legend-threshold')
        .html(() => {
          return `
          <span class="legend-key"></span>
          <span class="legend-label">${this.thresholdLabel}</span>
        `;
        });
    }

    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      this.updateChart();
    }
  }

  ngOnDestroy() {
    if (this.tooltip) this.tooltip.remove();
  }

  updateChart = () => {
    // update the xScale
    this.xAxisScale.domain(this.data.map((d) => d.label));

    // update the yScale
    this.yAxisScale
      .domain([
        d3_min(this.data, (d) => d.value - d.value * +this.yAxisMinBuffer),
        d3_max(this.data, (d) => d.value + d.value * +this.yAxisMaxBuffer)
      ])
      .rangeRound([this.height, 0])
      .nice();

    this.xAxis.transition().duration(1000).call(this.xAxisCall);

    this.yAxis.transition().duration(1000).call(this.yAxisCall);

    // update the grids
    if (!this.hideXGrid) {
      this.xGrid.transition().duration(1000).call(this.xGridCall);
    }

    if (!this.hideYGrid) {
      this.yGrid.transition().duration(1000).call(this.yGridCall);
    }

    if (!this.hideGrayBars) {
      // gray bars
      this.svg
        .selectAll('.gray-bar')
        .data(this.data)
        .join(
          (enter) =>
            enter
              .append('rect')
              .attr('class', 'gray-bar')
              .attr('height', 0)
              .attr('x', (d) => this.xAxisScale(d.label))
              .attr('width', this.xAxisScale.bandwidth())
              .call((enter) => enter.transition().duration(500).attr('height', this.height)),
          (update) =>
            update
              .transition()
              .duration(1000)
              .attr('x', (d) => this.xAxisScale(d.label))
              .attr('width', this.xAxisScale.bandwidth())
              .selection(),
          (exit) => exit.transition().selection().attr('pointer-events', 'none').remove()
        );

      // color bars
      this.svg
        .selectAll('.bar')
        .data(this.data)
        .join(
          (enter) =>
            enter
              .append('rect')
              .attr('class', 'bar')
              .attr('fill', (d) => this.barFill(d)) // removes hash to prevent safari bug;
              .attr('x', (d) => this.xAxisScale(d.label) + this.xAxisScale.bandwidth() / 4)
              .attr('width', this.xAxisScale.bandwidth() / 2)
              .attr('y', this.height)
              .attr('height', 0)
              .attr('pointer-events', 'none')
              .call((enter) =>
                enter
                  .transition()
                  .duration(1000)
                  .attr('y', (d) => this.yAxisScale(d.value))
                  .attr('height', (d) => this.height - this.yAxisScale(d.value))
                  .attr('data-color', (d) => this.colorRange(d.label))
                  .transition()
                  .attr('pointer-events', 'auto')
              ),
          (update) =>
            update
              .attr('pointer-events', 'none')
              .transition()
              .duration(1000)
              .attr('x', (d) => this.xAxisScale(d.label) + this.xAxisScale.bandwidth() / 4)
              .attr('width', this.xAxisScale.bandwidth() / 2)
              .attr('height', (d) => this.height - this.yAxisScale(d.value))
              .attr('y', (d) => this.yAxisScale(d.value))
              .transition()
              .selection()
              .attr('pointer-events', 'auto'),
          (exit) => exit.transition().selection().attr('pointer-events', 'none').remove()
        )
        .on('mouseover', (event, data) => this.barMouseOver(event, data))
        .on('mouseout', (event, data) => this.barMouseOut())
        .on('click', (event, data) => this.barMouseClick(event, data));
    } else {
      // color bars
      this.svg
        .selectAll('.bar')
        .data(this.data)
        .join(
          (enter) =>
            enter
              .append('rect')
              .attr('class', 'bar')
              .attr('fill', (d) => this.barFill(d))
              .attr('x', (d) => this.xAxisScale(d.label) + this.xAxisScale.bandwidth() / 5.5)
              .attr('width', this.xAxisScale.bandwidth() / 1.5)
              .attr('y', this.height)
              .attr('height', 0)
              .attr('pointer-events', 'none')
              .call((enter) =>
                enter
                  .transition()
                  .duration(1000)
                  .attr('y', (d) => this.yAxisScale(d.value))
                  .attr('height', (d) => this.height - this.yAxisScale(d.value))
                  .attr('data-color', (d) => this.colorRange(d.label))
                  .transition()
                  .attr('pointer-events', 'auto')
              ),
          (update) =>
            update
              .attr('pointer-events', 'none')
              .transition()
              .duration(1000)
              .attr('x', (d) => this.xAxisScale(d.label) + this.xAxisScale.bandwidth() / 5.5)
              .attr('width', this.xAxisScale.bandwidth() / 1.5)
              .attr('height', (d) => this.height - this.yAxisScale(d.value))
              .attr('y', (d) => this.yAxisScale(d.value))
              .transition()
              .selection()
              .attr('pointer-events', 'auto'),
          (exit) => exit.transition().attr('pointer-events', 'none').remove()
        )
        .on('mouseover', (event, data) => this.barMouseOver(event, data))
        .on('mouseout', (event, data) => this.barMouseOut())
        .on('click', (event, data) => this.barMouseClick(event, data));
    }

    if (!this.hideLegend) {
      this.chart
        .select('.legend')
        .selectAll('.legend-item')
        .data(this.data)
        .join(
          (enter) => {
            const li = enter.insert('li', 'li.legend-static').attr('class', 'legend-item');

            li.append('span')
              .attr('class', 'legend-key')
              .style('background-color', (d) => this.colorRange(d.label));

            li.append('span')
              .attr('class', 'legend-label')
              .html((d) => {
                switch (this.legendLabelFormatType) {
                  case 'number':
                    return this.legendLabelFormat(d.label);

                  case 'time':
                    const parsedTime = d3_isoParse(d.label);
                    return this.legendLabelFormat(parsedTime);

                  default:
                    return d.label;
                }
              });

            return li;
          },
          (update) =>
            update.select('.legend-label').html((d) => {
              switch (this.legendLabelFormatType) {
                case 'number':
                  return this.legendLabelFormat(d.label);

                case 'time':
                  const parsedTime = d3_isoParse(d.label);
                  return this.legendLabelFormat(parsedTime);

                default:
                  return d.label;
              }
            }),
          (exit) => exit.remove()
        )
        .on('mouseover', (event, data) => this.legendMouseOver(event, data))
        .on('mouseout', () => this.legendMouseOut())
        .on('click', (event, data) => this.legendMouseClick(event, data));
    }

    if (this.threshold) {
      this.yThreshold
        .raise()
        .transition()
        .duration(1000)
        .attr('transform', `translate(0,  ${this.yAxisScale(+this.threshold)})`);
    }

    if (this.average) {
      this.yAverage
        .raise()
        .transition()
        .duration(1000)
        .attr('transform', `translate(0,  ${this.yAxisScale(+this.average)})`);
    }
  };

  barMouseOver = (event, data) => {
    this.chart
      .selectAll('.bar')
      .filter((d, i) => d.label !== data.label)
      .classed('inactive', true);

    const bar = this.chart.selectAll('.bar').filter((d, i) => d.label === data.label);

    const barColor = bar.attr('data-color');

    bar.style('fill', barColor);

    this.chart
      .selectAll('.legend-item')
      .filter((d, i) => d.label !== data.label)
      .classed('inactive', true);

    this.tooltipShow(event, data);

    this.hovered.emit({ event, data });
  };

  barMouseOut = () => {
    this.chart.selectAll('.bar').classed('inactive', false).style('fill', null);

    this.chart.selectAll('.legend-item').classed('inactive', false);

    this.tooltipHide();
  };

  barMouseClick = (event, data) => {
    this.clicked.emit({ event, data });
  };

  legendMouseOver = (event, data) => {
    this.chart
      .selectAll('.legend-item')
      .filter((d, i) => d.label !== data.label)
      .classed('inactive', true);

    this.chart
      .selectAll('.bar')
      .filter((d, i) => d.label !== data.label)
      .classed('inactive', true);

    const bar = this.chart.selectAll('.bar').filter((d, i) => d.label === data.label);

    const barColor = bar.attr('data-color');

    bar.style('fill', barColor);

    this.tooltipShow(
      event,
      data,
      this.chart
        .selectAll('.bar')
        .filter((d, i) => d.label === data.label)
        .node()
    );

    this.hovered.emit({ event, data });
  };

  legendMouseOut = () => {
    this.chart.selectAll('.legend-item').classed('inactive', false);

    this.chart.selectAll('.bar').classed('inactive', false).style('fill', null);

    this.tooltipHide();
  };

  legendMouseClick = (event, data) => {
    this.clicked.emit({ event, data });
  };

  private tooltipShow = (event, data, node?) => {
    const scroll = this._scroll.getScrollPosition();
    let dimensions = event.currentTarget.getBoundingClientRect();
    let label;

    if (node) {
      const target = this.chart
        .selectAll('.bar')
        .filter((d, i) => d.label === data.label)
        .node();

      dimensions = target.getBoundingClientRect();
    }

    switch (this.tooltipLabelFormatType) {
      case 'number':
        label = `${this.tooltipLabelFormat(data.label)}${this.tooltipLabelSuffix}`;
        break;

      case 'time':
        const parsedTime = d3_isoParse(data.label);
        label = `${this.tooltipLabelFormat(parsedTime)}${this.tooltipLabelSuffix}`;
        break;

      default:
        label = `${data.label}${this.tooltipLabelSuffix}`;
    }
    const value =
      this.tooltipValueFormat === null
        ? `<div class="tooltip-value">${data.value}</div>`
        : `<div class="tooltip-value">${this.tooltipValueFormat(data.value)}</div>`;

    this.tooltip.html(
      `
        ${this.hideTooltipLabel ? '' : `${label}`}
        ${value}
      `
    );

    const tooltipOffsetWidth = +this.tooltip.node().offsetWidth / 2;
    const tooltipOffsetHeight = +this.tooltip.node().offsetHeight + 8;

    this.tooltip.style('top', `${+scroll[1] + +dimensions.top - tooltipOffsetHeight}px`); //
    this.tooltip.style('left', `${+scroll[0] + +dimensions.left - tooltipOffsetWidth + +dimensions.width / 2}px`);
    this.tooltip.style('opacity', 1);
  };

  private tooltipHide = () => {
    this.tooltip.style('opacity', 0);
  };

  private xAxisFormatter = (item) => {
    switch (this.xAxisFormatType) {
      case 'number':
        return this.xAxisFormat(item);

      case 'time':
        const parseDate = d3_isoParse(item);
        return this.xAxisFormat(parseDate);

      default:
        return item;
    }
  };

  private yAxisFormatter = (item) => {
    switch (this.yAxisFormatType) {
      case 'number':
        return this.yAxisFormat(item);

      case 'time':
        const parseDate = d3_isoParse(item);
        return this.yAxisFormat(parseDate);

      default:
        return item;
    }
  };

  private barFill(d) {
    const path = this._location.path();
    const url = this._location.prepareExternalUrl(path);
    const colorRange = this.colorRange(d.label);

    if (this.gradient) {
      return `url(${url}#gradient-${colorRange.substr(1)})`;
    } else {
      return colorRange;
    }
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'pbds-error-icon-alert',
  templateUrl: './error-icon-alert.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 150px;
        height: 150px;
        margin: 0 auto;
      }
      svg {
        overflow: visible;
      }
    `
  ]
})
export class ErrorIconAlertComponent {
  @Input() static: boolean;
}

import { ViewportScroller } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AccordionService } from '@app/shared/services/accordion.service';
// shared
import { SharedModule } from '@app/shared/shared.module';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { L10nIntlModule, L10nTranslationModule } from 'angular-l10n';

// third party
import { provideGoogleAnalytics, provideGoogleAnalyticsRouter } from '@hakimio/ngx-google-analytics';
import { ToastNoAnimation, ToastNoAnimationModule, ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// application
import { Globals } from './globals';
import { AppStorage, HttpTranslationLoader, l10nConfig } from './l10n-config';
import { OktaAuthenticateService } from './shared/services/okta-authenticate.service';

function initAuth(oktaAuth: OktaAuthenticateService, router: Router) {
  return () => {
    const promise = Promise;

    return oktaAuth
      .login()
      .toPromise()
      .then((response: any) => {
        if (response.redirectUrl === null) {
          const storedUrl = localStorage.getItem('tempUrl');

          if (storedUrl) {
            const temp: any = storedUrl.split('#');

            const tempUrl = temp[0];
            const tempFragment = temp[1] || '';

            if (tempUrl !== '/') {
              if (tempFragment) {
                router.navigate([tempUrl], { fragment: tempFragment });
              } else {
                router.navigate([tempUrl]);
              }
            }

            localStorage.removeItem('tempUrl');
          }
        }

        promise.resolve();
      })
      .catch((error) => {
        // save url to redirect after login
        localStorage.setItem('tempUrl', `${window.location.pathname}${window.location.hash}`);

        // handle rdirect url
        window.location.href = error.error.redirectUrl;

        // reject promise to prevent app from loading
        return promise.reject();
      });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    L10nTranslationModule.forRoot(l10nConfig, {
      storage: AppStorage,
      translationLoader: HttpTranslationLoader,
    }),
    L10nIntlModule,
    SharedModule,
    AppRoutingModule,
    ToastNoAnimationModule,
    ToastrModule.forRoot({
      toastComponent: ToastNoAnimation,
    }),
  ],
  providers: [
    Title,
    Globals,
    AccordionService,
    provideGoogleAnalytics('G-WB829N07E8'),
    provideGoogleAnalyticsRouter(),
    {
      provide: APP_INITIALIZER,
      useFactory: initAuth,
      deps: [OktaAuthenticateService, Router],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private _router: Router,
    private _scroll: ViewportScroller,
  ) {
    // scroll to window top on route change
    this._router.events.subscribe((event) => {
      let start = {
        url: '',
        fragment: '',
      };
      let end = {
        url: '',
        fragment: '',
      };
      function splitUrl(path) {
        path = path.split('#');
        return {
          url: path[0] || '',
          fragment: path[1] || '',
        };
      }
      if (event instanceof NavigationStart) {
        start = splitUrl(this._router.routerState.snapshot.url);
      } else if (event instanceof NavigationEnd) {
        end = splitUrl(this._router.routerState.snapshot.url);
      }
      if (start.url !== end.url && !end.fragment && !start.fragment) {
        this._scroll.scrollToPosition([0, 0]);
      }
    });
  }
}
